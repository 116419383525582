.menu {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    height: 100vh;
    min-height: 762px;

    transition: all $duration $easing;
    background-image: linear-gradient(to bottom, #f79e17 30%, $color-primary);

    align-items: initial;

    &.is-shown {
        .menu__nav {
            li {
                transform: translateY(0);

                opacity: 1;

                &:first-child {
                    transition-delay: .12s;
                }
                &:nth-child(2) {
                    transition-delay: .2s;
                }
                &:nth-child(3) {
                    transition-delay: .25s;
                }
                &:last-child {
                    transition-delay: .5s;
                }
            }
        }
    }
}

.menu__left {
    position: relative;
    width: 65%;
    padding: 25px 98px;

    .logo {
        margin: auto;
        display: inline-block;
    }
}

.menu__logo {
    width: 200px;
}

.menu__nav {
    margin-top: 22vh;

    li {

        a {
            font-family: $font-gilroy-xbold;
            font-size: 3.2rem;
            transition: all 0.35s ease-in-out;
            color: #fff;
            display: block;
            line-height: 1.4;

            &:hover {
                opacity: .8;
                transform: translateX(20px);
            }
        }
    }
}

.menu__sns {
    position: absolute;
    bottom: 70px;

    width: 80%;

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
        margin-right: 2rem;
        &:nth-child(3) {
            .menu__sns-icon {
                line-height: 2.2;
            }
        }
    }

    a {
        color: #fff;
    }
}

.menu__sns-icon {
    line-height: 2.4;

    width: 54px;
    height: 54px;

    text-align: center;

    background-image: url(#{$path-image}/sns-border.svg);
    background-repeat: no-repeat;
    background-size: contain;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon-linkedin {
        position: relative;
        top: -2px;
    }
}

.menu__sns-description {
    font-size: 14px;
    line-height: 1.4;
    position: relative;

    margin-left: 12px;

    strong {
        display: block;
        font-size: 17px;
        font-family: $font-gilroy-xbold;
    }
}

.menu__right {
    position: relative;

    width: 35%;
    height: 100%;
    padding: 50px 50px 70px;

    background-color: #000;

    align-items: flex-end;

    &:before {
        position: absolute;
        top: 100px;
        left: 50px;

        display: block;

        width: 85px;
        height: 100px;

        content: '';

        background-image: url(#{$path-image}/index/dots.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.menu__locations {
    width: 65%;

    h2 {
        font-family: $font-gilroy-xbold;
        font-size: 30px;

        display: block;

        margin-bottom: 15px;

        background: linear-gradient(90deg, $color-orange 0%, $color-primary 50%);
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
    }
    li {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.menu__locations-time {
    width: 40%;
}

.menu__locations-details {
    width: 55%;

    h3 {
        font-family: $font-gilroy-xbold;
        font-size: 21px;
        line-height: 1;

        display: block;

        margin-bottom: 0;

        color: #fff;
    }
    a {
        color: #787878;
    }
}



/////////




///// MENU
/* --------------------------------

Main Components

-------------------------------- */
#page-wrap {
    position: relative;
    z-index: 2;
    /* fix bug on iOS */
    //height: 100vh;
    //overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.7s;
    -moz-transition: -moz-transform 0.7s;
    transition: transform 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
    -moz-transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
    transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);

    background-color: #000;
}

.navigation-is-open #page-wrap {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

.cd-nav-trigger {
    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    transition: transform 0.5s;
}
.cd-nav-trigger .cd-nav-icon {
    /* icon created in CSS */
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 24px;
    height: 3px;
    background-color: #ffffff;
}
.cd-nav-trigger .cd-nav-icon::before, .cd-nav-trigger .cd-nav-icon:after {
    /* upper and lower lines of the menu icon */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s, width 0.5s, top 0.3s;
    -moz-transition: -moz-transform 0.5s, width 0.5s, top 0.3s;
    transition: transform 0.5s, width 0.5s, top 0.3s;
}
.cd-nav-trigger .cd-nav-icon::before {
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    transform: translateY(-6px);
}
.cd-nav-trigger .cd-nav-icon::after {
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translateY(6px);
    -moz-transform: translateY(6px);
    -ms-transform: translateY(6px);
    -o-transform: translateY(6px);
    transform: translateY(6px);
}
.no-touch .cd-nav-trigger:hover .cd-nav-icon::after {
    top: 2px;
}
.no-touch .cd-nav-trigger:hover .cd-nav-icon::before {
    top: -2px;
}
.cd-nav-trigger svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.cd-nav-trigger circle {
    /* circle border animation */
    -webkit-transition: stroke-dashoffset 0.4s 0s;
    -moz-transition: stroke-dashoffset 0.4s 0s;
    transition: stroke-dashoffset 0.4s 0s;
}
.navigation-is-open .cd-nav-trigger {
    /* rotate trigger when navigation becomes visible */
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
    /* animate arrow --> from hamburger to arrow */
    width: 50%;
    -webkit-transition: -webkit-transform 0.5s, width 0.5s;
    -moz-transition: -moz-transform 0.5s, width 0.5s;
    transition: transform 0.5s, width 0.5s;
}
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after, .no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
    top: 0;
}
.navigation-is-open .cd-nav-trigger circle {
    stroke-dashoffset: 0;
    -webkit-transition: stroke-dashoffset 0.4s 0.3s;
    -moz-transition: stroke-dashoffset 0.4s 0.3s;
    transition: stroke-dashoffset 0.4s 0.3s;
}


.cd-nav {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.7s;
    -moz-transition: visibility 0s 0.7s;
    transition: visibility 0s 0.7s;
}
.cd-nav .cd-navigation-wrapper {
    /* all navigation content */
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    //padding: 40px 5% 40px calc(5% + 80px);
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: -webkit-transform 0.7s;
    -moz-transition: -moz-transform 0.7s;
    transition: transform 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
    -moz-transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
    transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
}
.navigation-is-open .cd-nav {
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    -moz-transition: visibility 0s 0s;
    transition: visibility 0s 0s;
}
.navigation-is-open .cd-nav .cd-navigation-wrapper {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    transition: transform 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
    -moz-transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
    transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
}
.cd-nav .cd-primary-nav {
    margin-top: 60px;
}
.cd-nav .cd-primary-nav li {
    margin: 1.6em 0;
}
.cd-nav .cd-primary-nav a {
    font-family: "Merriweather", serif;
    font-size: 2.4rem;
    color: rgba(255, 255, 255, 0.3);
    display: inline-block;
}
.cd-nav .cd-primary-nav a.selected {
    color: #ffffff;
}
.no-touch .cd-nav .cd-primary-nav a:hover {
    color: #ffffff;
}
.cd-nav .cd-contact-info {
    margin-top: 80px;
}
.cd-nav .cd-contact-info li {
    font-family: "Merriweather", serif;
    margin-bottom: 1.5em;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.3);
}
.cd-nav .cd-contact-info a {
    color: #ffffff;
}
.cd-nav .cd-contact-info span {
    display: block;
}
.cd-nav .cd-contact-info li, .cd-nav .cd-contact-info a, .cd-nav .cd-contact-info span {
    font-size: 1.6rem;
}
@media only screen and (min-width: 1170px) {
    .cd-nav .cd-navigation-wrapper {
        // padding: 62px 20%;
    }
    .cd-nav .cd-navigation-wrapper::after {
        clear: both;
        content: "";
        display: table;
    }
    .cd-nav .cd-half-block {
        width: 50%;
        float: left;
    }
    .cd-nav .cd-primary-nav {
        margin-top: 0;
    }
    .cd-nav h2 {
        font-size: 1.5rem;
        margin-bottom: 5.6em;
    }
    .cd-nav .cd-primary-nav li {
        margin: 2em 0;
    }
    .cd-nav .cd-primary-nav a {
        font-size: 4.4rem;
    }
    .cd-nav .cd-contact-info {
        margin-top: 120px;
        text-align: right;
    }
    .cd-nav .cd-contact-info li {
        margin-bottom: 2.4em;
    }
    .cd-nav .cd-contact-info li, .cd-nav .cd-contact-info a, .cd-nav .cd-contact-info span {
        font-size: 2rem;
    }
}

.no-js #page-wrap {
    height: auto;
    overflow: visible;
}

.no-js .cd-nav {
    position: static;
    visibility: visible;
}
.no-js .cd-nav .cd-navigation-wrapper {
    height: auto;
    overflow: visible;
    padding: 100px 5%;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.home {
    .cd-nav-trigger {
        margin: 6px 2px;
    }
}

@media (max-width:$md) {
    .menu__right {
        display: none;
    }

    .menu__left {
        width: 100%;
        padding: 1rem 3rem;
    }

    .menu__sns li {
        margin-right: 1.5rem;
    }
}

@media (max-width:$sm) {

    .header {
        height: 80px;
        min-height: 80px;
    }


    .menu__nav {
        margin-top: 7rem;
    }



    .menu__nav li a {
        font-size: 3rem;

    }


    .menu__sns {
        display: block!important;
        position: static;
        margin-top: 2rem;
        width: 100%;
    }
    .menu__sns li {
        padding: .5rem 0;
        display: block;

        a {
            justify-content: inherit;
        }
    }


    .cd-nav-trigger svg {
        width: 48px;
        height: 48px;
    }

    .header__menu {
        min-width: 58px;
        min-height: 0;
        height: 100%;
        width: 58px;
        background-color: #F79E17;
        transition: height .4s ease;
        transition-delay: .2s;
    }

    .header__logo {
        left: 4.5rem;
    }

    .header {
        width: 100%;
        background: rgba(#000, .75);
    }

    .home .cd-nav-trigger {
        margin: 0;
    }


}