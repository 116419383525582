.clock {
    display: flex;

    width: 50px;
    height: 50px;

    border-radius: 50%;
    background-color: #000;

    justify-content: center;
    align-items: center;
}

.clock__hour,
.clock__minute {
    position: absolute;
}

.clock__hour-handle {
    width: 40px;
    height: 40px;

    &:before {
        position: absolute;
        top: 9px;

        width: 3px;
        height: 12px;

        content: '';

        border-radius: 6px 6px 0 0;
        background-image: linear-gradient(to bottom, $color-primary 20%, $color-orange);
    }
}

.clock__minute-handle {
    width: 50px;
    height: 50px;

    &:before {
        position: absolute;
        top: 9px;

        width: 3px;
        height: 16px;

        content: '';

        border-radius: 6px 6px 0 0;
        background-image: linear-gradient(to bottom, $color-primary 20%, $color-orange);
    }
}

.clock__hour-handle,
.clock__minute-handle {
    display: flex;

    transition: transform 1s $easing;

    border-radius: 50%;

    justify-content: center;
}
