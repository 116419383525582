.l-index {
    visibility: hidden;

    &.is-shown {
        visibility: visible;
    }
}


.section-home-1 {
    display: flex;
    align-items: center;
    height: 100vh;
    min-height: 780px;
    position: relative;

    @media (max-width:$sm) {
        min-height: 0;
    }


    .container {
        position: relative;
        z-index: 1;
    }

    #js-blob-spider {
        position: absolute;
        top: 10%;
        bottom: 0;

        @media (max-width:$sm) {
            top: auto;
        }
    }

    .section-copy {
        position: relative;
        z-index: 2;

        h1 {
            font-family: $font-gilroy-xbold;
            font-size: 6vw;
            line-height: 1.2;

            margin-bottom: 40px;

            span {
                position: relative;

                display: block;

                transition: all $duration $easing;

                &:nth-child(2) {
                    transition-delay: .25s;
                }
                &:nth-child(3) {
                    transition-delay: .5s;
                }
            }

            @media (max-width:$sm) {
                font-size: 2.7rem;
            }
        }

        .link {
            font-family: "Gilroy-ExtraBold";
            display: inline-block;


            transition: all $duration $easing;

            color: #fff;
        }

        @media (max-width:$sm) {
            z-index: 1;
        }
    }

    .hero__email {
        font-size: 20px;

        position: absolute;
        z-index: 21;
        right: -50px;
        bottom: 150px;

        display: block;

        transition: opacity $duration $easing;
        transform: rotate(-90deg);

        opacity: .75;
        color: #fff;

        @media (max-width:$sm) {
            display: none;
        }
    }

    .section-actions-float {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 20px;

        @media (max-width:$md) {
            transform: inherit;
            position: static;
            margin-top: 0;
            width: 130px;

            .button {
                position: relative;
                display: block;
                padding: 15px 0 13px;
                margin-right: 1rem;
            }
        }


        .button {
            display: block;
            background-color: #292929;
            margin: 12px 0;

            &:before {
                position: absolute;
                top: 50%;
                right: -10px;

                width: 24px;
                height: 2px;

                content: '';
                transition: right $duration $easing;
                transform: translateY(-50%);

                background-color: #d5d5d5;
            }
            &:after {
                position: absolute;
                top: 50%;
                right: -10px;

                display: block;

                width: 8px;
                height: 8px;

                content: '';
                transition: right $duration $easing;
                transform: translateY(-50%) rotate(45deg);

                border-top: 2px solid #d5d5d5;
                border-right: 2px solid #d5d5d5;
            }
        }
    }


}

.l-index__mask {
    z-index: 21;

    width: 100vw;
    height: 100vh;

    background-image: url(#{$path-image}/index/mask-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.l-index__services {
    position: relative;
    overflow: hidden;
    min-height: 100vh;

    .l-index__heading-top {

        h2 {
            display: block;
            text-align: right;
        }
    }
}

.l-index__services-bg {
    width: 100%;
    padding: 12vh 0;

    .services-bg {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(#{$path-image}/index/services-bg.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        transition: .3s ease;
        transform: scale(1);


        &:after {
            content: '';
            height: 400px;
            width: 100%;

            background-image: url(#{$path-image}/index/services-bg-top-shadow.png);
            background-repeat: repeat-x;
            background-position: center top;
            display: block;
            background-size: auto 100%;

        }
    }
}

.l-index__heading-top {

    svg {
        position: relative;

        height: 20vh;

        path {
            fill: #fff;
        }
    }
}

.l-index__play {
    position: relative;
    // margin-top: 100px;
    // margin-bottom: 100px;

    text-align: center;
}

.l-index__works {
    position: relative;

    overflow: hidden;

    padding: 150px 0 130px;

    &:before {
        position: absolute;
        top: 195px;
        left: 0;

        display: block;

        width: 82%;
        height: 920px;

        content: '';

        background-color: #18181a;
        box-shadow: inset 0 -250px 90px -90px #18181a;
    }
}


.l-index__sponsors {
    padding: 5rem 0px 6rem;
    h2 {
        font-family: $font-gilroy-xbold;
        font-size: 4.8vw;
        line-height: 1.2;
        margin: auto;
        text-align: center;
        margin-bottom: 4.5rem;
    }
}


.l-index__sponsors-left {
    position: relative;
    width: 45%;
    padding: 36px 45px;
    background-color: #fff;
    text-align: center;

    span {
        font-family: $font-gilroy-xbold;
        font-size: 30px;

        display: block;

        margin-bottom: 10px;

        background: linear-gradient(90deg, $color-orange 0%, $color-primary 40%);
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
    }
    h3 {
        font-family: $font-gilroy-xbold;
        font-size: 36px;
        line-height: 1.2;

        margin-bottom: 10px;
        padding-right: 30px;

        color: #000;
    }
    p {
        line-height: 1.7;

        margin-bottom: 20px;

        color: #4f4f4f;
    }
    a {
        font-family: $font-gilroy-xbold;
        font-size: 17px;

        color: #000;
    }
}

.l-index__sponsors-right {
    width: 50%;
}

.l-index__sponsors-list {
    li {
        position: relative;

        width: 30%;
        margin-bottom: 2.5rem;

        text-align: center;

        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3) {
            margin-bottom: 0;
        }

        img {
            width: 120px;
            max-width: 100%;

            filter: brightness(0) invert(1);
        }
    }
}

.l-index__sponsors-arrow {
    position: absolute;
    bottom: 25px;
    left: 25px;

    a {
        display: block;

        width: 25px;
        height: 25px;

        transform: rotate(-45deg);

        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
    }
}


.section-services {
    padding: 4rem 0;
}

.section-services-homepage {
    margin-top: -30vh;
    margin-bottom: 3vh;
}

.services-item {
    display: block;
    padding: .6rem 0;

    > a {
        transition: all $duration;
        display: inline-block;
        position: relative;
        left: 0;

        .icon-hover {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 65px;
            content: '';
            background-position: 99% 0;
            background-size: 200% 100%;
            transition: background .3s;

            border: 2px solid rgba(#fff, .50);
            border-right: 0;

            &:before,
            &:after {
                content: '';
                position: absolute;
                right: 0;

                width: 2px;
                height: 10px;
                background-color: rgba(#fff, .50);
            }
            &:before {
                top: 0;
            }
            &:after {
                bottom: 0;
            }

        }

        span {
            color: $color-light-gray;
            font-family: $font-gilroy-xbold;
            line-height: 1.4;
            padding: 12px 28px;
            min-height: 72px;
            position: relative;
            z-index: 1;
            text-shadow: #000 0 1px 28px;


            display: inline-flex;
            align-items: center;
            position: relative;
        }

        &:hover {
            color: #fff;
            left: -22px;

            span {
                text-shadow: none;
            }

            .icon-hover {
                width: 100%;
                background-image: linear-gradient(to left, transparent, transparent 50%, #97080C 50%, #FB8631);
                background-position: 0 0;
                background-size: 200% 100%;

                border-color: transparent;

                &:before,
                &:after {
                    background-color: transparent;
                }

            }
        }
    }
    @media (max-height:700px) {

        padding: 5px 0;

        > a span {
            min-height: 55px;
            font-size: 15px;
        }
    }
}

section#section-contact {
    padding: 4rem 0;
}

////

@media (max-width:$md) {
    .services-item {
        padding: .2rem 0;

        > a span {
            font-size: 16px;
            padding: 12px 28px;

        }
    }



    .row .col-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .section-services-homepage {
        margin-top: 0;
        padding: 0;
    }
}


@media (max-width:$sm) {



    .row .col-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-height:700px) {
    .section-services {
        padding: 1rem 0;
    }
}