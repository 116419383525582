.cursor__outline {
    position: absolute;
    z-index: 999999;
    top: 50%;
    left: 50%;

    mix-blend-mode: difference;

    transition: opacity .3s ease-in-out,
    transform .3s ease-in-out;
    transform: translate(-50%, -50%);
    pointer-events: none;

    opacity: 0;
    border-radius: 50%;

    @include max-screen($breakpoint-mobile) {
        display: none;
    }
}
.cursor__outline {
    width: 40px;
    height: 40px;

    border: 2px solid $color-secondary;
}
