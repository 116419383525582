#gg-screen {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;

    visibility: hidden;
    overflow: hidden;

    width: 100%;
    height: 100%;

    -webkit-transition: all .35s ease-in-out 0s;
       -moz-transition: all .35s ease-in-out 0s;
        -ms-transition: all .35s ease-in-out 0s;
         -o-transition: all .35s ease-in-out 0s;
            transition: all .35s ease-in-out 0s;
    text-align: center;

    opacity: 0;
    background-color: #000;

    .content-left,
    .content-right {
        position: absolute;
        top: 0;
        bottom: 0;

        float: left;

        height: 100%;
        padding-top: 150px;
        padding-bottom: 80px;

        @include max-screen($breakpoint-mobile) {
            position: relative;

            height: auto;
            padding-top: 80px;
            padding-bottom: 0;
        }

        .content-inner-wrap {
            position: relative;

            width: 100%;
            height: 100%;
        }
    }

    .content-left {
        left: -65%;

        width: 65%;
        padding-left: 80px;

        -webkit-transition: all .35s ease-in-out 0s;
           -moz-transition: all .35s ease-in-out 0s;
            -ms-transition: all .35s ease-in-out 0s;
             -o-transition: all .35s ease-in-out 0s;
                transition: all .35s ease-in-out 0s;

        @include max-screen($breakpoint-mobile) {
            width: 100%;
            padding-left: 0;
        }

        .gg-image {
            width: 100%;
            height: 100%;

            background-position: center top;
            background-size: cover;

            @include max-screen($breakpoint-mobile) {
                height: 230px;
            }
        }

        .title {
            font-family: $font-gilroy-xbold;
            font-size: 70px;

            position: absolute;
            top: -100px;
            left: 0;

            color: #fff;

            @media(max-width: 1199px) {
                font-size: 50px;
            }

            @media(max-width: 1024px) {
                font-size: 40px;
            }

            @media(max-width: 991px) {
                font-size: 30px;
            }

            @include max-screen($breakpoint-mobile) {
                position: relative;
                top: -15px;
            }
        }
    }

    .content-right {
        right: -35%;

        width: 35%;
        padding-right: 80px;

        -webkit-transition: all .5s ease-in-out 0s;
           -moz-transition: all .5s ease-in-out 0s;
            -ms-transition: all .5s ease-in-out 0s;
             -o-transition: all .5s ease-in-out 0s;
                transition: all .5s ease-in-out 0s;

        @include max-screen($breakpoint-mobile) {
            top: 0;

            width: 100%;
            margin-top: -7px;
            padding-top: 0;
            padding-right: 0;
        }

        .content-inner-wrap {
            padding: 40px 30px 30px;

            text-align: left;

            color: #202020;
            background-color: #fff;

            @include max-screen($breakpoint-mobile) {
                padding: 30px 15px 20px;
            }
        }

        h3 {
            font-family: $font-gilroy-xbold;

            margin-bottom: 10px;

            text-transform: capitalize;

            color: #202020;

            @include max-screen($breakpoint-mobile) {
                font-size: 16px;
            }
        }

        .content-inner {
            display: block;
            overflow-y: scroll;

            height: 100%;
            padding-bottom: 50px;

            @include max-screen($breakpoint-mobile) {
                padding-bottom: 25px;
            }
        }

        .icon-logo {
            display: block;

            max-width: 160px;
            margin-bottom: 15px;

            img {
                max-width: 100%;

                @include max-screen($breakpoint-mobile) {
                    max-height: 70px;

                    object-fit: contain;
                }
            }
        }

        .button-wrap {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            overflow: hidden;

            padding: 25px;

            background-color: #fff;

            @include max-screen($breakpoint-mobile) {
                position: relative;
            }

            .gg-bt {
                font-family: $font-gilroy-xbold;
                font-size: 16px;

                text-align: center;
                text-decoration: underline;
                text-transform: uppercase;

                color: #b22d2c;
            }
            .gg-nxt {
                float: right;
            }

            .gg-prev {
                float: left;
            }
        }

        .description {
            margin-bottom: 15px;

            @include max-screen($breakpoint-mobile) {
                font-size: 15px;
            }

            p {
                color: initial;
            }
        }

        .description-list {
            ul {
                margin-left: 15px;

                text-align: left;

                li {
                    position: relative;

                    padding-left: 30px;

                    @include max-screen($breakpoint-mobile) {
                        font-size: 15px;

                        padding-left: 20px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &:before {
                        position: absolute;
                        top: 0;
                        left: -15px;

                        width: 28px;
                        height: 28px;

                        content: '';

                        border-radius: 50%;
                        background: linear-gradient(90deg, $color-orange 0%, $color-primary 80%);

                        @include max-screen($breakpoint-mobile) {
                            width: 23px;
                            height: 23px;
                        }
                    }
                    &:after {
                        position: absolute;
                        top: 6px;
                        left: -5px;

                        width: 8px;
                        height: 13px;

                        content: '';
                        transform: rotate(45deg);

                        border-right: 2px solid #fff;
                        border-bottom: 2px solid #fff;

                        @include max-screen($breakpoint-mobile) {
                            top: 4px;
                            left: -7px;

                            width: 6px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }

    &.in {
        z-index: 999999;

        visibility: visible;

        opacity: 1;

        @include max-screen($breakpoint-mobile) {
            overflow-y: scroll;
        }

        .content-left {
            left: 0;
        }

        .content-right {
            right: 0;
        }
    }
}
#gg-screen .gg-image {
    display: inline-flex;

    height: 100%;

    justify-content: center;
    align-items: center;
}
#gg-screen .gg-image img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.gg-close {
    position: absolute;
    top: -120px;
    right: 0;

    width: 51px;
    height: 51px;

    -webkit-transition: all .35s ease-in-out 0s;
       -moz-transition: all .35s ease-in-out 0s;
        -ms-transition: all .35s ease-in-out 0s;
         -o-transition: all .35s ease-in-out 0s;
            transition: all .35s ease-in-out 0s;

    opacity: .7;
    background-image: url(#{$path-image}/our-works/close.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @include max-screen($breakpoint-mobile) {
        top: -350px;
        right: 12px;

        width: 35px;
        height: 35px;
    }

    &:hover {
        opacity: 1;
    }
}

// PopUp for homepage Pong Game
.pong-popup {
    left: 100%;

    @extend #gg-screen;

    &.in {
        left: 0;
    }
}

.pong-popup-inner {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    max-width: 700px;
    margin: 0 auto;
    padding: 50px;

    -webkit-transform: translate(0, -50%);
       -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
         -o-transform: translate(0, -50%);
            transform: translate(0, -50%);

    background-color: #191919;

    .pong-popup-close {
        top: -15%;

        @extend .gg-close;
    }

    .form-group {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    form {
        overflow: hidden;
    }

    .btn-wrap {
        margin-bottom: 0;

        button {
            display: block;

            width: 100%;
            padding: 20px;
        }
    }
}

.pong-popup-header {
    display: flex;

    margin-bottom: 20px;
    padding: 0 0 15px;

    border-bottom: 1px solid #242429;

    h3 {
        font-family: $font-gilroy-xbold;

        text-align: left;
    }

    .pong-popup-header-title-left {
        margin-right: auto;

        span {
            font-family: $font-gilroy-light;
        }
    }
}
