.l-home-section {
    position: relative;

    display: flex;
    overflow: hidden;

    min-height: 100vh;

    align-items: center;

    .l-container {
        margin-top: 3rem;
    }
}



.l-home-section-footer {
    .prefooter {
        .u-flex {
            align-items: inherit;
        }
    }
}
.js-letsplay {
    position: fixed;
    z-index: 24;
    right: 0;
    bottom: 0;

    display: block;

    transition: all .3s;
}
#works-section.fp-completely {
    .js-letsplay {
        right: 0;
    }
}


.modal-letsplay {
    position: fixed;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    visibility: hidden;

    margin: 0;

    transition: opacity .5s, visibility 0s .5s;

    opacity: 0;
    background: #000;

    align-items: center;

    &.active {
        z-index: 101;

        visibility: visible;

        transition: opacity .5s;

        opacity: 1;
    }
}

.background {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



.background {
    position: fixed;

    width: 100%;
    height: 100%;

    text-rendering: optimizeLegibility;
}
.background:after {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
}
.wrapper {
    display: none;
}


/* 1. Intro Keyframe
--------------------------------------- */
#intro {
    /*position: fixed;*/
    /*top: 48%;*/
    /*left: 10%;*/
    /*!* width: 80%; *!*/
    /*color: #fff;*/
    /*text-align: center;*/
    /*text-transform: uppercase;*/
}
.ampersand {
    font-family: 'Adobe Caslon Pro', 'brandon-grotesque';
    font-style: italic;
}


#images {
    position: relative;
}
.explosion-byline,
.images-byline {
    position: fixed;
    top: 63%;
    left: 10%;

    max-width: 22%;

    opacity: 0;
    color: white;
}
.images-byline-2 {
    position: fixed;
    top: 93%;
    left: 25%;

    width: 50%;

    text-align: center;

    opacity: 0;
    color: white;
}

/* 3. Explosion Keyframe
--------------------------------------- */
// #domExplosionList {
//   position: fixed;
//   width: 100vw;
//   top: 100%;
//   left: 10%;
//   opacity: 0;
// }
// .dom-explosion-item {
//   position: absolute;
//   width: 120px;
//   height: 129px;
//   background-color: #fff;
//   background-size: cover;
// }
// .dei-1  {
//   top: 0;   left: 0;
//   background-image: url('../images/index/home-bulb/light_01.jpg');
// }
// .dei-2  {
//   top: 0;   left: 120px;
//   background-image: url('../images/index/home-bulb/light_02.jpg');
// }
// .dei-3  {
//   top: 0;   left: 240px;
//   background-image: url('../images/index/home-bulb/light_03.jpg');
// }
// .dei-4  {
//   top: 0;   left: 360px;
//   background-image: url('../images/index/home-bulb/light_04.jpg');
// }
// .dei-5  {
//   top: 129px;   left: 0;
//   background-image: url('../images/index/home-bulb/light_05.jpg');
// }
// .dei-6  {
//   top: 129px;   left: 120px;
//   background-image: url('../images/index/home-bulb/light_06.jpg');
// }
// .dei-7  {
//   top: 129px;   left: 240px;
//   background-image: url('../images/index/home-bulb/light_07.jpg');
// }
// .dei-8  {
//   top: 129px;   left: 360px;
//   background-image: url('../images/index/home-bulb/light_08.jpg');
// }
// .dei-9  {
//   top: 258px;   left: 0;
//   background-image: url('../images/index/home-bulb/light_09.jpg');
// }
// .dei-10 {
//   top: 258px;   left: 120px;
//   background-image: url('../images/index/home-bulb/light_10.jpg');
// }
// .dei-11 {
//   top: 258px;   left: 240px;

//   background-image: url('../images/index/home-bulb/light_11.jpg');
// }
// .dei-12 {
//   top: 258px;   left: 360px;
//   background-image: url('../images/index/home-bulb/light_12.jpg');
// }
// .dei-13 {
//   top: 387px;   left: 0px;
//   background-image: url('../images/index/home-bulb/light_13.jpg');
// }
// .dei-14 {
//   top: 387px;   left: 120px;
//   background-image: url('../images/index/home-bulb/light_14.jpg');
// }
// .dei-15 {
//   top: 387px;   left: 240px;
//   background-image: url('../images/index/home-bulb/light_15.jpg');
// }
// .dei-16 {
//   top: 387px;   left: 360px;
//   background-image: url('../images/index/home-bulb/light_16.jpg');
// }


/* 3. Images Keyframe
--------------------------------------- */
.raw-page {
    position: fixed;

    width: 45%;

    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    background-clip: padding-box;
    box-shadow: inset 0 0 1px rgba(255,255,255,.3);
}
#mediumHomepage {
    top: 100%;
    right: 5%;
}
.iphone {
    position: fixed;
    top: 102%;
    right: 40%;

    width: 16%;
}
.iphone-frame {
    position: relative;
    z-index: 2;

    width: 100%;
    height: auto;

    background-size: 100%;
}
.iphone-viewport {
    position: absolute;
    top: 14%;
    left: 8%;

    overflow: hidden;

    width: 83.5%;
    height: 69.5%;

    background-color: #000;
}
.iphone-content {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
}
#medium-profile-iphone {
    z-index: 2;
}

/* 3. Links Keyframe
--------------------------------------- */
#links {
    position: fixed;
    top: 35%;
    left: 10%;

    width: 80%;

    text-align: center;

    opacity: 0;
    color: #fff;
}
.links-byline {
    font-size: 30px;
    line-height: 1.15;
}
.btn {
    font-size: 12px;
    font-weight: 900;

    display: inline-block;

    padding: 10px 20px;

    text-align: center;
    text-decoration: none;
    letter-spacing: .1em;
    text-transform: uppercase;

    color: rgba(255,255,255,.8);
    border: 1px solid rgba(255,255,255,.5);
    border-radius: 50px;
}
.btn:hover,
.btn:focus {
    color: #fff;
    border-color: #fff;
}
.twitter {
    position: fixed;
    top: 20px;
    right: 20px;
}

/* 4. Handling resize and small devices
--------------------------------------- */
.error {
    font-size: 30px;
    font-weight: 300;

    position: fixed;
    top: 30%;
    left: 10%;

    display: none;

    width: 80%;

    text-align: center;
}
.page-error .error {
    display: block;
}
.page-error .container {
    display: none;
}
@media all and (max-width: 1000px) {
    .error {
        font-size: 22px;

        display: block;
    }
}


.l-index-container {
    max-width: 1400px;
    margin: auto;
}

#links {
    .l-index__heading-top {
        right: 0;
        left: 0;
    }
}


body.is-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;
}



//// SCROLL

@-webkit-keyframes mouseScroll {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(14px);
                transform: translateY(14px);
    }
}

@keyframes mouseScroll {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(14px);
                transform: translateY(14px);
    }
}


.mouse-scroll {
    position: fixed;
    z-index: 0;
    bottom: 1.4rem;
    left: 2rem;

    overflow: hidden;

    width: 26px;
    height: 40px;

    transition: all .3s ease;

    border: 2px solid rgba(255, 255, 255, .16);
    border-radius: 15px;

    mix-blend-mode: difference;

    &-inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        margin: auto;
    }

    .roll {
        position: absolute;
        z-index: 2;
        top: 8px;
        left: 50%;

        width: 4px;
        height: 4px;
        margin-left: -2px;

        -webkit-animation: mouseScroll .8s cubic-bezier(.7,0,.3,1) infinite alternate;
                animation: mouseScroll .8s cubic-bezier(.7,0,.3,1) infinite alternate;

        border-radius: 4px;
        background: #fff;;
    }

    .roll-shadow {
        position: absolute;
        z-index: 1;
        top: 8px;
        left: 50%;

        width: 4px;
        height: 4px;
        margin-left: -2px;

        -webkit-animation: mouseScroll .8s cubic-bezier(.7,0,.3,1) infinite alternate;
                animation: mouseScroll .8s cubic-bezier(.7,0,.3,1) infinite alternate;
        -webkit-animation-delay: 80ms;
                animation-delay: 80ms;

        border-radius: 4px;
        background: rgba(255, 255, 255, .16);;
    }

    &.is-hidden {
        opacity: 0;
    }
}


//////

.l-section-1 {
    display: flex;
    align-items: center;
    padding: 3rem 0;

    p {
        color: #d4d4d4;
    }

    h1 + * {
        margin-top: .5rem;
    }

    .l-section-left {
        width: 67%;
        padding-right: 3rem;

        @media(max-width: $sm) {
            padding-right: 0;
            margin-bottom: 2em;
        }
    }

    .l-section-right {
        width: 33%;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    @media(max-width: 767px) {
        .l-section-left, .l-section-right {
            width: 100%;
            text-align: center;


            img {
                width: 100%;
            }
        }
    }
}

.l-section-left {
}

.l-section-right {
    margin-left: auto;

    &.offset-b {
        z-index: 1;
        margin-bottom: -4rem;
    }
}

.l-section-1 {
    @include max-screen($breakpoint-mobile) {
        display: block;
    }
}


h1 {
    font-family: $font-gilroy-xbold;
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 20px;

    @media(max-width: 1280px) {
        font-size: 50px;
    }

    @media(max-width: 1024px) {
        font-size: 40px;
    }

    @include max-screen($breakpoint-mobile) {
        font-size: 30px;
    }
}

h2 {
    font-family: $font-gilroy-xbold;
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: .8rem;
}

h3 {
    font-family: $font-gilroy-xbold;
    font-size: 24px;
    line-height: 1.2;

    margin-bottom: 1rem;
}


//////



.top-float {
    position: absolute;
    top: 0;
}
#posters
{
    padding:100px 0px;
}

#post
{
    overflow:hidden;
    height:4500px;
    position: relative;
    padding:100px 0px 100px;

    .l-index__heading-top {

        svg {

            height: 15vh;
        }
    }
}

#post>.post-inner
{
    width:100vw;
    height:100vh;
    height: calc(var(--vh 1vh) * 100);
    position:absolute;
}

#cont_posters #post>.post-inner>.post-inner-holder
{
    position:relative;
    left:-60vw;
    width:600vw;
    height:100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: repeat(10, 24vw);
    grid-gap: 4vw;
}

#cont_posters #post>.post-inner>.post-inner-holder {

}

#cont_posters img
{

    width:100%;
    top:50%;
    transform:translateY(-50%);
    display: block;
    position: relative;

}

#cont_posters a{
    display: block;
    transform:scale(1);
    transition: .3s all;
}

#cont_posters a:hover{
    transform:scale(1.09);
}

#double_g
{
    height:200vh;
    background:#000;
}

#double_g #cont_doubleg,
#double_g #cont_doubleg>div
{
    width:100vw;
    height:100vh;
    position: relative;
}

#double_g #cont_doubleg>div
{
    position: absolute;
    background:#E2302E;
    overflow: hidden;
    top:0px;
}

#double_g #cont_doubleg>div#second_g
{
    z-index:10;
    height:0px;
    background:#000;
}

#double_g #cont_doubleg>div>div
{
    width:100vw;
    height:100vh;
    position:relative;
}

#double_g #cont_doubleg>div>div img
{
    position:absolute;
    display: block;
    width:60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#chap_part {
    height: 3000px;

    @media (max-width:$md) {

    }

    @media (max-width:$sm) {
        display: none;
    }
}

#chap_part>div {
    width: 100vw;
    overflow: hidden;
    background: #F1F1E8;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

#bauhausEdificio {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
}

#chap_part>div>#bautitle {
    display: block;
    position: absolute;
    z-index: 5;
    top: 50%;
    // transform: translate(511vw, -50%) scale(116);
    transform: translate(430vw, -50%) scale(75);

    // transform: translate(575vw, -50%) scale(173);
}
.l-index__services-bg {
    min-height: 100vh;
}

.section-services-list {
    min-height: 64vh;
}

.p-l2 {
    text-align: center;
    font-size: 19px;

    @include max-screen($breakpoint-mobile) {
        font-size: inherit;
    }
}


.banner-l2 {
    overflow: hidden;
    display: flex;
    align-items: center;


    &.banner-bg:after {
        display: none;
    }

    .l-service__eyecatch-left {
        background: #000;
        max-width: 675px;
        padding: 3rem 4.3rem 3.5rem;
        margin-left: -4.3rem;

        h1 {
            font-size: 46px;
            line-height: 1.2;
            margin-bottom: 10px;
        }

        p {
            width: 100%;
        }

        @media(max-width: 767px) {
            margin: 2em auto 0;
        }
    }
}


///

.section {
    padding: 3rem 0;

    @include max-screen($breakpoint-mobile) {
        padding: 2rem 0;
    }
}


.text-center {
    text-align: center;
}