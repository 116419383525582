.work-block {
    text-align: center;

    color: #fff;
}

.work-block__title {
    font-family: $font-gilroy-xbold;
    font-size: 42px;

    margin-bottom: 20px;

    text-align: left;
}

.work-block__image {
    width: 100%;
    height: 333px;

    object-fit: cover;
}

.work-block__description {
    font-weight: bold;
    line-height: 1.8;

    position: relative;

    width: 65%;
    margin: 30px 45px 30px auto;

    text-align: left;

    color: #cdcdcd;

    &:before {
        position: absolute;
        top: 6px;
        left: -80px;

        width: 100%;
        height: 30px;

        content: '';
        content: '';

        background-image: url(#{$path-image}/works/quote.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
}
