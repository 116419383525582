.footer {
    padding: 30px 0;
    margin-top: 2rem;

    @include max-screen($breakpoint-mobile) {
        padding: 25px 0 40px;
    }
}

.footer__left {
    @include max-screen($breakpoint-mobile) {
        width: 100%;

        text-align: center;
    }
}

.footer__logo {
    @include max-screen($breakpoint-mobile) {
        height: 70px;
    }
}

.footer__middle {
    text-align: center;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        margin: 30px 0;
    }

    small {
        font-size: 15px;

        color: #858585;
    }
}

.footer__links {
    font-size: 17px;

    margin-bottom: 5px;

    @include max-screen($breakpoint-mobile) {
        margin-bottom: 15px;
    }

    li {
        margin-right: 12px;

        @include max-screen($breakpoint-mobile) {
            width: 50%;
            margin-right: 0;
            margin-bottom: 15px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
    a {
        transition: color $duration $easing;

        color: #858585;

        &:hover {
            color: $color-primary;
        }
    }
}

.footer__right {
    @include max-screen($breakpoint-mobile) {
        width: 70%;
        margin: 0 auto;
    }
}

.footer__sns {
    li {
        margin-right: 15px;

        &:last-child {
            margin-right: 0;

            .icon {
                position: relative;
                top: 1px;
            }
        }
    }
    a {
        line-height: 2.7;

        position: relative;

        display: block;

        width: 45px;

        text-align: center;

        color: #fff;
        background-image: url(#{$path-image}/sns-border.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include max-screen($breakpoint-mobile) {
            line-height: 2.6;

            width: 42px;
        }
    }
}

.footer2 {
    padding-top: 0;

    background: #1a1a1a;

    .footer__sns {
        a {
            line-height: 2.5;

            border-radius: 50%;
            background-color: #29292b;
            background-image: none;

            &:before {
                display: none;
            }

            i {
                position: relative;
                top: 2px;
            }
        }
    }
}
