.section-iframe-tours {
    position: relative;
    z-index: 2;
    margin-bottom: -130px;

    .laptop-wrap {
        position: relative;
        width: 100%;
        overflow: hidden;
        width: 740px;
        max-width: 95%;
        margin: auto;

        .iframe-responsive {
            width: 100%;
            height: 100%;
            border: none;

            iframe {
                position: absolute;
                width: 82.3%;
                height: 82%;
                top: 7%;
                left: 9%;
            }
        }

        img {
            width: 100%;
        }
    }
}

.section-gallery-grid {
    .gallery-wrap {
        display: flex;
        flex-wrap: wrap;

        .image-item {
            flex: 0 0 25%;
            max-width: 25%;

            img {
                height: 260px;
                width: 100%;
                object-fit: cover;
            }

            @include max-screen($breakpoint-mobile) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}

.section-title {
    font-family: "Gilroy-ExtraBold";
    font-size: 45px;

    &.lg {
        font-size: 50px;
    }

    p {
        color: #D4D4D4;
        font-family: "Gilroy-Regular";
        font-size: 18px;

    }
}

.section-title-actions {
    display: flex;
    margin-bottom: 40px;

    .section-title {
        width: 66%;
        max-width: 100%;

    }

    .button {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: 15px;
    }

    &.bb {
        border-bottom: 1px solid #333333;
        padding-bottom: 40px;
    }
}

.section-service-features {
    padding: 90px 0;

    .section-title {
        @media(max-width: 640px) {
            font-size: 40px;
        }
    }

    .service-feature-list {
        overflow: hidden;
        margin-top: 4em;

        .feature-item {
            width: 25%;
            float: left;
            position: relative;

            .number {
                position: absolute;
                top: 0;
                left: 0;
                color: #101010;
                font-size: 80px;
                line-height: .9;
                z-index: 1;
                font-family: $font-gilroy-xbold;
            }

            h3 {
                position: relative;
                z-index: 2;
                padding: 47px 0 0 41px;

                @media(max-width: 1024px) {
                    font-size: 20px;
                }
            }

            @media(max-width: 991px) {
                width: 50%;
                margin-bottom: 2em;
            }

            @media(max-width: 640px) {
                width: 100%;
            }
        }
    }

    @media(max-width: 1280px) {
        padding: 4rem 0;
    }

    @media(max-width: 767px) {
        .section-title-actions {
            display: block;
            text-align: center;

            .section-title {
                margin-bottom: 15px;
            }

            .section-title, .button {
                width: 100%;

            }
        }
    }
}

.service-feature-list {
    &.list-view {
        display: flex;
        flex-wrap: wrap;

        .feature-item {
            display: flex;
            flex: 0 0 50%;
            max-width: 50%;

            &.full-width {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 30px;

                .content {
                    h2 {
                        margin-bottom: 8px;
                    }
                }
            }

            @include max-screen($breakpoint-mobile) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .img-wrap {
                position: relative;
                display: inline-block;
                margin-bottom: 35px;
                background-color: #1f1f21;
                margin-right: 30px;
                padding: 35px 25px;
                display: flex;
                text-align: center;

                img {
                    margin: auto;
                    text-align: center;
                    width: 46px;
                    max-width: 46px;
                }
            }

            .content {
                h2 {
                    font-family: "Gilroy-ExtraBold";
                    font-size: 22px;
                    margin-bottom: 4px;
                    line-height: 1;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }
}

.section-vr {
    .detail-block__image {
        display: flex;
        flex-direction: column;

        img {
            display: block;
            width: 100%;
            margin-top: auto;
            margin-bottom: auto;
            object-fit: cover;

            &.full{
                height: 100%;
                width: 100%;
            }
        }
    }

    .detail-block__text {
        p {
            font-family: Gilroy-Regular;
            line-height: 30px;
        }
    }

    @media(max-width: 1280px) {
        padding: 4rem 0 !important;
    }

    @media(max-width: 767px) {
        .detail-block__text {
            text-align: center;
        }

        .detail-block__image {
            min-height: 0;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.detail-block {
    &.detail-block-alt {
        .detail-block__image {
            flex: 0 0 30%;
            max-width: 30%;
            margin-top: auto;
            margin-bottom: auto;

            img {
                margin: auto;
            }

            @media(max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .detail-block__text {
            flex: 0 0 70%;
            max-width: 70%;
            margin-top: auto;
            margin-bottom: auto;

            @media(max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    &.detail-block-40-50 {
        .detail-block__image {
            flex: 0 0 45%;
            max-width: 45%;
            margin-top: auto;
            margin-bottom: auto;

            img {
                margin: auto;
            }

            @media(max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .detail-block__text {
            flex: 0 0 55%;
            max-width: 55%;
            margin-top: auto;
            margin-bottom: auto;

            @media(max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

.section-cta-offset {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 120px;
    padding-bottom: 120px;

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background-color: rgba(#000,.4);
        z-index: 1;
    }

    .section-cta-img {
        width: 100%;
        height: 550px;
        object-fit: cover;
    }

    .content {
        position: relative;
        width: 915px;
        padding: 50px 55px 50px;
        background-color: #232323;
        margin: auto;
        text-align: center;
        max-width: 95%;
        z-index: 2;

        h2 {
            font-family: "Gilroy-ExtraBold";
            font-size: 50px;
            margin-bottom: 15px;

            @media(max-width: 640px) {
                font-size: 40px;
            }
        }

        p {
            margin-bottom: 40px;
        }
    }
}

.section-features-col-2 {
    position: relative;
    margin-top: 120px;
    margin-bottom: 120px;
    overflow: hidden;

    .col-content {
        width: 40%;
        background-color: transparent;
        text-align: left;
        padding: 0 0 0px;

        @media(max-width: 991px) {
            width: 100%;
        }
    }

    .section-features-img {
        width: 45%;
        height: auto;
        position: absolute;
        top: 0;
        right: 3%;
        z-index: 1;
        height: 800px;
        object-fit: cover;

        @media(max-width: 991px) {
            width: 100%;
            height: auto;
            position: relative;
            top: auto;
            right: auto;
            margin-top: 4em;
        }
    }

    .section-heading {
        margin-bottom: 45px;
    }

    .service-feature-list {
        margin-bottom: 45px;

        @media(max-width: 1280px) {
            margin-bottom: 15px;
        }

        @media(max-width: 991px) {
            margin-bottom: 0;
        }
    }

    @media(max-width: 1280px) {
        margin: 4rem 0;
    }

    @media(max-width: 991px) {
        margin-bottom: 0;
    }
}

.section-page-intro {
    .intro-content {
        width: 990px;
        max-width: 100%;
        padding: 65px 0;
        text-align: center;
        margin: auto;

        h1 {
            margin-bottom: 15px;
        }

        .description {
            margin-bottom: 0;
        }
    }
}

.section-how-it-works {
    position: relative;
    background-color: #1D1D1D !important;
    padding-top: 195px !important;
    z-index: 1;

    .l-service__heading {
        font-size: 45px;
    }

    .process-block__icon,
    .l-service-v3__intro-block__icon {
        background-color: #2D2D2D;
    }

    .l-service-v3__intro-listing {
        li {
            margin-bottom: 0;

            @media(max-width: 767px) {
                margin-bottom: 30px;
                padding: 0;
            }
        }
    }

    @media(max-width: 1024px) {
        padding-bottom: 4rem !important;
    }
}

.section-top-video-wrap {
    position: relative;
    margin-top: -5.3rem;
    padding-top: 5.3rem;

    .video-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        display: -ms-flexbox;
        -ms-flex-direction: column!important;
        flex-direction: column!important;

        & > div{
            min-height: 100%;
            display: flex;
            display: -ms-flexbox;
            -ms-flex-direction: column!important;
            flex-direction: column!important;

            .plyr--video, .plyr__video-wrapper{
                min-height: 100%;
                display: flex;
                display: -ms-flexbox;
                -ms-flex-direction: column!important;
                flex-direction: column!important;

                video{
                    object-fit: cover;
                }
            }

        }

        .plyr__control--overlaid,
        .plyr--video .plyr__controls {
            display: none !important;
        }

         &:before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-color: transparent;
            z-index: 3;
        }
    }

    .l-service-v2__eyecatch-right {
        right: auto;
        left: -30px;
    }

    .l-section-left {
        padding: 2rem 0;
    }

    .l-container {
        position: relative;
        z-index: 4;
        padding: 3rem 0;
    }

    .l-service__intro {
        text-align: center;
    }
    .description{
        color: #fff;

        p{
          color: #fff;  
        }
    }
    
    .l-service-v2__circle{
        transform: scale(1.45);
        z-index: 1;
    }
    
    &.faded{

        .video-bg{

            & > div{
                position: relative;
                &:after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 40%;
                    width: 100%;
                    z-index: 2;
                    background: rgb(0,0,0);
                    background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 23%, rgba(0,0,0,0.7189250700280112) 42%, rgba(0,0,0,0) 100%);
                    background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 23%, rgba(0,0,0,0.7189250700280112) 42%, rgba(0,0,0,0) 100%);
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 23%, rgba(0,0,0,0.7189250700280112) 42%, rgba(0,0,0,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                }
            }
        }
    }
}


.section-feature-list{
    &.column-4{
        .l-service-v3__intro-listing.u-flex{
            align-items: baseline;
            li{
                width: 20%;
                margin-bottom: 50px;
            }
        }

    }
}


.section-video-list{
    .plyr--video{
        pointer-events: none!important;
        video{
          pointer-events: none!important;  
        }
        .plyr__captions, .plyr__control, .plyr__controls {
            display: none!important;
            opacity: 0;
            visibility: 0;
        }
    }
}