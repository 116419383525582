
.header__logo {
    position: fixed;
    z-index: 2;
    left: 6.5rem;
    top: 1rem;
    width: 170px;
    display: block;
    transition: all .3s;

    img {
        width: 100%;
    }


    @media (max-width:$sm) {
        left: 4rem;
        top: 0.8rem;
        width: 136px;
    }

}

body.home {
    //.header__logo {
    //    top: -5.5rem;
    //
    //    &.is-show {
    //        top: 1.5rem;
    //    }
    //}
}


.header {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;


    &.is-open {
        z-index: 99;
    }

    .header__menu-next {

    }

    .home & {
    }

    &.is-show {

    }

    @include max-screen($sm) {
        width: auto;
    }
}


.header__menu {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 90px;
    // height: 100vh;
    min-height: 86px;

    transition: height .2s;

    // background: linear-gradient(0deg, #97080c 0%, #fbb431 100%);

    align-items: start;
    justify-content: center;


    &-icon {
        position: relative;

        display: block;

        width: 20px;
        margin-top: 1.5rem;

        border-color: #fff;

        &:hover {
            span {
                background-color: $color-primary;
            }
        }

        span {
            position: relative;
            right: -2px;

            display: block;

            width: 20px;
            height: 3px;
            margin: 5px 0;

            transition: all $duration $easing;

            background-color: #fff;

            &:nth-child(2) {
                left: -2px;
            }
        }
    }


    .is-open & {
        z-index: 9999!important;

        background: none;
    }

    .is-scrolled  & {
        height: 86px;
    }

    .is-locked &,
    .is-loading & {
        z-index: 0;
    }

    @media (max-width: 1250px) {
        height: 86px;
    }

    @media (max-width:$sm) {

    }
}


.button-menu {
    position: relative;
    width: 60px;
    height: 60px;
    margin-top: 10px;
    cursor: pointer;
    transition: all .2s;

    &,
    * {
        transition: all .25s ease-in-out;
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 22px;
        height: 3px;

        transform: translate(-50%, -50%);

        background: #fff00;

        &:after,
        &:before {
            position: absolute;

            width: 24px;
            height: 3px;

            content: '';
            transition: all .25s ease-in-out;

            background: #fff;
        }

        &:before {
            transform: rotate(0deg) translate(0px,-4px);
        }
        &:after {
            transform: rotate(0deg) translate(0px,4px);
        }
    }


    &:hover {
        i {
            &:before {
                transform: rotate(0deg) translate(-4px, -4px);
            }
            &:after {
                transform: rotate(0deg) translate(4px, 4px);
            }
        }
    }


    .is-open & {
        i {
            &:before {
                transform: rotate(-45deg) translate(0px,0px);
            }
            &:after {
                transform: rotate(45deg) translate(0px,0px);
            }
        }

        &:hover {
            i {
                &:before {
                    transform: rotate(0deg) translate(0px,0px);
                }
                &:after {
                    transform: rotate(0deg) translate(0px,0px);
                }
            }
        }
    }
}

.home .button-menu {

    margin-top: 20px;
}


.header__menu-next {
    font-weight: bold;

    position: fixed;
    z-index: 4;
    bottom: 62px;
    left: 8px;

    display: flex;

    transition: all .1s;
    transform: rotate(-90deg)!important;
    text-align: right;
    letter-spacing: .05em;

    opacity: .5;
    color: #fff;

    align-items: center;

    i {
        position: relative;
        left: 0;

        display: block;

        width: 30px;
        height: 8px;
        margin-right: 8px;

        transition: left $duration $easing;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;

            width: 26px;
            height: 2px;

            content: '';
            transition: left $duration $easing;
            transform: translateY(-50%);

            background-color: #fff;
        }
        &:after {
            position: absolute;
            top: 50%;
            left: 0;

            display: block;

            width: 8px;
            height: 8px;

            content: '';
            transition: left $duration $easing;
            transform: translateY(-50%) rotate(-45deg);

            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
        }
    }

    &:hover {
        opacity: 1;

        i {
            left: -8px;
        }
    }
}

.fp-viewing-hero header {
    .header__menu-next {
        bottom: 62px;
    }
}

.header-r {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;

    padding: 2.5rem 3rem;

    transition: all .3s;

    @media (max-width:$sm) {
        padding: 1.7rem 1.5rem;
    }
}

.cta-link-phone {
    font-family: $font-gilroy-xbold;
    font-size: 16px;

    color: #fff;


    svg {
        width: 18px;
        height: auto;
        margin-right: 8px;
    }
}

.header__services--links {
    font-weight: 800;

    position: fixed;
    top: 36px;
    left: 21em;

    padding: 11px 35px;

    color: #a6a6a6;
    border-left: 1px solid #343434;

    .icon {
        position: relative;
        top: -2px;

        display: inline-block;

        margin-right: 5px;
    }
}

.header-second {
    overflow: hidden;

    padding: 12px 0;

    background-color: #202020;

    .l-container {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;

        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;

        @media(max-width: 767px) {
            display: block !important;
            text-align: center;
        }
    }

    .menu2 {
        li {
            display: inline-block;

            vertical-align: middle;

            a {
                font-family: $font-gilroy-xbold;
                line-height: 1;

                display: block;

                padding: 0 20px;

                transition: all .3s;

                color: #fff;
                border-right: 1px solid #626262;

                &:hover {
                    color: #dd6822;
                }
            }

            &.active {
                a {
                    color: #dd6822;
                }
            }
            &:first-child {
                a {
                    padding-left: 0;
                }
            }

            &:last-child {
                a {
                    border-right: 0;
                }
            }

            @media(max-width: 767px) {
                display: block;
                padding: 0 0 15px;

                &:last-child {
                   padding-bottom: 30px;
                }

                a {
                    border: none;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        &.menu-center {
            margin: 0 auto;

            text-align: center;

            li {
                a {
                    font-size: 17px;
                }
            }
        }
    }

    .button {
        margin-left: auto;
    }

    @media(max-width: 767px) {
        padding: 30px 0;
    }
}

.header__menu2 {
    height: auto;

    background: transparent;

    .header__menu-trigger {
        span {
            background-color: #fff;
        }
    }
}

.header-top {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;

    overflow: hidden;

    width: 100%;
    padding: 20px;

    background-color: #000;

    .header-left {
        float: left;

        padding-left: 90px;

        .header__logo,
        .header__services--links {
            display: inline-block;

            vertical-align: middle;
        }

        .header__logo {
            position: relative;
            top: auto;
            left: auto;
        }

        .header__services--links {
            position: relative;
            top: auto;
            left: auto;

            margin-left: 35px;
        }
    }

    .header-right {
        float: right;

        padding: 28px;

        .cta-link-phone {
            &:after {
                display: inline-block;

                width: 27px;
                height: 27px;
                margin: -5px 0 0 20px;

                content: '';
                vertical-align: middle;

                background-image: url('../images/header-circle.png');
                background-position: center center;
                background-size: contain;
            }
        }
    }
}



.is-scrolled {
    .header__logo {
        position: fixed;
        z-index: 2;
        top: .5rem;
        left: 7rem;

        width: 155px;
    }
    .header__menu-trigger {
        span {
            height: 2px;
        }
    }

    .header-r {
        padding: 1.8rem 2rem;
    }
}




body.page {
    .header {
        right: 0;
        height: 84px;
        min-height: 86px;
        border-bottom: 1px solid rgba(#fff, .20);
        background: #000;

        &__menu {
            width: 85px;

            transition: height .4s ease;
            transition-delay: .2s;

            background-color: #F79E17;
        }
    }

    .header__logo {
        top: .5rem;
        width: 155px;

    }

    .header-r {
        padding: 1.8rem 2rem;



        a{
            display: inline-block;
        }
    }
}


///// SERVICES

.l-mega-menu {
    font-family: 'Gilroy-ExtraBold';
    font-size: 17px;

    position: fixed;
    z-index: 999;
    top: 1.7rem;
    left: 19rem;

    display: flex;

    padding-left: 2rem;

    color: #e4e4e4;

    align-items: center;


    &:before {
        position: absolute;
        left: 0;

        width: 1px;
        height: 35px;

        content: '';

        background: #3c3c3c;
    }

    &-dropdown {
        position: fixed;
        z-index: -3;
        top: 8.4rem;
        right: 0;
        left: 0;

        padding: 2rem 0;

        transition: top .3s, opacity .4s;

        opacity: 0;
        border-top: 1px solid transparent;
        background: #000;


        h4 {
            font-family: 'Gilroy-ExtraBold';
            font-size: 30px;

            margin-bottom: 1.5rem;
        }

        .l-index__services-list {
            position: relative;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
        }



        .l-index__services-item {
            width: 25%;

            .service-link {
                span {
                    min-height: 65px;
                }
            }
        }
    }


    &-icon {
        display: block;

        margin-right: .6rem;
        padding: 0 10px;

        i:after,
        i:before,
        i {
            position: relative;

            display: block;

            width: 4px;
            height: 4px;

            content: '';

            background: #e4e4e4;
        }

        i {
            margin: 3px auto;

            &:before,
            &:after {
                position: absolute;
            }

            &:before {
                left: 8px;
            }

            &:after {
                right: 8px;
            }
        }
    }


    &-is-open {
        .l-mega-menu {
            color: #b12b2a;
        }

        .l-mega-menu-icon i,
        .l-mega-menu-icon i:after,
        .l-mega-menu-icon i:before {
            background: #b12b2a;
        }

        .l-mega-menu-dropdown {
            z-index: 9;
            top: 5.4rem;

            opacity: 1;
            border-color: #2d2d2d;;
        }
    }
}

