.link {
    position: relative;

    transition: color $duration $easing;

    &:after {
        position: absolute;
        bottom: -8px;
        left: 0;

        width: 100%;
        height: 3px;

        content: '';

        background-color: $color-primary;
    }

    &:hover {
        color: $color-primary;

        &:after {
            animation: slide-right .6s linear;
        }
    }
}

@keyframes slide-right {
    0%,
    to {
        left: 0;

        width: 100%;
    }
    25% {
        left: 50%;

        width: 50%;
    }
    50% {
        left: 100%;

        width: 0;
    }
    51% {
        left: 0;
    }
    75% {
        width: 50%;
    }
}
