//antialiased,subpixel-antialiased,none
$font-smoothing: antialiased;

//auto,optimizeSpeed,optimizeLegibility,geometricPrecision
$text-rendering: auto;

$base-font-family: 'Gilroy-Regular', sans-serif;
$base-font-size: 17px;
$base-font-color: #d8d8d8;
$base-background-color: #000;

$site-size: 1200px + 60;

// colors
$color-primary: #b12b2a; // red orange
$color-secondary: #fbdc2e; // yellow
$color-orange: #fb8631;
$color-light-gray: #d5d5d5;

// easing / animation
$duration: .35s;
$easing: ease-in-out;
$delay: .35s;

//breakpoints
$breakpoint-mobile: 767px;
$md: 1024px;
$sm: 767px;
$xs: 425px;

//path
$path-assets: "/wp-content/themes/lightmedia/assets";
$path-image: "#{$path-assets}/images";
$path-font: "#{$path-assets}/fonts";

$font-gilroy-light: 'Gilroy-Light';
$font-gilroy-xbold: 'Gilroy-ExtraBold';
$font-montserrat: 'Montserrat', sans-serif;
$font-press-start: 'Press Start 2P', cursive;

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('#{$path-font}/Gilroy-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path-font}/Gilroy-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path-font}/Gilroy-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('#{$path-font}/Gilroy-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Gilroy-Light';
    src: url('#{$path-font}/Gilroy-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path-font}/Gilroy-Light.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path-font}/Gilroy-Light.woff') format('woff'), /* Pretty Modern Browsers */
    url('#{$path-font}/Gilroy-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('#{$path-font}/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path-font}/Gilroy-ExtraBold.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path-font}/Gilroy-ExtraBold.woff') format('woff'), /* Pretty Modern Browsers */
    url('#{$path-font}/Gilroy-ExtraBold.ttf')  format('truetype'), /* Safari, Android, iOS */
}
