.l-about__heading {
    font-family: $font-gilroy-xbold;

    margin-bottom: 15px;
}

.l-about__eyecatch {
    padding: 0 0 250px;

    @include max-screen($breakpoint-mobile) {
        padding: 80px 0 40px;
    }
}

.l-about__eyecatch-content {
    position: relative;

    @include max-screen(1399px) {
        width: 90%;
        padding-left: 7vw;
    }
    @include max-screen($breakpoint-mobile) {
        width: 100%;
        padding-left: 0;
    }

    &:before {
        position: absolute;
        top: -60px;
        right: -30px;

        display: block;

        width: 86px;
        height: 100px;

        content: '';

        background-image: url(#{$path-image}/index/dots.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include max-screen($breakpoint-mobile) {
            right: 30px;

            width: 66px;
            height: 70px;
        }
    }
}

.l-about__eyecatch-title {
    font-family: $font-gilroy-xbold;
    font-size: 45px;
    line-height: 1.2;
    
    margin-bottom: 40px;

    @include max-screen($breakpoint-mobile) {
        font-size: 32px;

        width: 100%;
    }
}

.l-about__eyecatch-description {
    p {
        line-height: 1.8;

        width: 46%;

        color: $color-light-gray;

        @include max-screen($breakpoint-mobile) {
            font-size: 16px;

            width: 100%;
            margin-bottom: 20px;
        }
    }

    &.l-about__eyecatch-description-full {
        p {
            width: 100%;
        }
    }
}

.l-about__intro {
    padding-bottom: 5rem;

    background-color: #1a1a1a;

    @include max-screen($breakpoint-mobile) {
        padding-bottom: 20px;
    }

    ul {
        margin-top: 1rem;

        counter-reset: process;

        align-items: initial;
        @include max-screen($breakpoint-mobile) {
            margin-top: 20px;
        }
    }
    li {
        width: 20%;

        counter-increment: process;

        @include max-screen($breakpoint-mobile) {
            width: 100%;
            margin-bottom: 40px;
        }
    }
}

.l-about__intro-banner {
    width: 100%;
    height: 460px;
    margin-top: -200px;
    margin-bottom: 50px;

    object-fit: cover;
    @include max-screen($breakpoint-mobile) {
        height: 290px;
        margin-top: 0;
    }
}

.l-about__values {
    position: relative;

    padding: 4.5rem 0;

    @include max-screen($breakpoint-mobile) {
        padding: 70px 0 40px;
    }

    ul {
        position: relative;
        z-index: 1;

        margin-top: 70px;
        margin-left: 160px;

        counter-reset: details;
        cursor: draggable;

        @include max-screen($breakpoint-mobile) {
            margin-top: 150px;
            margin-left: 0;
        }

        li {
            padding-right: 35px;

            counter-increment: details;

            @include max-screen($breakpoint-mobile) {
                padding-right: 15px;
            }
        }
    }
}

.l-about__values-arrows {
    position: absolute;
    top: 3rem;
    right: 190px;

    width: 140px;

    @include max-screen($breakpoint-mobile) {
        top: 200px;
        right: 0;
        left: 50%;

        transform: translateX(-50%);
    }
}

.l-about__values-arrows-note {
    font-family: $font-gilroy-xbold;
    font-size: 14px;
    line-height: 5.5;

    position: relative;
    left: 11px;

    width: 82px;
    height: 82px;

    text-align: center;

    border: 3px dashed rgba(95, 95, 95, .5);
    border-radius: 50%;
}

.l-about__values-arrow {
    position: relative;

    cursor: pointer;

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;

        width: 15px;
        height: 15px;

        content: '';
        transform: translate(-50%, -50%) rotate(-45deg);

        border-top: 2px solid #2e2e30;
        border-left: 2px solid #2e2e30;
    }

    &:hover {
        opacity: 1;
    }

    // MODIFIER
    &.l-about__values-arrow--next {
        margin-left: 20px;

        &:before {
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    &.slick-disabled {
        opacity: .5;
    }
}

.l-about__characteristics {
    padding: 0 0 100px;

    @include max-screen($breakpoint-mobile) {
        padding: 60px 0;
    }
}

.l-about__characteristics-left {
    width: 45%;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        margin-bottom: 40px;
    }

    p {
        line-height: 1.8;

        margin-bottom: 25px;

        color: $color-light-gray;

        @include max-screen($breakpoint-mobile) {
            font-size: 15px;
        }
    }
}

.l-about__characteristics-right {
    width: 42%;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
    }

    ul {
        li {
            font-size: 20px;
            font-weight: bold;

            position: relative;

            padding-left: 30px;

            @include max-screen($breakpoint-mobile) {
                font-size: 16px;

                padding-left: 35px;
            }

            &:not(:last-child) {
                margin-bottom: 14px;

                @include max-screen($breakpoint-mobile) {
                    margin-bottom: 18px;
                }
            }
            &:last-child {
                margin-bottom: 35px;

                @include max-screen($breakpoint-mobile) {
                    margin-bottom: 0;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: -15px;

                width: 28px;
                height: 28px;

                content: '';

                border-radius: 50%;
                background: linear-gradient(90deg, $color-orange 0%, $color-primary 80%);

                @include max-screen($breakpoint-mobile) {
                    left: 0;

                    width: 23px;
                    height: 23px;
                }
            }
            &:after {
                position: absolute;
                top: 6px;
                left: -5px;

                width: 8px;
                height: 13px;

                content: '';
                transform: rotate(45deg);

                border-right: 2px solid #000;
                border-bottom: 2px solid #000;

                @include max-screen($breakpoint-mobile) {
                    top: 4px;
                    left: 7px;

                    width: 6px;
                    height: 11px;
                }
            }
        }
    }
}

.l-about__cta {
    margin-left: 50px;

    justify-content: initial;
    @include max-screen($breakpoint-mobile) {
        margin-left: 0;
    }
}

.l-about__cta-images {
    width: 50%;

    align-items: initial;
    @include max-screen($breakpoint-mobile) {
        width: 100%;
    }

    img {
        width: 100%;

        object-fit: cover;

        &:first-child {
            width: 47%;
            height: 287px;

            @include max-screen($breakpoint-mobile) {
                height: 250px;
            }
        }
        &:nth-child(2) {
            width: 49%;
            height: 193px;

            @include max-screen($breakpoint-mobile) {
                height: 165px;
            }
        }
        &:nth-child(3) {
            width: 47%;
            height: 240px;
            margin-top: 30px;

            @include max-screen($breakpoint-mobile) {
                height: 210px;
                margin-top: 15px;
            }
        }
        &:last-child {
            width: 49%;
            height: 329px;
            margin-top: -60px;

            @include max-screen($breakpoint-mobile) {
                height: 293px;
                margin-top: -70px;
            }
        }
    }
}

.l-about__cta-content {
    width: 35%;
    margin-top: -20px;
    margin-left: 100px;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        margin-top: 30px;
        margin-left: 0;
        padding: 0 15px;
    }

    h2 {
        font-family: $font-gilroy-xbold;
        font-size: 38px;

        margin-bottom: 25px;

        @include max-screen($breakpoint-mobile) {
            font-size: 30px;

            margin-bottom: 15px;
        }
    }
    p {
        line-height: 1.8;

        margin-bottom: 35px;

        color: $color-light-gray;

        @include max-screen($breakpoint-mobile) {
            font-size: 15px;

            margin-bottom: 25px;
        }
    }
}


.l-about {
    @mixin ms(
        // Default values
        $cssClass: 'ms-container',
        $slider-length: 400px,
        $slider-center: false,
        $slide-width: 90px,
        $slide-height: 90px,
        $vertical: false,
        $reverse: false,
        $debug: false
    ) {
        // Slider container
        .#{$cssClass} {
            position: relative;
            @if $slider-center {
                margin: 0 auto;
            }
            overflow: hidden;
            @if $debug {
                background-color: rgba(0, 0, 0, 0.2);
            }

            @if $debug {
                // Just for testing the current slide position (centered)
                &:before {
                    content: '';
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 100%;
                }
            }

            @if $vertical {
                // Styles for vertical slider
                &.ms-container--vertical {
                    width: $slide-width;
                    height: $slider-length;
                    max-height: 100%;

                    @if $debug {
                        &:before {
                            left: 0;
                            top: calc(50% - #{$slide-height / 2});
                            width: 100%;
                            height: $slide-height;
                        }
                    }

                    // Centering slider track vertically, to get current slide centered
                    .ms-track {
                        flex-direction: column;
                        top: calc(50% - #{$slide-height / 2});
                    }

                    @if $reverse {
                        // Reverse styles
                        &.ms-container--reverse .ms-track {
                            flex-direction: column-reverse;
                            top: auto;
                            bottom: calc(50% - #{$slide-height / 2});
                        }
                    }

                    // Positioning slides vertically
                    .ms-slide {
                        display: flex;
                    }
                }
            } @else {
                // Styles for horizontal slider
                &.ms-container--horizontal {
                    width: $slider-length;
                    height: $slide-height;
                    max-width: 100%;

                    @if $debug {
                        &:before {
                            left: calc(50% - #{$slide-width / 2});
                            top: 0;
                            width: $slide-width;
                            height: 100%;
                        }
                    }

                    // Centering slider track horizontally, to get current slide centered
                    .ms-track {
                        left: calc(50% - #{$slide-width / 2});
                    }

                    @if $reverse {
                        // Reverse styles
                        &.ms-container--reverse .ms-track {
                            flex-direction: row-reverse;
                            left: auto;
                            right: calc(50% - #{$slide-width / 2});
                        }
                    }

                    // Positioning slides horizontally
                    .ms-slide {
                        display: inline-flex;
                    }
                }
            }

            // Slider track
            .ms-track {
                display: flex;
                position: absolute;
                white-space: nowrap;
                padding: 0;
                margin: 0;
                list-style: none;
            }

            // Slides
            .ms-slide {
                align-items: center;
                justify-content: center;
                width: $slide-width;
                height: $slide-height;
                user-select: none;

                @if $debug {
                    // A testing backgrounds for slides
                    &:nth-child(2n) {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    &:nth-child(2n + 1) {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }


    // Images

    $ms-images-slide-width: 500px;
    $ms-images-slide-height: 315px;

    // SCSS mixin to generate the final CSS code for the slider
    @include ms(
            $cssClass: 'ms--images', // CSS class to match the slider container
            $slider-length: 100%,    // The slider container will have full width
            $slider-center: false,   // Don't need to center it, as it is full width
            $slide-width: $ms-images-slide-width,   // Fixed width for each slide
            $slide-height: $ms-images-slide-height, // Fixed height for each slide
            $vertical: false, // The slider should be horizontal
            $reverse: false,  // Normal order
            $debug: false     // No debbug backgrounds in production
    );

    // Custom styles for images slider
    .ms--images {
    left: 0;

        &.ms-container--horizontal .ms-track {
            left: -70px;
        }

        // Slides images
        .ms-slide {
            padding-right: 50px;

            &:nth-child(1) .ms-slide__image {
                background-image: url('#{$path-image}/about/about-img1.jpg');
            }
            &:nth-child(2) .ms-slide__image {
                background-image: url('#{$path-image}/about/about-img2.jpg');
            }
            &:nth-child(3) .ms-slide__image {
                background-image: url('#{$path-image}/about/about-img3.jpg');
            }
            &:nth-child(4) .ms-slide__image {
                background-image: url('#{$path-image}/about/about-img1.jpg');
            }
        }

        .ms-slide__image-container {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            overflow: hidden;
        }

        .ms-slide__image {
            width: 100%;
            height: 100%;
            background-size: cover;
        }
    }


    // Numbers

    $ms-numbers-slide-width: 200px;
    $ms-numbers-slide-height: 100px;

    @include ms(
            $cssClass: 'ms--numbers',
            $slider-length: $ms-numbers-slide-width,
            $slider-center: false,
            $slide-width: $ms-numbers-slide-width,
            $slide-height: $ms-numbers-slide-height,
            $vertical: false,
            $reverse: false,
            $debug: false
    );

    .ms--numbers {
        position: absolute;
        left: -3%;
        top: 18%;
        z-index: 1;
        pointer-events: none;

        .ms-slide {
            font-size: 120px;
            font-family: $font-gilroy-xbold;
            color: #303030;
        }
    }


    // Titles

    $ms-titles-slide-width: 400px;
    $ms-titles-slide-height: 170px;

    @include ms(
            $cssClass: 'ms--titles',
            $slider-length: $ms-titles-slide-height,
            $slider-center: false,
            $slide-width: $ms-titles-slide-width,
            $slide-height: $ms-titles-slide-height,
            $vertical: true,
            $reverse: true,
            $debug: false
    );

    @include ms(
            $cssClass: 'ms--description',
            $slider-length: $ms-titles-slide-height,
            $slider-center: false,
            $slide-width: $ms-titles-slide-width,
            $slide-height: $ms-titles-slide-height,
            $vertical: true,
            $reverse: true,
            $debug: false
    );

    .ms--titles, .ms--description {
        position: absolute;
        left: -3%;
        top: calc(50% - #{$ms-titles-slide-height / 2});
        z-index: 2;
        pointer-events: none;

        .ms-track {
            white-space: normal;
        }

        .ms-slide {

            h3 {
                margin: 0;
                font-size: 40px;
                font-family: $font-gilroy-xbold;
                line-height: 1;
            }
        }
    }

    // Description

    .ms--description {
        bottom: 0;
        top: auto;
        left: 0;

        &.ms-container--vertical {
            width: 415px;
            height: 216px;
        }

        p {
            font-size: 16px;
            color: #D4D4D4;
            font-family: $base-font-family;
        }

        .ms-track {
            width: 100%;

            .ms-slide {
                width: 100%;
            }
        }
    }


    // Links

    $ms-links-slide-width: 120px;
    $ms-links-slide-height: 60px;

    @include ms(
            $cssClass: 'ms--links',
            $slider-length: $ms-links-slide-height,
            $slider-center: false,
            $slide-width: $ms-links-slide-width,
            $slide-height: $ms-links-slide-height,
            $vertical: true,
            $reverse: false,
            $debug: false
    );

    // Responsive styles

    @media screen and (max-width: 860px) {
        .ms--numbers {
            left: calc(50% - #{$ms-numbers-slide-width / 2});
        }

        .ms--titles, .ms--description {
            left: calc(50% - #{$ms-titles-slide-width / 2});
            top: calc(50% - #{$ms-titles-slide-height / 2 + 50px});
            text-align: center;
        }
    }

    @media screen and (max-width: 600px) {
        .ms--images {
            overflow: visible;
        }
    }

    @media screen and (max-width: 400px) {
        .ms--titles, .ms--description {
            .ms-slide {
                transform: scale(0.8);
            }
        }
    }


    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 650px;
        max-width: 1200px;
        margin-top: -5rem;
    }

    .sliders-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 200%;

        .pagination {
            display: none;
        }

    }

    .about-slider {
        overflow: hidden;
    }
}



@media (max-width:$md) {
    .l-about__eyecatch {
        padding: 0;
        margin-bottom: 1rem;
    }

    .l-about__intro-banner {
        margin: 0;
    }

    .l-about__eyecatch-title {
        margin-bottom: 1rem;
    }

    .l-about__intro {background: transparent;padding-bottom: 2rem;}
}