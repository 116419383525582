.selected-works {
	width: 250%;
}

.selected-works__item {
	width: 15%;

	img {
		width: 100%;
		-webkit-filter: saturate(0);
		-webkit-transition : -webkit-filter $duration $easing;
	}

	&:hover {
		img {
			-webkit-filter: saturate(1);
		}
	}
}