
// Init
@import 'setting';
@import 'base/base';
@import 'base/sanitize';

@import 'lib/media-queries';
@import 'lib/utility';
@import 'lib/slick';
@import 'lib/splitting';
@import 'lib/icons';

@import 'modules/header';
@import 'modules/menu';
@import 'modules/hero';
@import "modules/banner";
@import "modules/common";
@import 'modules/service-link';
@import 'modules/circle-dial';
@import 'modules/cursor';
@import 'modules/link';
@import 'modules/button';
@import 'modules/prefooter';
@import 'modules/clock';
@import 'modules/traced-text';
@import 'modules/footer';
@import 'modules/work-block';
@import 'modules/process-block';
@import 'modules/detail-block';
@import 'modules/forms';
@import 'modules/form-group';
@import 'modules/grid-block';
@import 'modules/portfolio-block';
@import 'modules/popup';
@import 'modules/split-image';
@import 'modules/loader';
@import 'modules/pong';
@import 'modules/selected-works';
@import 'modules/sections';
@import 'modules/tabs';

@import 'layout/l-container';
@import 'layout/l-index';
@import 'layout/l-works';
@import 'layout/l-service';
@import 'layout/l-service-v2';
@import 'layout/l-contacts';
@import "layout/l-thank-you";
@import "layout/l-our-works";
@import "layout/l-about";
@import "layout/l-pages";
@import "layout/l-service-v3";
@import "layout/l-privacy";
@import "layout/l-3d-rendering-portfolio";
@import "layout/l-printing";
@import "layout/l-service-virtual";

@import "temp";


@media (max-width:1170px) {
  .l-index__services .l-index__heading-top h2 svg {
    width: 700px;
  }

  .l-index__services-list {
    margin-top: -12rem;
    max-width: 824px;
  }

  .service-link span {
    font-size: 17px;
    line-height: 1;
    min-height: 60px;
    padding: 8px 28px;
  }

  .l-container {
    width: 90%;
  }

  .l-index__services-item {
    padding-right: 1rem;
    margin-bottom: 10px;
  }

  .l-index__services-item {
    width: 33.333%;
  }

  .l-index__services .l-index__heading-top {
    padding-top: 1.5rem;
  }
}

@media (max-width:$md) {
  #chap_part > div > #bautitle {
    transform: translate(482vw, -50%) scale(64);
  }

  #cont_posters #post > .post-inner > .post-inner-holder {
    grid-template-columns: repeat(10, 32vw);
  }

  .pong__game-canvas canvas {
    width: 100%!important;
    height: auto!important;
  }
  .post__content-right {
    padding-left: 2rem;
  }

  .post__content-left {
    width: 61%;
  }

  #double_g #cont_doubleg > div > div {

    h2 svg {
      width: 340px;
    }
  }

  .l-index__services-item {
    width: 100%;
  }

  #posters {
    padding: 2rem 0 0;

    .l-index__heading-top h2 svg {
      width: 620px;
    }
  }

  .l-index__sponsors-left h3 {
    font-size: 1.6rem;
    padding: 0;
  }

  .l-index__sponsors-left span {
    line-height: 1.2;
    font-size: 26px;
    margin-bottom: 7px;
  }

  .l-index__sponsors-left p {
    line-height: 1.5;
    font-size: 16px;
    margin-bottom: 14px;
  }


  .l-index__sponsors-list li {
    padding: 0 10px;
  }

  footer.footer > .u-flex {
    display: block;
    text-align: center;
  }

  ul.footer__sns.u-flex {
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  footer.footer > .u-flex {
    flex-direction: column-reverse;
    display: flex;
    flex-wrap: wrap;
  }

  footer.footer > .u-flex > * {
    width: 100%;
  }

  .footer {
    padding: 2rem 0;
  }

  .footer__middle {
  }

  .footer__middle small {
    padding: 1rem 0;
    display: block;
  }

  img.footer__logo {
    display: none;
  }

  .prefooter > .u-flex {
    display: block;
  }

  .prefooter__left {
    width: 100%;
  }

  .prefooter__right {
    width: 100%;
    padding: 2rem;
  }

  .prefooter__locations ul {}

  .prefooter__locations ul li {
    display: inline-block;
    margin-right: 2rem;
    text-align: center;
  }

  .prefooter__locations-time {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .prefooter__locations-details {
    margin-top: 1rem;
  }

  .prefooter__locations h2 {
    margin-bottom: 13px;
  }

  #post {
    height: 3050px;
    padding: 13vh 0;
  }


  //// SEVICES UI/UX


  body {
    font-size: 17px;
    line-height: 1.5;
  }

  .l-service__process, .l-service-v3__intro {
    padding: 2.5rem 0;
  }

  .process-block__description, .l-service-v3__intro-block__description {
    font-size: inherit;
  }

  .process-block__icon, .l-service-v3__intro-block__icon {
    width: 100px;
    padding: 22px;
  }

  .l-service__process .column-3 li, .l-service-v3__intro .column-3 li {
    padding: 0 1rem;
  }

  .l-service-web-development .l-service__process .column-3, .l-services .l-service__process .column-3, .l-service-web-development .l-service-v3__intro .column-3, .l-services .l-service-v3__intro .column-3 {
    margin-bottom: 1rem;
  }

  .section-what-we-do {
    padding: 3rem 0;
  }

  .section-what-we-do .l-service__heading, .section-what-we-do .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .section-what-we-do .l-service-v3__heading {
    font-size: 2.6rem;
  }

  .section-testimonial h2 {
    font-size: 2.5rem;
  }



}


@media (max-width:$sm) {
  .l-index__services .l-index__heading-top h2 svg {
    width: 500px;
  }
  .section-services-list {min-height: 0;margin-top: -28rem;padding-bottom: 1rem;}

  .service-link span {
    font-size: 17px;
  }

  #cont_posters #post > .post-inner > .post-inner-holder {
    grid-template-columns: repeat(10, 76vw);
  }
  #posters {
    padding: 2rem 0 0;

    .l-index__heading-top h2 svg {
      width: 540px;
    }
  }

  #post {
    height: 1250px;
    padding: 0;
  }

  .l-index__services-item {
    margin-bottom: 10px;
  }

  #double_g #cont_doubleg > div > div {

    h2 svg {
      width: 340px;
    }
  }

  .pong__top h2 {
    font-size: 1.5rem;
    right: 0!important;
    margin-top: 0;
  }

  .pong__content {
    display: block;
    margin-top: 0;
  }

  .post__content-left {
    width: 100%;
  }

  .post__content-right {
    width: 100%;
    padding-left: 0;
    margin-top: 3rem;
  }

  #double_g #cont_doubleg, #double_g #cont_doubleg > div {
    height: 800px;
  }

  .l-index__sponsors h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }


  .l-index__sponsors-left {
    width: 100%;
    padding: 1.5rem 1.5rem 2rem;
  }



  .l-index__sponsors-list li {
    width: 33.333%;
    padding: 1rem;
    margin: 0;
  }

  .l-index__sponsors-right {
    width: 100%;
  }

  ul.l-index__sponsors-list {
    display: flex;
    margin-top: 1rem;
  }

  .l-index__sponsors {
    padding-bottom: 2rem;
  }

  .l-service__testimonies h2, .l-service-v2__testimonies h2, .l-service-v3__testimonies h2 {
    font-size: 28px;
  }

  .l-service__testimonies-list li p {
    font-size: 16px;
    line-height: 1.5;
  }

  .l-service__testimonies.l2, .l2.l-service-v2__testimonies, .l2.l-service-v3__testimonies {
    padding: 2rem 0;
  }

  .prefooter__right h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .prefooter__right {
    margin: 0;
    padding: 1.5rem 2rem;
    margin-top: 4rem;
  }

  .footer__links li {
    width: 100%;
    margin-bottom: 0;
  }

  .footer__links a {
    display: block;
    padding: 5px 0;
  }

  canvas#js-pong-container {
    width: 100%!important;
    height: auto!important;
    margin-bottom: 1rem;
  }

  .pong__game-buttons {
    position: static;
  }

  .pong__game-canvas {
    display: block;
    overflow: visible;
  }

  #double_g #cont_doubleg, #double_g #cont_doubleg > div {height: 100%!important;}
  div#first_g {
    display: none;
  }

  section#double_g {
    height: 830px;
  }

  div#second_g .pong__top {
    display: none;
  }

  .button.button--3d-alt {
    line-height: 1;
    transform-style: inherit;
  }

  .button.button--3d-alt:before, .button.button--3d-alt:after {
    display: none;
  }

  .pong__restart, .pong__stop {
    margin: 0 5px;
    min-width: 140px;
  }

  .l-index__play {
    padding: 1rem 0;
  }

  .l-index__sponsors {
    padding: 2.5rem 0 0;
  }


  //////


  .l-index__services .l-index__heading-top h2 {
    transform: inherit!important;
  }

  .l-index__services .l-index__heading-top h2 svg {width: 100%;}

  .l-index__services .l-index__heading-top h2 {
    position: static!important;
    text-align: left;
  }

  .l-index__services-bg {
    min-height: 0;
    padding: 1rem 0;
  }

  .l-index__services {
    overflow: visible;
  }

  .section-services-list {
    margin-top: 0;
  }
  //
  //.section-home-1 {
  //  height: auto;
  //  padding: 11rem 0 4rem;
  //}

  .l-index__services-bg .services-bg {
    position: static;
    background: transparent;
  }

  .l-index__services-bg .services-bg:after {
    display: none;
  }

  .l-index__services-list {
    margin-top: 0;
  }

  .l-index__services-bg {
    transform: none!important;
  }


  ////
  #cont_posters #post > .post-inner > .post-inner-holder {
    transform: none!important;
    position: static;
    display: block;
    width: auto;
    height: auto;

    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-gap: 0;

    > a {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  #cont_posters img {
    position: static;
    transform: none;
  }

  #post > .post-inner {
    position: static;
    transform: none!important;
    height: auto;
  }

  #cont_posters a:hover {
    transform: none;
  }

  #cont_posters a {
    padding: .5rem;
  }

  .l-index__heading-top.top-float.is-inview {
    position: static;
    transform: none!important;
    margin-bottom: 1rem;
  }

  #posters .l-index__heading-top h2 svg {
    width: 100%;
  }

  #post {
    height: auto;
  }

  #posters {
    opacity: 1!important;
    pointer-events: inherit;
  }

  div#pong-section h2 {
    transform: none!important;
    position: static!important;
  }

  #double_g #cont_doubleg > div > div h2 svg {
    width: 266px;
    height: auto;
    margin-bottom: 1rem;
  }

  #double_g #cont_doubleg, #double_g #cont_doubleg > div {
    height: auto;
    position: static!important;
  }

  section#double_g {
    height: auto;
  }

  .l-index__sponsors h2    > div {
    transform: none!important;
  }

  .prefooter__right {
    margin-top: 0;
  }

  .prefooter__locations ul li {
    margin-right: 0;
    width: 32%;
  }

  ///

  .section-testimonial h2 {
    font-size: 2rem;
  }

  .testimonials > li {
    padding: 1rem;
  }

  .testimonials li p {
    font-size: 17px;
    line-height: 1.4;
  }

  .testimonials:before {
    display: none;
  }

  .testimonials li h3 {
    font-size: 20px;
  }

  div#cont_doubleg {
    display: none!important;
  }

  .section-testimonial {
    padding: 2rem 0;
  }


  .l-mega-menu {
    display: none;
  }

  body.page .header__menu {
    width: 58px;
    min-width: 0;
    min-height: 0;
  }

  .button-menu {
  }

  body.page .header__logo {
    left: 4.8rem;
  }


  ////// FOOTER
  .prefooter__left {
    text-align: center;
  }

}

@media (max-width:$xs) {
  .l-service .description, .l-service-v2 .description, .l-service-v3 .description {
    margin-bottom: 15px;
  }

  h1 {
    margin-bottom: .8rem;
  }

  body {
    font-size: 16px;
  }

  .testimonials {
    padding: 0;
  }

  .section-testimonial h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .prefooter__right h2 {
    text-align: center;
  }

  .prefooter__right p {
    text-align: center;
  }


  .l-service-web-development .l-service__details .detail-block__text {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  .detail-block {
    margin-bottom: 1rem;
  }

  .section-what-we-do .l-service__heading {
    font-size: 30px;
  }


  .l-section-right {
    margin-top: 2rem;
  }

  .l-section-right {
    margin-top: 2rem;
  }

  .detail-block__text h2 {
    font-size: 1.8rem;
  }

  .detail-block__text {
    padding: 1.6rem 0;
  }

  .l-service__details {
    padding: 2.5rem 0 0;
  }


  .l-section-1 {
    padding-top: 2rem;
  }

  .l-service-iphone-and-android-apps .l-service__details ul {
    padding: 0;
  }
  .l-service-iphone-and-android-apps .l-service__info {
    padding: 2rem 0;
  }

  h1,h2,h3,h4,h5,p {
    br {
      display: none;
    }
  }

  .prefooter__right .wpcf7 {
    margin-top: 1rem;
  }
  .prefooter__right .wpcf7 {
    margin-top: 1rem;
  }

  .footer {
    padding-bottom: 0;
    margin: 0;
  }

  ul.footer__sns.u-flex {
    margin-bottom: 0;
  }

  .section-what-we-do {
    padding: 2rem 0;
  }

  .section-what-we-do .l-service__content {
    margin-bottom: 1rem;
  }

  .section-top-video-wrap .l-container {
    padding: 0;
  }

  .l-section-1 {
    padding-bottom: 1rem;
  }

  .l-service-web-development .l-service__process .l-service__intro-content-full {
    margin-bottom: 2rem;
  }

  .detail-block {
    background: transparent;
  }


  /////


  .detail-block-l3 .detail-block__text {
    padding-left: 0;
    text-align: left;
  }

  .l-service__details .detail-block__text h2 {
    text-align: left;
    margin-top: 1rem;
    font-size: 1.6rem;
  }

  .l-service-v2__blurb-left {
    text-align: left;
  }

  .l-service__heading, .l-service-v3__call-to-action .l-service-v3__heading {
    font-size: 1.6rem;
  }

  .l-service-v2-service-photography .l-service-v2__blurb {
    padding: 2rem 0;
  }

  .detail-block__image {
    min-height: 200px;
  }

  .detail-block__text {
    padding-top: 0;
  }

  .l-container {
    padding: 0 12px;
  }
}