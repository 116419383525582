.l-thank-you {
  padding-top: 10em;
  padding-bottom: 100px;
  position: relative;
}

.l-thank-you__top {
  position: relative;
  background: #1f1f20;
  padding: 3.3rem 2rem 3.5rem;
  text-align: center;

  h1 {
    margin-bottom: .6rem;
  }

  svg {
    width: 600px;
    height: auto;
  }

  .description {
    max-width: 877px;
    margin: auto;

    p {
      font-size: 20px;
      opacity: .8;
    }
  }

  &:before {
    position: absolute;
    top: -20px;
    left: -20px;

    display: block;

    width: 33px;
    height: 33px;

    content: '';

    border-top: 5px solid #c4c4c4;
    border-left: 5px solid #c4c4c4;
  }
  &:after {
    position: absolute;
    right: -20px;
    bottom: -20px;

    display: block;

    width: 33px;
    height: 33px;

    content: '';

    border-right: 5px solid $color-primary;
    border-bottom: 5px solid $color-primary;
  }
}




@media (max-width: $md) {

  .l-thank-you__top svg {
    width: 90%;
  }
}

@media (max-width: $sm) {

  .l-thank-you__top .description {
    font-size: 17px;
  }

  .l-thank-you__top svg {
    width: 100%;
  }
  .l-thank-you__top .description p {
    font-size: 17px;
  }

  .l-thank-you__top {
    padding: 2rem 2.2rem 2rem;
  }

}