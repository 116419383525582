.l-works {
    padding: 100px 0 50px;
}

.l-works__top {
    text-align: center;

    h1 {
        font-family: $font-gilroy-xbold;
        font-size: 70px;
    }
}

.l-works__list {
    width: 1024px;
    margin: 70px auto 90px;

    align-items: initial;
    transform-style: preserve-3d;
    will-change: transform;

    li {
        width: 44%;
        margin-bottom: 20px;

        &:nth-of-type(even) {
            margin-top: 65px;
        }
    }
}

.l-works__loading {
    display: block;

    width: 222px;
    margin: 0 auto;
    padding: 15px 0;

    text-align: center;

    color: #cdcdcd;
    background-color: #1f1f20;

    @include max-screen($breakpoint-mobile) {
        font-size: 16px;

        width: 185px;
    }
}
