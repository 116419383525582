.img-gray {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    /* Firefox 3.5+ */
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(100%);
    /* Chrome 19+ & Safari 6+ */
    transition: .2s all linear;

    &:hover {
        filter: none;
        -webkit-filter: grayscale(0%);
    }
}


.text-gold {
    background: linear-gradient(90deg,#ffd24a 35%,#b56c39 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
}

.my-auto{
    margin-top: auto!important;
    margin-bottom: auto!important;
}