.traced-text {

}

.is-active {
    .traced-text {
        path:nth-child(1) {
            animation: line-animation ease-in-out forwards 1s,
            box-shadow .75s ease-in-out forwards 1s;

            stroke-dasharray: 350px;
            stroke-dashoffset: 0;
            fill: transparent;
            stroke: #fff;
        }
    }
}

@keyframes line-animation {
    from {
        stroke-dasharray: 400px;
        stroke-dashoffset: 100%;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes box-shadow {
    to {
        fill: #fff;
    }
}
