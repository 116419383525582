.split-image {
    .cell-grid {
        .cell {
            transition: all 1s $easing;

            opacity: 0;

            &:nth-child(1) {
                transform: translate(-17px, -87px) rotate(5deg);
            }
            &:nth-child(2) {
                transform: translate(-305px, -171px) rotate(2deg);
            }
            &:nth-child(3) {
                transform: translate(-182px, -7px) rotate(12deg);
            }
            &:nth-child(4) {
                transform: translate(-343px, -199px) rotate(32deg);
            }
            &:nth-child(5) {
                transform: translate(-159px, -113px) rotate(10deg);
            }
            &:nth-child(6) {
                transform: translate(-202px, -166px) rotate(48deg);
            }
            &:nth-child(7) {
                transform: translate(-194px, -196px) rotate(7deg);
            }
            &:nth-child(8) {
                transform: translate(-100px, -63px) rotate(40deg);
            }
            &:nth-child(9) {
                transform: translate(-223px, -156px) rotate(9deg);
            }
            &:nth-child(10) {
                transform: translate(-71px, -55px) rotate(50deg);
            }
            &:nth-child(11) {
                transform: translate(-360px, -200px) rotate(55deg);
            }
            &:nth-child(12) {
                transform: translate(-54px, -28px) rotate(60deg);
            }
            &:nth-child(13) {
                transform: translate(-54px, -28px) rotate(60deg);
            }
            &:nth-child(14) {
                transform: translate(-344px, -70px) rotate(39deg);
            }
            &:nth-child(15) {
                transform: translate(-221px, -200px) rotate(120deg);
            }
            &:nth-child(16) {
                transform: translate(-336px, -192px) rotate(32deg);
            }
            &:nth-child(17) {
                transform: translate(-358px, -115px) rotate(17deg);
            }
            &:nth-child(18) {
                transform: translate(-276px, -41px) rotate(144deg);
            }
            &:nth-child(19) {
                transform: translate(-27px, -33px) rotate(95deg);
            }
            &:nth-child(20) {
                transform: translate(-102px, -192px) rotate(140deg);
            }
            &:nth-child(21) {
                transform: translate(-55px, -59px) rotate(21deg);
            }
            &:nth-child(22) {
                transform: translate(-159px, -166px) rotate(110deg);
            }
            &:nth-child(23) {
                transform: translate(-302px, -14px) rotate(184deg);
            }
            &:nth-child(24) {
                transform: translate(-162px, -70px) rotate(192deg);
            }
        }
    }

    // FORM THE SPLIT IMAGE
    &.is-active {
        .cell {
            transform: none !important;

            opacity: 1;
        }
    }
}
