.grid-block {
  background-color: $base-font-color;
  text-align: center;
  padding: 55px;

  .grid-block__icon {
    height: 150px;
    margin-bottom: 20px;
  }

  .grid-block__title {
    color: #202020;
    font-size: 22px;
    font-family: $font-gilroy-xbold;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .grid-block__description {
    color: #4F4F4F;
  }
}