.pt-0 {
  padding-top: 0!important;
}

.pb-0 {
  padding-bottom: 0!important;
}

.pt-100 {
  padding-top: 100px!important;
}

.mb-20 {
  margin-bottom: 20px!important;
}

.l-service-v2 {
  @extend .l-service;
}

.l-service-v2__eyecatch-right {
  @extend .l-service__eyecatch-right;
  height: 300px;
  width: 730px;
  top: 200px;
  right: 100px;
  z-index: 0;
  opacity: 0.5;

  &:before {
    background-image: url(#{$path-image}/service-v2/dots.png);
    top: -61%;
    right: 0;
    left: auto;
    width: 103px;
    z-index: 2;
  }
}

.l-service-v2__circle {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;

    &1 {
      width: 730px;
      height: 730px;

      background: #151515;
    }

    &2 {
      width: 548px;
      height: 548px;

      background: #1C1C1C;
    }

    &3 {
      width: 386px;
      height: 386px;

      background: #282828;
    }

    &4 {
      width: 220px;
      height: 220px;

      background: #313131;
    }
  }
}

.l-service-v2__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 540px;
  height: 310px;
  z-index: 1;

  .img {
    position: absolute;
    z-index: 1;

    &1 {
      left: 11%;
      bottom: 61%;
    }

    &2 {
      left: 23%;
      top: -14%;
    }

    &3 {
      right: 23%;
      top: -14%;
    }

    &4 {
      right: 7%;
      bottom: 64%;
    }

  }
}

.l-service-v2__eyecatch-full {
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    height: 540px;
    object-fit: cover;

    @include max-screen($breakpoint-mobile) {
      height: auto;
    }
  }
}

.l-service-v2__grid {
  margin-bottom: 110px;

  ul {

    li {
      width: 33.333333%;
      padding-right: 30px;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.l-service-v2__solutions {
  position: relative;
  padding-bottom: 3em;

  .l-container {
    overflow: hidden;
  }
}

.l-service__solutions-right {
  @extend .l-service__info-right;
  width: 48%;
  float: right;
  background-color: transparent;
  text-align: left;
  
  &:before {
    display: none;
  }

  .l-service__heading {
    line-height: 1.2;

    @include max-screen($breakpoint-mobile) {
      text-align: center;
    }
  }

  p {
    color: #D5D5D5;
    margin-bottom: 30px;
  }

  ul {
    margin-bottom: 3em;

    li {
      &:before {
        background: $base-font-color;
      }
    }
  }

  @include max-screen($breakpoint-mobile) {
    text-align: center;
    width: 100%;
  }
}

.l-service-v2__solutions-left {
  width: 48%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  .l-service-v2__solutions-left-img {
    width: 100%;
    height: 110%;
    overflow: hidden;

    @include max-screen($breakpoint-mobile) {
      height: 100%;
    }
  }

  img {
    width: 100%;
  }

  @include max-screen($breakpoint-mobile) {
    width: 100%;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
  }
}

.l-service-v2__blurb {
  background: #202020;
  padding: 133px 0 90px;

  &.pt-80 {
    padding-top: 80px;
  }

  @include max-screen($breakpoint-mobile) {
    padding: 50px 0;
  }
}

.l-service-v2__blurb-left, .l-service-v2__blurb-right {
  width: 48%;
  @include max-screen($breakpoint-mobile) {
    width: 100%;
  }
}

.l-service-v2__blurb-left {
  p {
    line-height: 1.9;

    color: #D5D5D5;
  }

  @include max-screen($breakpoint-mobile) {
    text-align: center;
    margin-bottom: 2em;
  }
}

.l-service-v2__blurb-right {
  text-align: center;

  img {
    margin:  0 auto;
    max-width: 100%;
  }
}

.l-service-v2__testimonies {
  @extend .l-service__testimonies;

  .l-container {
    &:before {
      display: none;
    }
  }
}

.l-service-v2__intro {
  .btn-wrap {
    padding-top: 30px;

    @include max-screen($breakpoint-mobile) {
      text-align: center;
    }
  }
}

.l-service-v2-3d-rendering__solutions {
  .l-service-v2__solutions-left {
    width: 40%;

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      position: relative;
      top: auto;
      left: auto;
      padding: 0 15px;

      .l-service-v2__solutions-left-img {
        height: 500px;
      }

    }
  }

  .l-service__solutions-right {
    width: 60%;

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      padding: 45px 0 0;
      text-align: center;

      ul {
        text-align: center;
        
        li {
          padding: 0;

          &:before, &:after {
            display: none;
          }
        }
      }
    }
  }
}

.l-service-v2__content {
  .btn-wrap {
    padding-top: 20px;
  }
}

.l-service-v2-illustration__content {
  max-width: 760px;
  margin: 0 auto 80px;
}

.l-service-v2 {
  .l-service__eyecatch {
    .l-container {
      z-index: 1;

      .l-service__eyecatch-left {
        width: 100%;
        padding: 100px 0;

        @include max-screen($breakpoint-mobile) {
          padding: 50px 0;
          padding-left: 0;
        }
      }
    }

    @include max-screen($breakpoint-mobile) {
      padding-bottom: 0;
    }
  }
}

.l-service-v2-service-photography {
  .l-service__details {
    .l-service__content {
      @include max-screen($breakpoint-mobile) {
        margin-bottom: 0;
      }
    }
  }

  .l-service-v2__solutions {
    .l-service__solutions-right {
      padding: 50px 0 0;
    }
  }
}

.l-service-3d-rendering {

  .l-service__heading--small {
    text-transform: uppercase;
    font-family: $font-gilroy-xbold;
  }

  .l-service-v2__intro {
    background-color: #1D1D1D;
    .l-service__intro-content {
      p {
        width: 50%;
        padding: 0 42px;
      }
    }

    .btn-wrap {
      text-align: center;
    }

    .l-service__intro-content {
      @media(max-width: 767px) {
        display: block;

        p {
          width: 100%;
        }
      }
    }
  }

  .l-service__details  {
    padding-top: 0;

    .l-service__heading {
      margin-bottom: 0;
    }

    @media(max-width: 1366px) {
      .detail-block {
        .detail-block__image {
          min-height: 400px;
        }
      }
    }

    @media(max-width: 1280px) {
      .detail-block {
        .detail-block__image {
          min-height: 425px;
        }
      }
    }

    @media(max-width: 1024px) {
      .detail-block {
        .detail-block__image {
          min-height: 450px;
        }
      }
    }
  }

  .section-what-we-do-tabs {
    padding: 4rem 0;

    .l-service__content,
    .l-service__heading--small {
      text-align: center;
    }

    .l-service__heading--small {
      margin-bottom: 25px;
    }

    .l-service__content {
      max-width: 778px;
      margin: 0 auto;
    }

    @media(max-width: 767px) {
      .image-wrap {
        display: none;
      }
    }
  }
}

.what-we-do-tabs {
  position: relative;
  margin-top: 80px;

  .image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 85%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .tab-listing {
    overflow: hidden;

    ul.tabs{
      float: right;
      width: 60%;
      margin: 0px;
      padding: 80px 0 80px 40px;
      list-style: none;

      @media(max-width: 767px) {
        float: none;
        width: 100%;
        padding-left: 0;
        padding-top: 0;
      }

    }
    ul.tabs li {
      color: #fff;
      display: block;
      padding: 10px 15px;
      cursor: pointer;
      font-family: $font-gilroy-xbold;
      font-size: 45px;
      position: relative;
      padding-left: 40px;
      margin-left: 0;
      -webkit-transition: all ease-in-out 0.2s;
      -moz-transition: all ease-in-out 0.2s;
      -ms-transition: all ease-in-out 0.2s;
      -o-transition: all ease-in-out 0.2s;
      transition: all ease-in-out 0.2s;

      span {
        position: absolute;
        top: 20px;
        left: 0;
        font-size: 22px;
        color: #4D4D4D;
      }

      @media(max-width: 767px) {
        font-size: 40px;
      }
    }

    ul.tabs li.current{
      color: #B22D2C;
      margin-left: -42px;

      span {
        color: #B22D2C;
      }

      @media(max-width: 767px) {
        margin-left: 0;
      }
    }

    .tab-content{
      display: none;
      padding: 50px;
      float: left;
      width: 100%;
      background-color: #232323;

      h3 {
        font-family: $font-gilroy-xbold;
        font-size: 26px;
      }
    }

    .tab-content.current{
      display: inherit;
    }
  }
}

.section-what-we-do {
  background-color: #191919;
  text-align: center;
  padding: 4rem 0;

  .l-service__heading {
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  .l-service__content {
    max-width: 895px;
    margin: 0 auto 30px;
  }

  .give-us-call {
    font-family: $font-gilroy-xbold;
    padding-top: 12px;

    span {
      color: #717171;
      text-transform: uppercase;
    }

    p {
      text-transform: uppercase;

      a {
        color: #b12b2a;
      }
    }
  }
}

.l-service-360-degree-product {
  @extend .l-service-3d-rendering;

  .l-service-v3__intro {
    padding: 80px 0;
  }

  .l-service-v3__intro-listing {
    margin-top: 0;

    li {
      width: 25%;
      padding: 0 32px;
      margin-bottom: 0;
    }
  }

  .l-service-v2__solutions-left .l-service-v2__solutions-left-img {
    height: 100%;
  }

  .l-service-v2__solutions {
    padding-bottom: 0;
    margin-bottom: 80px;
  }

  .l-service-v2__intro {
    h3 {
      padding-left: 42px;
      font-size: 26px;
      font-family: $font-gilroy-xbold;
      margin-bottom: 8px;
    }
  }
}

.l-service-information-architecture {
  .l-service-v2__solutions {
    margin-bottom: 10em;
    padding-bottom: 0;
  }
}

.l-service-v2-crm {
  .l-service__details {
    .l-service-v2__content {
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .l-section-1{

    .l-section-left{
      h1{
        font-size: 45px;
      }
    }
    .l-section-right.img-right{
      width: 45%;

      @media(max-width: 767px) {
        width: 100%;
      }
    }
  }
  .l-service__intro.l-services-border-bottom{
    background-color: #1a1a1a;
  }
}

.l-service-v2-illustration {
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }

  .section-video-list {
    .detail-block {
      background-color: transparent;
    }
  }
}

.l-service-v2-service-photography{
  .l-service__intro{
    .l-service__intro-content.l-service__intro-content-full{
      font-size: 20px;
      text-align: center;
    }
   
  }
  .l-service-v2__blurb{
    padding: 60px 0;
  }
}

.l-service-v2.l-service-3d-rendering{
  .banner-services{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(#000,.4);
    }
    .l-container{
      position: relative;
      z-index: 2;
    }
  }
  .section-what-we-do-tabs{
    .tab-listing ul.tabs li{
      float: none;
      width: 100%;
      text-align: left;
    }
  }
  .l-service__intro-content.u-flex{
    margin-right: -42px;
    margin-left: -42px;
  }

  &.l-service-virtual{
    .section-page-intro{
      h1{
        font-size: 45px;
      }
    }
    .l-service__details.section-vr{
      padding-top: 80px;
      padding-bottom: 80px;
      .detail-block{
        background-color: transparent;
      }
      .detail-block__text{
        background-color: transparent;
      }
    }
  }
}


.l-service.l-service-v2.l-service-v2-illustration{
  .l-service-v2-illustration__content{
    font-size: 19px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-top: 40px;
  }
  .video-bg{
    position: relative;
  }

  .l-service-v2-illustration__content{
    margin-top: -60px;
  }

  .l-service-v2__circle{
    left: auto;
    right: auto;
    bottom: -300px;
    z-index: 1;
  }
  .section-top-video-wrap{
    & > .l-container{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      max-width: 1200px;
    }
    .l-section-1 .l-section-left {
      width: 100%;
      padding-right: 0;
      margin: auto;
      text-align: center;
      padding-top: 0;
    }
  }
}