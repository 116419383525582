.u-show-pc {
  display: block;

  @include max-screen($breakpoint-mobile) {
    display: none;
  }
}

.u-show-sp {
  display: none;

  @include max-screen($breakpoint-mobile) {
    display: block;
  }
}

.u-notap {
  -webkit-tap-highlight-color: transparent;
}

.u-flex {
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}