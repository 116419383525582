.l-privacy {
    padding: 100px 0 50px;

    @include max-screen($breakpoint-mobile) {
        padding: 80px 0 30px;
    }
}

.l-privacy__top {
    position: relative;

    margin-bottom: 4em;

    @include max-screen($breakpoint-mobile) {
        margin-bottom: 3em;
    }

    &:after {
        position: absolute;
        top: -3%;
        right: 0;

        display: block;

        width: 90px;
        height: 100px;

        content: '';

        background-image: url(#{$path-image}/index/dots.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include max-screen($breakpoint-mobile) {
            right: 30px;

            width: 66px;
            height: 70px;
        }
    }

    h1 {
        font-family: $font-gilroy-xbold;
        font-size: 70px;

        text-align: center;

        color: #fff;

        @include max-screen($breakpoint-mobile) {
            font-size: 40px;
        }
    }
}

.l-privacy__content {
    width: 1024px;
    margin: 70px auto 90px;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        margin: 0;
    }

    p {
        line-height: 1.7;

        @include max-screen($breakpoint-mobile) {
            font-size: 15px;
        }

        &:not(:last-child) {
            margin-bottom: 40px;

            @include max-screen($breakpoint-mobile) {
                margin-bottom: 20px;
            }
        }
    }
}
