.l-our-works {
    padding: 100px 0 50px;
}

.l-our-works__top {
    position: relative;

    margin: 4em 0;

    @media(max-width: 1280px) {
        margin-bottom: 3em;
    }

    @media(max-width: 1024px) {
        margin-bottom: 2em;
    }

    &:after {
        position: absolute;
        top: -3%;
        right: 0;

        display: block;

        width: 90px;
        height: 100px;

        content: '';

        background-image: url(#{$path-image}/index/dots.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include max-screen($breakpoint-mobile) {
            right: 30px;

            width: 66px;
            height: 70px;
        }
    }

    h1 {
        font-family: $font-gilroy-xbold;
        font-size: 60px;

        text-align: center;

        color: #fff;

        @media(max-width: 1280px) {
            font-size: 50px;
        }

        @media(max-width: 1024px) {
            font-size: 40px;
        }

        @media(max-width: 767px) {
            font-size: 30px;
        }
    }
}

#cont_posters{
    a.poster-item{
        position: relative;

        .img-wrap{
            img.img-main{
                opacity: .65;
                z-index: 1;
                position: absolute;
            }
            img.gradient{
                z-index: 2;
                opacity: 0;
                position: absolute;
                transition: all linear .2s;
            }
        }
       
        .portfolio-content {
            z-index: 5;
            transform: translateY(-50%);
            top: 51%;

            img{
                display: block;
                max-width: 50%;
                max-height: 30%;
                margin: 15px auto;
                width: auto;
                transform: translateY(0%);
                margin-bottom: 20px;
            }
            .offset-top-md{
                transform: translateY(-20%);
            }
        }
        

        &:hover {
            .img-wrap{

                img.gradient{
                    opacity: .75;
                    background: linear-gradient(90deg, #fb8631 .01%, #97080c 100.02%);
                }
            }


            .portfolio-content {
                top: 50%;

                -webkit-transform: translateY(-50%);
                   -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                     -o-transform: translateY(-50%);
                        transform: translateY(-50%);

                span {
                    visibility: visible;

                    opacity: 1;
                }
            }
        }
    
        &.more-link{
            .img-wrap{
                img.img-main{
                    opacity: 1;
                }
            }
            .portfolio-content{
               img{
                    max-width: 160px;
                    max-height: max-content
               } 
            }
        }

        @media (max-width: 767px) {
            .img-wrap {

                img.gradient {
                    top: .5rem;
                    left: .5rem;
                    display: block;
                    width: calc( 100% - 1rem );
                    height: calc( 100% - 1rem );
               }
               

            }

            &:hover{
                
            }
        }
        
    }
}
.portfolio_thumbs {
    overflow: hidden;

    margin: 0 -15px 4em;

    @include max-screen($breakpoint-mobile) {
        margin-bottom: 3em;
    }
}

.portfolio_img {
    position: relative;

    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;

    width: 100%;
    height: 284px;

    -webkit-transition: all .35s ease-in-out 0s;
    -moz-transition: all .35s ease-in-out 0s;
    -ms-transition: all .35s ease-in-out 0s;
    -o-transition: all .35s ease-in-out 0s;
    transition: all .35s ease-in-out 0s;

    background-color: $base-background-color;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 120%;

    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;

    @media(max-width: 1024px) {
        height: 244px;
    }

    @include max-screen($breakpoint-mobile) {
        height: 140px;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: '';
        -webkit-transition: all .35s ease-in-out 0s;
        -moz-transition: all .35s ease-in-out 0s;
        -ms-transition: all .35s ease-in-out 0s;
        -o-transition: all .35s ease-in-out 0s;
        transition: all .35s ease-in-out 0s;

        opacity: .25;
        background: #000;
    }
}

.portfolio-content {
    position: absolute;
    z-index: 1;
    top: 55%;

    width: 100%;
    padding: 0 15px;

    -webkit-transition: all .35s ease-in-out 0s;
    -moz-transition: all .35s ease-in-out 0s;
    -ms-transition: all .35s ease-in-out 0s;
    -o-transition: all .35s ease-in-out 0s;
    transition: all .35s ease-in-out 0s;
    -webkit-transform: translateY(-45%);
    -moz-transform: translateY(-45%);
    -ms-transform: translateY(-45%);
    -o-transform: translateY(-45%);
    transform: translateY(-45%);
    text-align: center;
    text-align: center;

    @include max-screen($breakpoint-mobile) {
        top: 50%;
    }

    img {
        display: block;

        max-width: 200px;
        max-height: 100px;
        margin: 15px auto;

        @include max-screen($breakpoint-mobile) {
            max-width: 100px;
            max-height: 60px;
            margin-bottom: 0;
        }
    }

    span {
        font-family: $font-gilroy-xbold;
        font-size: 12px;

        visibility: hidden;

        width: 160px;
        margin: 0 auto;
        padding: 10px;

        -webkit-transition: all .35s ease-in-out 0s;
        -moz-transition: all .35s ease-in-out 0s;
        -ms-transition: all .35s ease-in-out 0s;
        -o-transition: all .35s ease-in-out 0s;
        transition: all .35s ease-in-out 0s;

        opacity: 0;
        border: 1px solid #fff;
        background-color: transparent;

        @include max-screen($breakpoint-mobile) {
            display: none;
        }
    }
}

.portfolio_item {
    position: relative;

    float: left;

    width: 33.333333%;
    padding: 10px;

    @include max-screen($breakpoint-mobile) {
        width: 50%;
    }

    &:hover {
        .portfolio_img {
            background-size: 130%;

            &:before {
                opacity: .75;
                background: linear-gradient(90deg, #fb8631 .01%, #97080c 100.02%);
            }
        }

        .portfolio-content {
            top: 50%;

            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);

            span {
                visibility: visible;

                opacity: 1;
            }
        }
    }
}

@media (max-width:$md) {
    .l-our-works__top,
    .l-contact__top {
        margin-top: .5rem;
        margin-bottom: 1rem;
    }

    .l-our-works#page-wrap .l-container {
        width: 100%;
        padding: 0 17px;
    }

    .portfolio_item {
        padding: 3px;
    }

    .portfolio_img {
        height: 255px;
        background-size: cover;
    }

    .l-our-works {
        padding-bottom: 0;
    }

    .portfolio_thumbs {
        margin-bottom: 0;
    }
}
