.l-service {
    position: relative;

    padding: 5.3rem 0 50px;

    .description {
        margin-bottom: 30px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    img {
        max-width: 100%;
    }

    @include max-screen($md){
        padding-bottom: 0;
    }
}

.l-service__heading {
    font-family: $font-gilroy-xbold;
    font-size: 38px;

    margin-bottom: 10px;

    @media(max-width: 1024px) {
        font-size: 35px;
    }

    @include max-screen($breakpoint-mobile){
        font-size: 30px;
    }
}

.l-service__content {
    p {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.l-service__eyecatch {
    position: relative;
    min-height: 630px;
    display: flex;
    align-items: center;

    &.l-service__eyecatch-middle {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;

        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;
    }

    @include max-screen($breakpoint-mobile) {
        min-height: 0;
        padding-bottom: 50px;
    }

}

.l-service__eyecatch-left {
    width: 60%;

    @include max-screen(1399px) {
        width: 70%;
    }

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    p {
        width: 80%;
        margin-bottom: 15px;

        @include max-screen($breakpoint-mobile) {
            width: 100%;
        }
    }

    ul {
        margin-bottom: 60px;
    }
}

.l-service__eyecatch-description {
    margin-bottom: 1.6rem;
}

.l-service__eyecatch-item {
    width: 45%;
    margin-bottom: 40px;

    &:last-child,
    &:nth-last-child(2) {
        margin-bottom: 0;
    }

    &:nth-child(3) {
        img {
            padding: 28px 30px;
        }
    }

    img {
        width: 35%;
        height: 110px;
        padding: 30px;

        background-color: #242429;

        object-position: bottom;
        object-fit: cover;
    }
    span {
        font-size: 20px;
        font-weight: bold;

        width: 60%;
    }
}

.l-service__eyecatch-right {
    position: absolute;
    top: 0;
    right: 0;

    width: 500px;
    height: 630px;

    background-size: cover;

    &:before {
        position: absolute;
        top: 0;
        left: -150px;

        display: block;

        width: 86px;
        height: 100px;

        content: '';

        background-image: url(#{$path-image}/index/dots.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }

    @include max-screen($breakpoint-mobile) {
        display: none;
    }
}

.l-service__intro {
    padding: 80px 0;

    @include max-screen($breakpoint-mobile) {
        padding: 50px 0;

        .l-service__heading {
            text-align: center;
        }
    }
}

.l-service__intro-content {
    align-items: initial;

    p {
        margin-bottom: 15px;

        color: $color-light-gray;

        @include max-screen($breakpoint-mobile) {
            width: 100%;
        }
    }

    &.l-service__intro-content-full {
        p {
            width: 100%;
        }
    }

    &.l-service__intro-content-two-column {
        .column-left, .column-right {
            width: 50%;

            p {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .column-left {
            padding-right: 30px;
        }

        .column-right {
            padding-left: 30px;
        }
    }

    @include max-screen($breakpoint-mobile) {
        text-align: center;
    }
}

.l-service__process {
    position: relative;

    padding: 4rem 0 4.2rem;

    background-color: #1a1a1a;

    ul {
        margin-top: 3rem;

        counter-reset: process;
    }
    li {
        width: 20%;

        counter-increment: process;
    }

    .column-2 {
        -ms-flex-align: start!important;
        align-items: flex-start!important;
        counter-reset: process;

        li {
            width: 45%;
            margin-bottom: 60px;

            @include max-screen($breakpoint-mobile) {
                width: 100%;
            }
        }
    }

    .column-3 {
        -ms-flex-align: start!important;
        align-items: flex-start!important;
        counter-reset: process;
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        li {
            width: 33.333%;
            margin-bottom: 2.3rem;
            padding: 0 1.5rem;

            @include max-screen($breakpoint-mobile) {
                width: 100%;
            }
        }
    }


    .btn-wrap {
        padding-top: 0;
        margin-top: 5px;
    }

    @include max-screen($breakpoint-mobile) {
        padding: 50px 0;
        text-align: center;
    }

}


.l-service__process-button {
    position: absolute;
    top: -30px;
    right: 31%;
}

.l-service__details {

    padding: 3rem 0;

    h2 {
        text-align: center;
    }
    ul {

        counter-reset: details;

        li {
            counter-increment: details;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            ul {
                margin: 0 0 0 18px;
            }

            @include max-screen($breakpoint-mobile) {
                margin-bottom: 0;
            }
        }

        @media(max-width: 1366px) {
            margin: 0;
        }
    }


    .l-service__content {
        text-align: center;
        margin-bottom: 3rem;
    }


    .detail-block__text {
        h2 {
            text-align: left;

            @include max-screen($breakpoint-mobile) {
                text-align: center;
            }
        }
    }

    .btn-wrap {
        text-align: center;
        padding-top: 60px;

        @include max-screen($breakpoint-mobile) {
            padding-top: 0;
        }
    }

    @include max-screen($breakpoint-mobile) {
        padding: 50px 0 0;
    }
}



.l-service__info {
    position: relative;
    padding: 3rem 0 01rem;

    //&:before {
    //    position: relative;
    //    top: -100px;
    //    left: 0;
    //
    //    display: block;
    //
    //    width: $site-size;
    //    height: 1px;
    //    margin: 0 auto;
    //    padding: 0 30px;
    //
    //    content: '';
    //
    //    background-color: #323232;
    //}

    &.l-service__info-no-line {
        &:before {
            display: none;
        }
    }


    &.l-service__info-l2{
        border-top: 1px solid #1d1d1d;
        padding-top: 3.5rem;
        padding-bottom: 4rem;
        margin-top: 2rem;


        @media (max-width:$md) {
            margin-top: 0;
            padding-top: 2rem;
            padding-bottom: 3rem;
        }
    }

    @include max-screen($breakpoint-mobile) {
        text-align: center;
    }
}

.l-service__info-left {
    width: 50%;

    p {
        color: $color-light-gray;

        &:not(:last-child) {
            margin-bottom: 14px;
        }
    }

    &.l-service__info-full {
        width: 100%;

        ul {
            margin-left: 15px;

            text-align: left;

            li {

                position: relative;

                padding-left: 25px;

                &:not(:last-child) {
                    margin-bottom: 14px;
                }
                &:last-child {
                    margin-bottom: 35px;
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: -15px;

                    width: 28px;
                    height: 28px;

                    content: '';

                    border-radius: 50%;
                    background: linear-gradient(90deg, $color-orange 0%, $color-primary 80%);
                }
                &:after {
                    position: absolute;
                    top: 6px;
                    left: -5px;

                    width: 8px;
                    height: 13px;

                    content: '';
                    transform: rotate(45deg);

                    border-right: 2px solid #000;
                    border-bottom: 2px solid #000;
                }
            }
        }
    }
}

.l-service__info-right {
    position: relative;

    width: 42%;
    padding: 60px 50px;

    text-align: center;

    background-color: #191919;

    &:before {
        position: absolute;
        top: -15px;
        left: -15px;

        display: block;

        width: 37px;
        height: 37px;

        content: '';

        background-image: url(#{$path-image}/service/caret.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }

    h3,h2  {
        font-family: $font-gilroy-xbold;
        font-size: 28px;

        margin-bottom: 25px;

        text-align: left;
    }
    ul {
        margin-left: 15px;

        text-align: left;

        li {
            font-size: 20px;
            font-weight: bold;

            position: relative;

            padding-left: 30px;

            &:not(:last-child) {
                margin-bottom: 25px;
            }
            &:last-child {
                margin-bottom: 35px;
            }

            &:before {
                position: absolute;
                top: 0;
                left: -15px;

                width: 28px;
                height: 28px;

                content: '';

                border-radius: 50%;
                background: linear-gradient(90deg, $color-orange 0%, $color-primary 80%);
            }
            &:after {
                position: absolute;
                top: 6px;
                left: -5px;

                width: 8px;
                height: 13px;

                content: '';
                transform: rotate(45deg);

                border-right: 2px solid #000;
                border-bottom: 2px solid #000;
            }
        }
    }
}

.l-service__other {
    padding: 80px 0 0;
    text-align: center;

    h2 {
        margin-bottom: 40px;
    }

    .l-index__services-list {
        padding-right: 0;
    }

    .l-index__services-item {
        text-align: left;
        padding-right: 6rem;

        @media(max-width: 1024px) {
            padding-right: 2rem;
        }

        @media(max-width: 640px) {
            width: 50%;
        }
    }
}

.section-testimonial{
    position: relative;
    padding: 4rem 0 4rem;
    text-align: center;
    background: #252525;

    h2 {
        font-family: $font-gilroy-xbold;
        font-size: 3.2rem;
        margin-bottom: 2.4rem;
    }
}

.testimonials {
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
    padding: 0 4rem;

    .slick-slide {
        min-height: 180px;
        display: flex!important;
        align-items: center;
    }

    &:after,
    &:before {
        content: '';
        width: 60px;
        height: 42px;
        display: block;
        position: absolute;

        background-repeat: no-repeat;
        background-size: contain;
    }

    &:before {
        left: -4rem;
        top: 0rem;
        background-image: url(#{$path-image}/works/quote-2.svg);
    }

    &:after {
        right: -4rem;
        bottom: 0;
        background-image: url(#{$path-image}/works/quote.svg);
    }



    li {
        width: 100%;
        img {
            height: 40px;
            margin: 0 auto 20px;

            transform: rotate(180deg);
        }
        h3 {
            font-family: $font-gilroy-xbold;
            font-size: 22px;
            margin-bottom: 12px;
        }
        p {
            font-size: 19px;
            line-height: 1.7;
            color: $color-light-gray;
        }
    }

    .slick-dots {
        display: flex;

        width: 100px;
        margin: 40px auto 0;

        list-style-type: none;

        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        li {
            width: 10px;
            height: 10px;

            cursor: pointer;
            transition: background-color $duration $easing,
            opacity $duration $easing;

            border-radius: 100%;
            background-color: #bdbdbd;

            &.slick-active {
                background: linear-gradient(90deg, $color-orange 0%, $color-primary 60%);
            }
        }
    }
}

.l-service__logos-list {
    margin: 80px auto 0;

    li {
        width: 18%;

        img {
            width: 120px;
            max-width: 100%;

            opacity: .5;

            filter: brightness(0) invert(1);
        }
    }
}

.l-services-border-bottom {
    border-bottom: 1px solid #282828;
}

.l-service-web-development {

    .l-service__eyecatch {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;

        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;
    }

    .l-service__process {
        .l-service__heading,
        .btn-wrap,
        .l-service__intro-content  {
            text-align: center;
        }
    }

    .l-service__details {
        @include max-screen($breakpoint-mobile) {
            padding-bottom: 50px;
        }
    }
}

.l-service-iphone-and-android-apps,
.l-service-media-buying {
    .l-service__details {
        padding-bottom: 0;

        ul {
            margin: 0;
            border-bottom: 1px solid #282828;
            padding-bottom: 80px;

            @include max-screen($breakpoint-mobile) {
                padding-bottom: 50px;
            }
        }

        .detail-block__image,
        .detail-block__text {
            width: 50%;

            @include max-screen($breakpoint-mobile) {
                width: 100%;
            }
        }
    }

    .l-service__info {
        &:before {
            display:  none;
        }
    }
}

.l-service-marketing {
    .l-service__details {

        ul {
            border-bottom: none;
            padding-bottom: 0;
        }

        @include max-screen($breakpoint-mobile) {
            padding-bottom: 20px;
        }
    }

    .detail-block__text .button {
        margin-top: 0;
    }
}

.l-services {
    @extend .l-service-web-development;

    //&:before {
    //    content: '';
    //    position: absolute;
    //    background-image: none;
    //    background-color: #2A2A2A;
    //    height: 500px;
    //    width: 500px;
    //    top: 0;
    //    right: 0;
    //}

    .l-service__eyecatch {
        .l-service__eyecatch-right {
            width: 781px;
            height: 565px;
            right: auto;
            left: 27rem;
            top: -10rem;
            position: relative;
            z-index: 1;

            &:before {
                display: none;
            }
        }

        .l-service__eyecatch-left {
            position: relative;
            width: 50%;

            &:before {
                position: absolute;
                top: -15px;
                left: -37px;
                display: block;

                width: 86px;
                height: 100px;

                content: '';

                background-image: url(#{$path-image}/index/dots.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.l-service-iphone-and-android-apps{
    .l-section-1{
        .l-section-left, .l-section-right {
            width: 50%;

            @media(max-width: 767px) {
                width: 100%;
            }
        }
    }

    .l-service__info {
        padding: 4rem 0;
    }

    @media(max-width: 1280px) {
        .l-service__details {
            padding-bottom: 0 !important;
        }
    }
}
.l-service-web-development {

    .l-service__intro {
        padding-top: 0;

        @media(max-width: 767px) {
            padding-top: 50px;
        }
    }

    .l-service__process {

        .column-3 {
            margin-top: 0;
            margin-bottom: 30px;
        }

        .l-service__intro-content-full {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5em;
        }
    }

    .l-service__details {
        .l-service__content {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.l-service {
    .banner-intro{
        .img-right{
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
}


.l-service__details-l2 {
    background-size: cover;
    background-position: center;
    min-height: 450px;
    display: flex;
    align-items: center;

    .detail-block {
        max-width: 700px;
        margin: auto;
        background: #000;
        padding: 2.5rem 3rem 3rem;

        .detail-block__text {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }
    }

    @media (max-width: $sm) {

        * {
            text-align: left;
        }

        .detail-block {
            padding: 2rem 2rem;
            text-align: left;
        }

    }

}


.detail-block__image {
    position: relative;

    figcaption {
        position: absolute;
        bottom: -34px;
        left: 0;
        right: 0;
        font-size: 90%;
        text-align: center;
        opacity: .6;
    }

}

.l-service-information-architecture {
    .l-service__details {
        .detail-block {
            @media(max-width: 767px) {
                display: block;

                .detail-block__image, .detail-block__text {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .detail-block__text {
                    padding-left: 0;
                    text-align: center;
                }

                .detail-block__image {
                    margin-bottom: 2em;
                }
            }
        }
    }

    .l-service__eyecatch {
        .l-service__eyecatch-left {
            @media(max-width: 767px) {
                margin: 2em auto 0;
            }
        }
    }
}