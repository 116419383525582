.button {
    position: relative;
    font-size: 16px;
    font-weight: bold;

    display: inline-block;

    padding: 15px 20px;
    min-width: 230px;

    text-align: center;
    letter-spacing: .04em;
    text-transform: uppercase;
    font-family: $font-gilroy-xbold;

    background-color: $color-primary;

    color: #d5d5d5;
    background-image: linear-gradient(to left, transparent, transparent 51%, $color-primary 51%, $color-secondary);
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all $duration $easing;

    &:hover {
        color: #fff;
        background-image: linear-gradient(to left, transparent, transparent 50%, #97080C 50%, #FB8631);

        background-position: 0 0;
        background-size: 200% 100%;

        &:before,
        &:after {
            right: -15px;
        }
    }

    @include max-screen($breakpoint-mobile) {
        font-size: 14px;

        padding: 15px 25px;
    }

    // MODIFIERS
    &.button--small {
        font-size: 15px;
        padding: 14px 20px;

        min-width: 0;
    }
    &.button--3d {
        transition: transform 150ms cubic-bezier(0, 0, .58, 1), background 150ms cubic-bezier(0, 0, .58, 1);

        border-radius: 50%;
        background-color: #fb8631;
        transform-style: preserve-3d;

        min-width: 0;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;

            content: '';
            transition: transform 150ms cubic-bezier(0, 0, .58, 1), box-shadow 150ms cubic-bezier(0, 0, .58, 1);
            transform: translate3d(0, 1em, -1em);

            border-radius: 20% 20% 50% 50%;
            background: #b75b19;
        }
        &:after {
            position: absolute;
            top: 8px;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 90%;

            content: '';
            transition: transform 150ms cubic-bezier(0, 0, .58, 1), box-shadow 150ms cubic-bezier(0, 0, .58, 1);
            transform: translate3d(0, .75em, -1em);

            border-radius: inherit;

            box-shadow: 0 9px 0 12px #751615, 0 0.625em 0 0 #b75b19;
        }
        &:hover {
            transform: translate(0, .75em);
            &:before {
                transform: translate3d(0, 1em, -1em);
            }
            &:after {
                transform: translate3d(0, 0, -1em);
            }
        }
    }
    &.button--3d-alt {
        border-radius: 40px;

        &:before,
        &:after {
            border-radius: 40px;
        }
    }

    &.button-gold{
        border: 2px solid #ffd24a;
        border-image-source: linear-gradient(90deg,#a2620e,#ffd24a,#a2620e);
        border-image-slice: 20;
        transition: all .3s ease;
        background-color: rgba(#fff,.1);
        &:hover, &:active, &:focus, &:active:focus {
            background-color: rgba(#fff,.3);
        }
    }
}
