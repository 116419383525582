.banner-services {
  background-position: center center;
  background-size: cover;
  text-align: center;

  .l-container {
    height: 700px;

    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;

    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
  }

  .banner-content {
    h1 {
      font-size: 45px;
      font-family: $font-gilroy-xbold;
    }

    .description {
      p {
        font-size: 20px;
      }
    }
  }
}

.banner-bg{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -5.3rem;
  padding-top: 5.3rem;

  .l-service-v2__eyecatch-right{
    right: auto;
    left: 100px;
  }

  .l-container{
    position: relative;
    z-index: 2;
  }

  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(#000,.4);
  }
}

.banner-bg-wrap{
    position: relative;
    background-position: bottom -172px center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background-color: rgba(#000,.4);
      z-index: 1;
  }

  &.offset-next-elem{
     &:before{
        content: '';
        bottom: 130px;
        top: auto;
    }
  }

  & > div, & > section{
    position: relative;
    z-index: 2;
  } 
}