.l-service-virtual {
  .banner-bg-wrap {
    background-position: center center;
  }

  .banner-bg-wrap.offset-next-elem:before {
    top: 0;
  }

  @media(max-width: 991px) {
    .section-service-features {
      padding-bottom: 2rem;
    }
  }
}