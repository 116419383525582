.detail-block {
    position: relative;
    display: flex;
    align-items: center;
    background: #1a1a1a;

    &__image,
    &__text {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        @media(max-width: 767px) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }


    &__image {
        min-height: 370px;
    }

    &__text {
        padding: 1rem;

        @media(max-width: 767px) {
            text-align: center;
            padding: 0 15px;
        }
    }


    &:nth-child(2n) {
        background-color: #000;
        flex-direction: row-reverse;
    }

    &-l2 {
        background-color: transparent;
    }

    &-l3 {
        background-color: transparent;
        align-items: flex-start;



        .detail-block__image {
            min-height: 0;
        }

        .detail-block__image {
            min-height: 370px;

            @media(max-width: 767px) {
                margin-bottom: 2em;
            }
        }

        .detail-block__text {
            padding: 0;
            padding-left: 3rem;

            @media(max-width: 767px) {
                padding-left: 0;
            }
        }

        &:not(:first-child){
            margin-bottom: 4rem;
        }

        &:nth-child(2n) {
            .detail-block__text {
                padding-left: 0;
                padding-right: 3rem;
            }
        }



    }

    @media(max-width: 767px) {
        display: block;
    }
}


babylon {
    width: 100%;
}

.detail-block__image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;



    @media(max-width: 767px) {
        width: 100%;
    }

    &.detail-block__image--caption {
        position: relative;

        figcaption {
            position: absolute;
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);

            font-size: .8em;
            font-style: italic;
        }
    }
}

.detail-block__text {
    position: relative;
    padding: 2rem 3rem;

    h3, h2 {
        font-family: $font-gilroy-xbold;
        font-size: 26px;

        margin-bottom: 1rem;
        text-align: left;

        @include max-screen($breakpoint-mobile) {
            margin-bottom: 10px;
            text-align: center;
        }
    }

    h2 {
        font-size: 38px;
    }

    p {
        color: $color-light-gray;

        @include max-screen($breakpoint-mobile) {
            font-size: 15px;
            line-height: 1.8;
        }
    }


    ul {
        margin: 0 0 0 18px;

        li {
            position: relative;

            padding-left: 30px;

            &:not(:last-child) {
                margin-bottom: .7rem;
            }
            &:last-child {
                margin-bottom: 35px;
            }

            &:before {
                position: absolute;
                top: 0;
                left: -15px;

                width: 28px;
                height: 28px;
                content: '';

                border-radius: 50%;
                background: linear-gradient(90deg, $color-orange 0%, $color-primary 80%);
            }
            &:after {
                position: absolute;
                top: 6px;
                left: -5px;

                width: 8px;
                height: 13px;

                content: '';
                transform: rotate(45deg);

                border-right: 2px solid #000;
                border-bottom: 2px solid #000;
            }

            @media(max-width: 767px) {
                &:before{
                    width: 22px;
                    height: 22px;
                }
                &:after{
                    top: 4px;
                    left: -7px;
                    width: 6px;
                    height: 11px;
                }
            }
        }
    }

    .button {
        margin-top: 1.5em;
    }

    @media(max-width: 767px) {
        padding: 40px 15px;
        width: 100%;

        ul {
            li {
                padding-left: 18px;
            }

        }
    }
}

.detail-block {
    &.detail-block--reverse {
        .detail-block__text {
            padding-left: 0;
            padding-right: 80px;

            @media(max-width: 767px) {
                padding: 40px 0;
            }
        }
    }
}

