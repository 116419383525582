@font-face {
  font-family: 'Gilroy-Regular';
  src: url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.woff2") format("woff2"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.woff") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.woff2") format("woff2"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.woff") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.woff") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.woff2") format("woff2"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.woff") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.woff2") format("woff2"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.woff") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.woff") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: auto;
  font-size: 17px;
  font-family: "Gilroy-Regular", sans-serif;
  color: #d8d8d8;
  background-color: #000;
  line-height: 1.7;
}

@media screen and (min-width: 1281px) {
  body {
    min-width: 1260px;
  }
}

html,
html *,
body,
body * {
  outline: none;
}

html.is-locked,
body.is-locked {
  overflow: hidden;
}

html.is-home,
body.is-home {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

html.is-home::-webkit-scrollbar,
body.is-home::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row .col {
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row .col-6 {
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 25%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row .col-3 {
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

/*! sanitize.css | CC0 Public Domain | github.com/jonathantneal/sanitize.css */
/*
 * Normalization
 */
:root {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  text-size-adjust: 100%;
}

audio:not([controls]) {
  display: none;
}

details {
  display: block;
}

input[type="number"] {
  width: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

main {
  display: block;
}

summary {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

small {
  font-size: 75%;
}

template {
  display: none;
}

textarea {
  overflow: auto;
}

[hidden] {
  display: none;
}

[unselectable] {
  user-select: none;
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

/*
 * Opinionated defaults
 */
*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
  cursor: default;
  font: 16px / 1.5 sans-serif;
  text-rendering: optimizeLegibility;
}

html {
  background-color: #FFFFFF;
}

a {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  background-color: transparent;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

ol,
ul {
  list-style: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: vertical;
}

::selection {
  background-color: #B3D4FC;
  text-shadow: none;
}

@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

.u-show-pc {
  display: block;
}

@media screen and (max-width: 767px) {
  .u-show-pc {
    display: none;
  }
}

.u-show-sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .u-show-sp {
    display: block;
  }
}

.u-notap {
  -webkit-tap-highlight-color: transparent;
}

.u-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots li button {
  font-size: 0;
}

.splitting.cells img {
  display: block;
  width: 100%;
}

.splitting.cells {
  visibility: hidden;
  /*overflow: hidden;*/
  background-size: cover;
}

.splitting .cell-grid {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100%;
  background: inherit;
  grid-template: repeat(var(--row-total), 1fr)/repeat(var(--col-total), 1fr);
}

.splitting .cell {
  position: relative;
  display: block;
  overflow: hidden;
  opacity: 1;
  background: inherit;
}

.splitting .cell-inner {
  position: absolute;
  top: calc(-100% * var(--row-index));
  /* Position properly */
  left: calc(-100% * var(--col-index));
  visibility: visible;
  /* Size to fit the whole container size */
  width: calc(100% * var(--col-total));
  height: calc(100% * var(--row-total));
  background: inherit;
}

@font-face {
  font-family: 'icomoon';
  src: url("/wp-content/themes/lightmedia/assets/fonts/icons/icomoon.eot?uv3uaf");
  src: url("/wp-content/themes/lightmedia/assets/fonts/icons/icomoon.eot?uv3uaf#iefix") format("embedded-opentype"), url("/wp-content/themes/lightmedia/assets/fonts/icons/icomoon.ttf?uv3uaf") format("truetype"), url("/wp-content/themes/lightmedia/assets/fonts/icons/icomoon.woff?uv3uaf") format("woff"), url("/wp-content/themes/lightmedia/assets/fonts/icons/icomoon.svg?uv3uaf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\ea90";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-linkedin:before {
  content: "\eaca";
}

.header__logo {
  position: fixed;
  z-index: 2;
  left: 6.5rem;
  top: 1rem;
  width: 170px;
  display: block;
  transition: all .3s;
}

.header__logo img {
  width: 100%;
}

@media (max-width: 767px) {
  .header__logo {
    left: 4rem;
    top: 0.8rem;
    width: 136px;
  }
}

.header {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
}

.header.is-open {
  z-index: 99;
}

@media screen and (max-width: 767px) {
  .header {
    width: auto;
  }
}

.header__menu {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 90px;
  min-height: 86px;
  transition: height .2s;
  align-items: start;
  justify-content: center;
}

.header__menu-icon {
  position: relative;
  display: block;
  width: 20px;
  margin-top: 1.5rem;
  border-color: #fff;
}

.header__menu-icon:hover span {
  background-color: #b12b2a;
}

.header__menu-icon span {
  position: relative;
  right: -2px;
  display: block;
  width: 20px;
  height: 3px;
  margin: 5px 0;
  transition: all 0.35s ease-in-out;
  background-color: #fff;
}

.header__menu-icon span:nth-child(2) {
  left: -2px;
}

.is-open .header__menu {
  z-index: 9999 !important;
  background: none;
}

.is-scrolled .header__menu {
  height: 86px;
}

.is-locked .header__menu,
.is-loading .header__menu {
  z-index: 0;
}

@media (max-width: 1250px) {
  .header__menu {
    height: 86px;
  }
}

.button-menu {
  position: relative;
  width: 60px;
  height: 60px;
  margin-top: 10px;
  cursor: pointer;
  transition: all .2s;
}

.button-menu,
.button-menu * {
  transition: all .25s ease-in-out;
}

.button-menu i {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 3px;
  transform: translate(-50%, -50%);
  background: #fff00;
}

.button-menu i:after, .button-menu i:before {
  position: absolute;
  width: 24px;
  height: 3px;
  content: '';
  transition: all .25s ease-in-out;
  background: #fff;
}

.button-menu i:before {
  transform: rotate(0deg) translate(0px, -4px);
}

.button-menu i:after {
  transform: rotate(0deg) translate(0px, 4px);
}

.button-menu:hover i:before {
  transform: rotate(0deg) translate(-4px, -4px);
}

.button-menu:hover i:after {
  transform: rotate(0deg) translate(4px, 4px);
}

.is-open .button-menu i:before {
  transform: rotate(-45deg) translate(0px, 0px);
}

.is-open .button-menu i:after {
  transform: rotate(45deg) translate(0px, 0px);
}

.is-open .button-menu:hover i:before {
  transform: rotate(0deg) translate(0px, 0px);
}

.is-open .button-menu:hover i:after {
  transform: rotate(0deg) translate(0px, 0px);
}

.home .button-menu {
  margin-top: 20px;
}

.header__menu-next {
  font-weight: bold;
  position: fixed;
  z-index: 4;
  bottom: 62px;
  left: 8px;
  display: flex;
  transition: all .1s;
  transform: rotate(-90deg) !important;
  text-align: right;
  letter-spacing: .05em;
  opacity: .5;
  color: #fff;
  align-items: center;
}

.header__menu-next i {
  position: relative;
  left: 0;
  display: block;
  width: 30px;
  height: 8px;
  margin-right: 8px;
  transition: left 0.35s ease-in-out;
}

.header__menu-next i:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 26px;
  height: 2px;
  content: '';
  transition: left 0.35s ease-in-out;
  transform: translateY(-50%);
  background-color: #fff;
}

.header__menu-next i:after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 8px;
  height: 8px;
  content: '';
  transition: left 0.35s ease-in-out;
  transform: translateY(-50%) rotate(-45deg);
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}

.header__menu-next:hover {
  opacity: 1;
}

.header__menu-next:hover i {
  left: -8px;
}

.fp-viewing-hero header .header__menu-next {
  bottom: 62px;
}

.header-r {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  padding: 2.5rem 3rem;
  transition: all .3s;
}

@media (max-width: 767px) {
  .header-r {
    padding: 1.7rem 1.5rem;
  }
}

.cta-link-phone {
  font-family: "Gilroy-ExtraBold";
  font-size: 16px;
  color: #fff;
}

.cta-link-phone svg {
  width: 18px;
  height: auto;
  margin-right: 8px;
}

.header__services--links {
  font-weight: 800;
  position: fixed;
  top: 36px;
  left: 21em;
  padding: 11px 35px;
  color: #a6a6a6;
  border-left: 1px solid #343434;
}

.header__services--links .icon {
  position: relative;
  top: -2px;
  display: inline-block;
  margin-right: 5px;
}

.header-second {
  overflow: hidden;
  padding: 12px 0;
  background-color: #202020;
}

.header-second .l-container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

@media (max-width: 767px) {
  .header-second .l-container {
    display: block !important;
    text-align: center;
  }
}

.header-second .menu2 li {
  display: inline-block;
  vertical-align: middle;
}

.header-second .menu2 li a {
  font-family: "Gilroy-ExtraBold";
  line-height: 1;
  display: block;
  padding: 0 20px;
  transition: all .3s;
  color: #fff;
  border-right: 1px solid #626262;
}

.header-second .menu2 li a:hover {
  color: #dd6822;
}

.header-second .menu2 li.active a {
  color: #dd6822;
}

.header-second .menu2 li:first-child a {
  padding-left: 0;
}

.header-second .menu2 li:last-child a {
  border-right: 0;
}

@media (max-width: 767px) {
  .header-second .menu2 li {
    display: block;
    padding: 0 0 15px;
  }
  .header-second .menu2 li:last-child {
    padding-bottom: 30px;
  }
  .header-second .menu2 li a {
    border: none;
    text-align: center;
    padding: 0;
  }
}

.header-second .menu2.menu-center {
  margin: 0 auto;
  text-align: center;
}

.header-second .menu2.menu-center li a {
  font-size: 17px;
}

.header-second .button {
  margin-left: auto;
}

@media (max-width: 767px) {
  .header-second {
    padding: 30px 0;
  }
}

.header__menu2 {
  height: auto;
  background: transparent;
}

.header__menu2 .header__menu-trigger span {
  background-color: #fff;
}

.header-top {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  background-color: #000;
}

.header-top .header-left {
  float: left;
  padding-left: 90px;
}

.header-top .header-left .header__logo,
.header-top .header-left .header__services--links {
  display: inline-block;
  vertical-align: middle;
}

.header-top .header-left .header__logo {
  position: relative;
  top: auto;
  left: auto;
}

.header-top .header-left .header__services--links {
  position: relative;
  top: auto;
  left: auto;
  margin-left: 35px;
}

.header-top .header-right {
  float: right;
  padding: 28px;
}

.header-top .header-right .cta-link-phone:after {
  display: inline-block;
  width: 27px;
  height: 27px;
  margin: -5px 0 0 20px;
  content: '';
  vertical-align: middle;
  background-image: url("../images/header-circle.png");
  background-position: center center;
  background-size: contain;
}

.is-scrolled .header__logo {
  position: fixed;
  z-index: 2;
  top: .5rem;
  left: 7rem;
  width: 155px;
}

.is-scrolled .header__menu-trigger span {
  height: 2px;
}

.is-scrolled .header-r {
  padding: 1.8rem 2rem;
}

body.page .header {
  right: 0;
  height: 84px;
  min-height: 86px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: #000;
}

body.page .header__menu {
  width: 85px;
  transition: height .4s ease;
  transition-delay: .2s;
  background-color: #F79E17;
}

body.page .header__logo {
  top: .5rem;
  width: 155px;
}

body.page .header-r {
  padding: 1.8rem 2rem;
}

body.page .header-r a {
  display: inline-block;
}

.l-mega-menu {
  font-family: 'Gilroy-ExtraBold';
  font-size: 17px;
  position: fixed;
  z-index: 999;
  top: 1.7rem;
  left: 19rem;
  display: flex;
  padding-left: 2rem;
  color: #e4e4e4;
  align-items: center;
}

.l-mega-menu:before {
  position: absolute;
  left: 0;
  width: 1px;
  height: 35px;
  content: '';
  background: #3c3c3c;
}

.l-mega-menu-dropdown {
  position: fixed;
  z-index: -3;
  top: 8.4rem;
  right: 0;
  left: 0;
  padding: 2rem 0;
  transition: top .3s, opacity .4s;
  opacity: 0;
  border-top: 1px solid transparent;
  background: #000;
}

.l-mega-menu-dropdown h4 {
  font-family: 'Gilroy-ExtraBold';
  font-size: 30px;
  margin-bottom: 1.5rem;
}

.l-mega-menu-dropdown .l-index__services-list {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.l-mega-menu-dropdown .l-index__services-item {
  width: 25%;
}

.l-mega-menu-dropdown .l-index__services-item .service-link span {
  min-height: 65px;
}

.l-mega-menu-icon {
  display: block;
  margin-right: .6rem;
  padding: 0 10px;
}

.l-mega-menu-icon i:after,
.l-mega-menu-icon i:before,
.l-mega-menu-icon i {
  position: relative;
  display: block;
  width: 4px;
  height: 4px;
  content: '';
  background: #e4e4e4;
}

.l-mega-menu-icon i {
  margin: 3px auto;
}

.l-mega-menu-icon i:before, .l-mega-menu-icon i:after {
  position: absolute;
}

.l-mega-menu-icon i:before {
  left: 8px;
}

.l-mega-menu-icon i:after {
  right: 8px;
}

.l-mega-menu-is-open .l-mega-menu {
  color: #b12b2a;
}

.l-mega-menu-is-open .l-mega-menu-icon i,
.l-mega-menu-is-open .l-mega-menu-icon i:after,
.l-mega-menu-is-open .l-mega-menu-icon i:before {
  background: #b12b2a;
}

.l-mega-menu-is-open .l-mega-menu-dropdown {
  z-index: 9;
  top: 5.4rem;
  opacity: 1;
  border-color: #2d2d2d;
}

.menu {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100vh;
  min-height: 762px;
  transition: all 0.35s ease-in-out;
  background-image: linear-gradient(to bottom, #f79e17 30%, #b12b2a);
  align-items: initial;
}

.menu.is-shown .menu__nav li {
  transform: translateY(0);
  opacity: 1;
}

.menu.is-shown .menu__nav li:first-child {
  transition-delay: .12s;
}

.menu.is-shown .menu__nav li:nth-child(2) {
  transition-delay: .2s;
}

.menu.is-shown .menu__nav li:nth-child(3) {
  transition-delay: .25s;
}

.menu.is-shown .menu__nav li:last-child {
  transition-delay: .5s;
}

.menu__left {
  position: relative;
  width: 65%;
  padding: 25px 98px;
}

.menu__left .logo {
  margin: auto;
  display: inline-block;
}

.menu__logo {
  width: 200px;
}

.menu__nav {
  margin-top: 22vh;
}

.menu__nav li a {
  font-family: "Gilroy-ExtraBold";
  font-size: 3.2rem;
  transition: all 0.35s ease-in-out;
  color: #fff;
  display: block;
  line-height: 1.4;
}

.menu__nav li a:hover {
  opacity: .8;
  transform: translateX(20px);
}

.menu__sns {
  position: absolute;
  bottom: 70px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.menu__sns li {
  margin-right: 2rem;
}

.menu__sns li:nth-child(3) .menu__sns-icon {
  line-height: 2.2;
}

.menu__sns a {
  color: #fff;
}

.menu__sns-icon {
  line-height: 2.4;
  width: 54px;
  height: 54px;
  text-align: center;
  background-image: url(/wp-content/themes/lightmedia/assets/images/sns-border.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu__sns-icon .icon-linkedin {
  position: relative;
  top: -2px;
}

.menu__sns-description {
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  margin-left: 12px;
}

.menu__sns-description strong {
  display: block;
  font-size: 17px;
  font-family: "Gilroy-ExtraBold";
}

.menu__right {
  position: relative;
  width: 35%;
  height: 100%;
  padding: 50px 50px 70px;
  background-color: #000;
  align-items: flex-end;
}

.menu__right:before {
  position: absolute;
  top: 100px;
  left: 50px;
  display: block;
  width: 85px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.menu__locations {
  width: 65%;
}

.menu__locations h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 30px;
  display: block;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu__locations li {
  margin-bottom: 30px;
}

.menu__locations li:last-child {
  margin-bottom: 0;
}

.menu__locations-time {
  width: 40%;
}

.menu__locations-details {
  width: 55%;
}

.menu__locations-details h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 21px;
  line-height: 1;
  display: block;
  margin-bottom: 0;
  color: #fff;
}

.menu__locations-details a {
  color: #787878;
}

/* --------------------------------

Main Components

-------------------------------- */
#page-wrap {
  position: relative;
  z-index: 2;
  /* fix bug on iOS */
  -webkit-overflow-scrolling: touch;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.7s;
  -moz-transition: -moz-transform 0.7s;
  transition: transform 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
  -moz-transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
  transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
  background-color: #000;
}

.navigation-is-open #page-wrap {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.cd-nav-trigger {
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}

.cd-nav-trigger .cd-nav-icon {
  /* icon created in CSS */
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 24px;
  height: 3px;
  background-color: #ffffff;
}

.cd-nav-trigger .cd-nav-icon::before, .cd-nav-trigger .cd-nav-icon:after {
  /* upper and lower lines of the menu icon */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.5s, width 0.5s, top 0.3s;
  -moz-transition: -moz-transform 0.5s, width 0.5s, top 0.3s;
  transition: transform 0.5s, width 0.5s, top 0.3s;
}

.cd-nav-trigger .cd-nav-icon::before {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  transform: translateY(-6px);
}

.cd-nav-trigger .cd-nav-icon::after {
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: translateY(6px);
  -moz-transform: translateY(6px);
  -ms-transform: translateY(6px);
  -o-transform: translateY(6px);
  transform: translateY(6px);
}

.no-touch .cd-nav-trigger:hover .cd-nav-icon::after {
  top: 2px;
}

.no-touch .cd-nav-trigger:hover .cd-nav-icon::before {
  top: -2px;
}

.cd-nav-trigger svg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.cd-nav-trigger circle {
  /* circle border animation */
  -webkit-transition: stroke-dashoffset 0.4s 0s;
  -moz-transition: stroke-dashoffset 0.4s 0s;
  transition: stroke-dashoffset 0.4s 0s;
}

.navigation-is-open .cd-nav-trigger {
  /* rotate trigger when navigation becomes visible */
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  /* animate arrow --> from hamburger to arrow */
  width: 50%;
  -webkit-transition: -webkit-transform 0.5s, width 0.5s;
  -moz-transition: -moz-transform 0.5s, width 0.5s;
  transition: transform 0.5s, width 0.5s;
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after, .no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
  top: 0;
}

.navigation-is-open .cd-nav-trigger circle {
  stroke-dashoffset: 0;
  -webkit-transition: stroke-dashoffset 0.4s 0.3s;
  -moz-transition: stroke-dashoffset 0.4s 0.3s;
  transition: stroke-dashoffset 0.4s 0.3s;
}

.cd-nav {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.7s;
  -moz-transition: visibility 0s 0.7s;
  transition: visibility 0s 0.7s;
}

.cd-nav .cd-navigation-wrapper {
  /* all navigation content */
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: -webkit-transform 0.7s;
  -moz-transition: -moz-transform 0.7s;
  transition: transform 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
  -moz-transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
  transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
}

.navigation-is-open .cd-nav {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  -moz-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
}

.navigation-is-open .cd-nav .cd-navigation-wrapper {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
  -moz-transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
  transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
}

.cd-nav .cd-primary-nav {
  margin-top: 60px;
}

.cd-nav .cd-primary-nav li {
  margin: 1.6em 0;
}

.cd-nav .cd-primary-nav a {
  font-family: "Merriweather", serif;
  font-size: 2.4rem;
  color: rgba(255, 255, 255, 0.3);
  display: inline-block;
}

.cd-nav .cd-primary-nav a.selected {
  color: #ffffff;
}

.no-touch .cd-nav .cd-primary-nav a:hover {
  color: #ffffff;
}

.cd-nav .cd-contact-info {
  margin-top: 80px;
}

.cd-nav .cd-contact-info li {
  font-family: "Merriweather", serif;
  margin-bottom: 1.5em;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.3);
}

.cd-nav .cd-contact-info a {
  color: #ffffff;
}

.cd-nav .cd-contact-info span {
  display: block;
}

.cd-nav .cd-contact-info li, .cd-nav .cd-contact-info a, .cd-nav .cd-contact-info span {
  font-size: 1.6rem;
}

@media only screen and (min-width: 1170px) {
  .cd-nav .cd-navigation-wrapper::after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-nav .cd-half-block {
    width: 50%;
    float: left;
  }
  .cd-nav .cd-primary-nav {
    margin-top: 0;
  }
  .cd-nav h2 {
    font-size: 1.5rem;
    margin-bottom: 5.6em;
  }
  .cd-nav .cd-primary-nav li {
    margin: 2em 0;
  }
  .cd-nav .cd-primary-nav a {
    font-size: 4.4rem;
  }
  .cd-nav .cd-contact-info {
    margin-top: 120px;
    text-align: right;
  }
  .cd-nav .cd-contact-info li {
    margin-bottom: 2.4em;
  }
  .cd-nav .cd-contact-info li, .cd-nav .cd-contact-info a, .cd-nav .cd-contact-info span {
    font-size: 2rem;
  }
}

.no-js #page-wrap {
  height: auto;
  overflow: visible;
}

.no-js .cd-nav {
  position: static;
  visibility: visible;
}

.no-js .cd-nav .cd-navigation-wrapper {
  height: auto;
  overflow: visible;
  padding: 100px 5%;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.home .cd-nav-trigger {
  margin: 6px 2px;
}

@media (max-width: 1024px) {
  .menu__right {
    display: none;
  }
  .menu__left {
    width: 100%;
    padding: 1rem 3rem;
  }
  .menu__sns li {
    margin-right: 1.5rem;
  }
}

@media (max-width: 767px) {
  .header {
    height: 80px;
    min-height: 80px;
  }
  .menu__nav {
    margin-top: 7rem;
  }
  .menu__nav li a {
    font-size: 3rem;
  }
  .menu__sns {
    display: block !important;
    position: static;
    margin-top: 2rem;
    width: 100%;
  }
  .menu__sns li {
    padding: .5rem 0;
    display: block;
  }
  .menu__sns li a {
    justify-content: inherit;
  }
  .cd-nav-trigger svg {
    width: 48px;
    height: 48px;
  }
  .header__menu {
    min-width: 58px;
    min-height: 0;
    height: 100%;
    width: 58px;
    background-color: #F79E17;
    transition: height .4s ease;
    transition-delay: .2s;
  }
  .header__logo {
    left: 4.5rem;
  }
  .header {
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
  .home .cd-nav-trigger {
    margin: 0;
  }
}

.section-bg-text {
  position: fixed;
  z-index: -1;
  right: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  display: block;
  opacity: .8;
}

.section-bg-text svg {
  width: 75%;
}

.marquee {
  animation: scroll 15s linear infinite;
}

@keyframes scroll {
  0% {
    left: 1900px;
  }
  100% {
    left: -1900px;
  }
}

.hero__text {
  position: fixed;
  text-align: left;
  left: 15rem;
  top: 35vh;
}

.hero__text h1 {
  font-family: "Gilroy-ExtraBold";
  font-size: 86px;
  line-height: 1.2;
  width: 550px;
  margin-bottom: 40px;
}

.hero__text h1 span {
  position: relative;
  display: block;
  transition: all 0.35s ease-in-out;
  transform: translateX(-50px);
  opacity: 0;
}

.hero__text h1 span:nth-child(2) {
  transition-delay: .15s;
}

.hero__text h1 span:nth-child(3) {
  transition-delay: .5s;
}

.hero__text h1.is-shown span {
  transform: translateX(0);
  opacity: 1;
}

.hero__text a {
  font-weight: bold;
  display: inline-block;
  transition: all 0.35s ease-in-out;
  transform: translateX(-50px);
  opacity: 0;
  color: #fff;
}

.hero__text a.is-shown {
  transform: translateX(0);
  opacity: 1;
}

.hero__bottom {
  position: absolute;
  right: 200px;
  bottom: 0;
}

.hero__bottom:after {
  position: absolute;
  right: -80px;
  bottom: 0;
  display: block;
  width: 90px;
  height: 90px;
  content: '';
  background-color: #b12b2a;
}

.hero__bottom svg {
  position: relative;
  width: 750px;
}

.hero__bottom svg path {
  fill: #464646;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1155px;
  margin-right: auto;
  margin-left: auto;
}

.is-nav-collapsed .section-home-1:before {
  width: 100vw;
}

.banner-services {
  background-position: center center;
  background-size: cover;
  text-align: center;
}

.banner-services .l-container {
  height: 700px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.banner-services .banner-content h1 {
  font-size: 45px;
  font-family: "Gilroy-ExtraBold";
}

.banner-services .banner-content .description p {
  font-size: 20px;
}

.banner-bg {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -5.3rem;
  padding-top: 5.3rem;
}

.banner-bg .l-service-v2__eyecatch-right {
  right: auto;
  left: 100px;
}

.banner-bg .l-container {
  position: relative;
  z-index: 2;
}

.banner-bg:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.banner-bg-wrap {
  position: relative;
  background-position: bottom -172px center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-bg-wrap:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.banner-bg-wrap.offset-next-elem:before {
  content: '';
  bottom: 130px;
  top: auto;
}

.banner-bg-wrap > div, .banner-bg-wrap > section {
  position: relative;
  z-index: 2;
}

.img-gray {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
  transition: .2s all linear;
}

.img-gray:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.text-gold {
  background: linear-gradient(90deg, #ffd24a 35%, #b56c39 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.circle-dial {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  width: 500px;
  height: 500px;
  margin: auto;
}

.circle-dial .slider-circle {
  position: absolute;
  top: -40px;
  left: -40px;
  overflow: visible;
  width: 537px;
  height: 537px;
  cursor: grab;
  fill: transparent;
}

.circle-dial .draggable-circle {
  position: relative;
  cursor: grab;
  animation: wiggle 1.5s infinite linear;
  fill: #b12b2a;
}

.circle-dial .draggable-circle-bg {
  cursor: grab;
  animation: blink 1.5s infinite linear, wiggle 1.5s infinite linear;
}

.circle-dial__bg {
  position: absolute;
  top: -40px;
  left: -40px;
  width: 537px;
  height: 537px;
  transition: background-image 0.5s ease-in-out;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.circle-dial__circles {
  position: absolute;
  top: 0;
  overflow: visible;
  fill: transparent;
}

.circle-dial__color {
  transform: rotate(-90deg);
  transform-origin: center;
  stroke-width: 1.5;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  49.99% {
    opacity: 0;
    r: 6.25;
  }
  50% {
    opacity: 1;
    r: 2.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wiggle {
  0% {
    transform: translate(5px, 0);
  }
  50% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(5px, 0);
  }
}

.cursor__outline {
  position: absolute;
  z-index: 999999;
  top: 50%;
  left: 50%;
  mix-blend-mode: difference;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .cursor__outline {
    display: none;
  }
}

.cursor__outline {
  width: 40px;
  height: 40px;
  border: 2px solid #fbdc2e;
}

.link {
  position: relative;
  transition: color 0.35s ease-in-out;
}

.link:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  content: '';
  background-color: #b12b2a;
}

.link:hover {
  color: #b12b2a;
}

.link:hover:after {
  animation: slide-right .6s linear;
}

@keyframes slide-right {
  0%,
  to {
    left: 0;
    width: 100%;
  }
  25% {
    left: 50%;
    width: 50%;
  }
  50% {
    left: 100%;
    width: 0;
  }
  51% {
    left: 0;
  }
  75% {
    width: 50%;
  }
}

.button {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 15px 20px;
  min-width: 230px;
  text-align: center;
  letter-spacing: .04em;
  text-transform: uppercase;
  font-family: "Gilroy-ExtraBold";
  background-color: #b12b2a;
  color: #d5d5d5;
  background-image: linear-gradient(to left, transparent, transparent 51%, #b12b2a 51%, #fbdc2e);
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.35s ease-in-out;
}

.button:hover {
  color: #fff;
  background-image: linear-gradient(to left, transparent, transparent 50%, #97080C 50%, #FB8631);
  background-position: 0 0;
  background-size: 200% 100%;
}

.button:hover:before, .button:hover:after {
  right: -15px;
}

@media screen and (max-width: 767px) {
  .button {
    font-size: 14px;
    padding: 15px 25px;
  }
}

.button.button--small {
  font-size: 15px;
  padding: 14px 20px;
  min-width: 0;
}

.button.button--3d {
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
  border-radius: 50%;
  background-color: #fb8631;
  transform-style: preserve-3d;
  min-width: 0;
}

.button.button--3d::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  transform: translate3d(0, 1em, -1em);
  border-radius: 20% 20% 50% 50%;
  background: #b75b19;
}

.button.button--3d:after {
  position: absolute;
  top: 8px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90%;
  content: '';
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  transform: translate3d(0, 0.75em, -1em);
  border-radius: inherit;
  box-shadow: 0 9px 0 12px #751615, 0 0.625em 0 0 #b75b19;
}

.button.button--3d:hover {
  transform: translate(0, 0.75em);
}

.button.button--3d:hover:before {
  transform: translate3d(0, 1em, -1em);
}

.button.button--3d:hover:after {
  transform: translate3d(0, 0, -1em);
}

.button.button--3d-alt {
  border-radius: 40px;
}

.button.button--3d-alt:before, .button.button--3d-alt:after {
  border-radius: 40px;
}

.button.button-gold {
  border: 2px solid #ffd24a;
  border-image-source: linear-gradient(90deg, #a2620e, #ffd24a, #a2620e);
  border-image-slice: 20;
  transition: all .3s ease;
  background-color: rgba(255, 255, 255, 0.1);
}

.button.button-gold:hover, .button.button-gold:active, .button.button-gold:focus, .button.button-gold:active:focus {
  background-color: rgba(255, 255, 255, 0.3);
}

.prefooter {
  padding: 2rem 0 1rem;
  max-width: 370px;
}

@media screen and (max-width: 767px) {
  .prefooter .l-container {
    padding: 0;
  }
}

.prefooter.prefooter--home {
  padding: 0;
}

.prefooter__left {
  width: 30%;
  padding: 30px 38px;
  background-color: #1f1f20;
}

@media screen and (max-width: 767px) {
  .prefooter__left {
    width: 100%;
    padding: 25px;
  }
}

.prefooter__details {
  margin-bottom: 20px;
}

.prefooter__details span {
  font-family: "Gilroy-ExtraBold";
  font-size: 26px;
  display: block;
  margin-bottom: 0;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 767px) {
  .prefooter__details span {
    font-size: 25px;
  }
}

.prefooter__details a {
  display: block;
  font-family: "Gilroy-ExtraBold";
  color: #fff;
}

@media screen and (max-width: 767px) {
  .prefooter__details a {
    font-size: 18px;
  }
}

.prefooter__locations h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 26px;
  display: inline-block;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 767px) {
  .prefooter__locations h2 {
    font-size: 25px;
  }
}

.prefooter__locations li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .7rem;
}

@media screen and (max-width: 767px) {
  .prefooter__locations li {
    margin-bottom: 20px;
  }
}

.prefooter__locations li:last-child {
  margin-bottom: 0;
}

.prefooter__locations-time {
  margin-right: 15px;
}

.prefooter__locations-details h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 20px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .prefooter__locations-details h3 {
    font-size: 18px;
  }
}

.prefooter__locations-details a {
  color: #969696;
  font-size: 15px;
  display: block;
  margin-top: 8px;
  line-height: 1;
  margin-bottom: -6px;
}

@media screen and (max-width: 767px) {
  .prefooter__locations-details a {
    font-size: 15px;
  }
}

.prefooter__right {
  width: 60%;
}

@media screen and (max-width: 767px) {
  .prefooter__right {
    width: 100%;
    margin: 50px 0;
    padding: 0 15px;
  }
}

.prefooter__right .wpcf7 {
  margin-top: 40px;
}

.prefooter__right .wpcf7 .prefooter__right-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.prefooter__right .wpcf7 .wpcf7-form-control-wrap {
  width: 47%;
}

@media screen and (max-width: 767px) {
  .prefooter__right .wpcf7 .wpcf7-form-control-wrap {
    width: 100%;
  }
}

.prefooter__right .wpcf7 .wpcf7-form-control-wrap input,
.prefooter__right .wpcf7 .wpcf7-form-control-wrap textarea {
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #383838;
  outline: none;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .prefooter__right .wpcf7 .wpcf7-form-control-wrap input,
  .prefooter__right .wpcf7 .wpcf7-form-control-wrap textarea {
    font-size: 15px;
    padding: 15px 0;
  }
}

.prefooter__right .wpcf7 .wpcf7-form-control-wrap input::placeholder,
.prefooter__right .wpcf7 .wpcf7-form-control-wrap textarea::placeholder {
  color: #d5d5d5;
}

.prefooter__right .wpcf7 .wpcf7-form-control-wrap input {
  margin-bottom: 14px;
}

@media screen and (max-width: 767px) {
  .prefooter__right .wpcf7 .wpcf7-form-control-wrap input {
    margin-bottom: 20px;
  }
}

.prefooter__right .wpcf7 .wpcf7-form-control-wrap textarea {
  resize: none;
}

@media screen and (max-width: 767px) {
  .prefooter__right .wpcf7 .wpcf7-form-control-wrap textarea {
    width: 100%;
  }
}

.prefooter__right .wpcf7 .link {
  font-weight: bold;
  position: relative;
  top: -16px;
  color: #fff;
  align-self: flex-end;
}

@media screen and (max-width: 767px) {
  .prefooter__right .wpcf7 .link {
    top: 0;
    margin: 40px auto 0;
  }
}

.prefooter__right h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 2.2vw;
  line-height: 1.3;
  margin-bottom: 20px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .prefooter__right h2 {
    font-size: 35px;
  }
}

.prefooter__right p {
  font-size: 22px;
  font-weight: bold;
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .prefooter__right p {
    font-size: 18px;
  }
}

.prefooter2 {
  background: #1a1a1a;
}

.prefooter2 .prefooter__left {
  position: relative;
  z-index: 1;
  width: 70%;
  padding: 56px;
  background-color: #d8d8d8;
}

.prefooter2 .prefooter__left:before {
  position: absolute;
  top: -15px;
  left: -15px;
  display: block;
  width: 37px;
  height: 37px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/service/caret.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.prefooter2 .prefooter__left h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 44px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #0e0e11;
}

.prefooter2 .prefooter__right {
  width: 30%;
  padding: 45px 50px;
  background-color: #1f1f20;
}

.prefooter2 .prefooter__right .prefooter__locations-details h3 {
  line-height: 1.7;
}

.prefooter2 .prefooter__right .prefooter__locations a {
  font-weight: normal;
  color: #787878;
}

.circle {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.circle1 {
  position: absolute;
  z-index: -1;
  top: -79%;
  left: -26%;
  display: block;
  width: 450px;
  height: 450px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/contact/subtract.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.clock {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  justify-content: center;
  align-items: center;
}

.clock__hour,
.clock__minute {
  position: absolute;
}

.clock__hour-handle {
  width: 40px;
  height: 40px;
}

.clock__hour-handle:before {
  position: absolute;
  top: 9px;
  width: 3px;
  height: 12px;
  content: '';
  border-radius: 6px 6px 0 0;
  background-image: linear-gradient(to bottom, #b12b2a 20%, #fb8631);
}

.clock__minute-handle {
  width: 50px;
  height: 50px;
}

.clock__minute-handle:before {
  position: absolute;
  top: 9px;
  width: 3px;
  height: 16px;
  content: '';
  border-radius: 6px 6px 0 0;
  background-image: linear-gradient(to bottom, #b12b2a 20%, #fb8631);
}

.clock__hour-handle,
.clock__minute-handle {
  display: flex;
  transition: transform 1s ease-in-out;
  border-radius: 50%;
  justify-content: center;
}

.is-active .traced-text path:nth-child(1) {
  animation: line-animation ease-in-out forwards 1s, box-shadow .75s ease-in-out forwards 1s;
  stroke-dasharray: 350px;
  stroke-dashoffset: 0;
  fill: transparent;
  stroke: #fff;
}

@keyframes line-animation {
  from {
    stroke-dasharray: 400px;
    stroke-dashoffset: 100%;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes box-shadow {
  to {
    fill: #fff;
  }
}

.footer {
  padding: 30px 0;
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 25px 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .footer__left {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .footer__logo {
    height: 70px;
  }
}

.footer__middle {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .footer__middle {
    width: 100%;
    margin: 30px 0;
  }
}

.footer__middle small {
  font-size: 15px;
  color: #858585;
}

.footer__links {
  font-size: 17px;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .footer__links {
    margin-bottom: 15px;
  }
}

.footer__links li {
  margin-right: 12px;
}

@media screen and (max-width: 767px) {
  .footer__links li {
    width: 50%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.footer__links li:last-child {
  margin-right: 0;
}

.footer__links a {
  transition: color 0.35s ease-in-out;
  color: #858585;
}

.footer__links a:hover {
  color: #b12b2a;
}

@media screen and (max-width: 767px) {
  .footer__right {
    width: 70%;
    margin: 0 auto;
  }
}

.footer__sns li {
  margin-right: 15px;
}

.footer__sns li:last-child {
  margin-right: 0;
}

.footer__sns li:last-child .icon {
  position: relative;
  top: 1px;
}

.footer__sns a {
  line-height: 2.7;
  position: relative;
  display: block;
  width: 45px;
  text-align: center;
  color: #fff;
  background-image: url(/wp-content/themes/lightmedia/assets/images/sns-border.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .footer__sns a {
    line-height: 2.6;
    width: 42px;
  }
}

.footer2 {
  padding-top: 0;
  background: #1a1a1a;
}

.footer2 .footer__sns a {
  line-height: 2.5;
  border-radius: 50%;
  background-color: #29292b;
  background-image: none;
}

.footer2 .footer__sns a:before {
  display: none;
}

.footer2 .footer__sns a i {
  position: relative;
  top: 2px;
}

.work-block {
  text-align: center;
  color: #fff;
}

.work-block__title {
  font-family: "Gilroy-ExtraBold";
  font-size: 42px;
  margin-bottom: 20px;
  text-align: left;
}

.work-block__image {
  width: 100%;
  height: 333px;
  object-fit: cover;
}

.work-block__description {
  font-weight: bold;
  line-height: 1.8;
  position: relative;
  width: 65%;
  margin: 30px 45px 30px auto;
  text-align: left;
  color: #cdcdcd;
}

.work-block__description:before {
  position: absolute;
  top: 6px;
  left: -80px;
  width: 100%;
  height: 30px;
  content: '';
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/works/quote.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .process-block, .l-service-v3__intro-block {
    text-align: center;
  }
}

.process-block__icon, .l-service-v3__intro-block__icon {
  position: relative;
  display: inline-block;
  margin-bottom: 35px;
  padding: 35px 0;
  background-color: #1f1f21;
  width: 130px;
  display: flex;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .process-block__icon, .l-service-v3__intro-block__icon {
    margin-bottom: 25px;
    padding: 25px 30px;
    margin-right: auto;
    margin-left: auto;
  }
}

.process-block__icon:before, .l-service-v3__intro-block__icon:before {
  position: absolute;
  top: -10px;
  left: -10px;
  display: block;
  width: 33px;
  height: 33px;
  content: '';
  border-top: 3px solid #c4c4c4;
  border-left: 3px solid #c4c4c4;
}

.process-block__icon:after, .l-service-v3__intro-block__icon:after {
  position: absolute;
  right: -10px;
  bottom: -10px;
  display: block;
  width: 33px;
  height: 33px;
  content: '';
  border-right: 3px solid #b12b2a;
  border-bottom: 3px solid #b12b2a;
}

.process-block__icon img, .l-service-v3__intro-block__icon img {
  margin: auto;
  text-align: center;
  width: 46px;
}

.process-block__counter {
  position: absolute;
  top: -10px;
  right: -10px;
}

.process-block__counter:before {
  font-family: "Gilroy-ExtraBold";
  font-size: 15px;
  display: block;
  padding: 6px 11px;
  content: "0" counter(process);
  background-color: #b12b2a;
}

.process-block__title, .l-service-v3__intro-block__title {
  font-family: "Gilroy-ExtraBold";
  font-size: 22px;
  margin-bottom: 10px;
}

.process-block__description, .l-service-v3__intro-block__description {
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .process-block__description, .l-service-v3__intro-block__description {
    font-size: 15px;
  }
}

.detail-block {
  position: relative;
  display: flex;
  align-items: center;
  background: #1a1a1a;
}

.detail-block__image, .detail-block__text {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 767px) {
  .detail-block__image, .detail-block__text {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.detail-block__image {
  min-height: 370px;
}

.detail-block__text {
  padding: 1rem;
}

@media (max-width: 767px) {
  .detail-block__text {
    text-align: center;
    padding: 0 15px;
  }
}

.detail-block:nth-child(2n) {
  background-color: #000;
  flex-direction: row-reverse;
}

.detail-block-l2 {
  background-color: transparent;
}

.detail-block-l3 {
  background-color: transparent;
  align-items: flex-start;
}

.detail-block-l3 .detail-block__image {
  min-height: 0;
}

.detail-block-l3 .detail-block__image {
  min-height: 370px;
}

@media (max-width: 767px) {
  .detail-block-l3 .detail-block__image {
    margin-bottom: 2em;
  }
}

.detail-block-l3 .detail-block__text {
  padding: 0;
  padding-left: 3rem;
}

@media (max-width: 767px) {
  .detail-block-l3 .detail-block__text {
    padding-left: 0;
  }
}

.detail-block-l3:not(:first-child) {
  margin-bottom: 4rem;
}

.detail-block-l3:nth-child(2n) .detail-block__text {
  padding-left: 0;
  padding-right: 3rem;
}

@media (max-width: 767px) {
  .detail-block {
    display: block;
  }
}

babylon {
  width: 100%;
}

.detail-block__image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .detail-block__image {
    width: 100%;
  }
}

.detail-block__image.detail-block__image--caption {
  position: relative;
}

.detail-block__image.detail-block__image--caption figcaption {
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  font-size: .8em;
  font-style: italic;
}

.detail-block__text {
  position: relative;
  padding: 2rem 3rem;
}

.detail-block__text h3, .detail-block__text h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 26px;
  margin-bottom: 1rem;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .detail-block__text h3, .detail-block__text h2 {
    margin-bottom: 10px;
    text-align: center;
  }
}

.detail-block__text h2 {
  font-size: 38px;
}

.detail-block__text p {
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .detail-block__text p {
    font-size: 15px;
    line-height: 1.8;
  }
}

.detail-block__text ul {
  margin: 0 0 0 18px;
}

.detail-block__text ul li {
  position: relative;
  padding-left: 30px;
}

.detail-block__text ul li:not(:last-child) {
  margin-bottom: .7rem;
}

.detail-block__text ul li:last-child {
  margin-bottom: 35px;
}

.detail-block__text ul li:before {
  position: absolute;
  top: 0;
  left: -15px;
  width: 28px;
  height: 28px;
  content: '';
  border-radius: 50%;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 80%);
}

.detail-block__text ul li:after {
  position: absolute;
  top: 6px;
  left: -5px;
  width: 8px;
  height: 13px;
  content: '';
  transform: rotate(45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

@media (max-width: 767px) {
  .detail-block__text ul li:before {
    width: 22px;
    height: 22px;
  }
  .detail-block__text ul li:after {
    top: 4px;
    left: -7px;
    width: 6px;
    height: 11px;
  }
}

.detail-block__text .button {
  margin-top: 1.5em;
}

@media (max-width: 767px) {
  .detail-block__text {
    padding: 40px 15px;
    width: 100%;
  }
  .detail-block__text ul li {
    padding-left: 18px;
  }
}

.detail-block.detail-block--reverse .detail-block__text {
  padding-left: 0;
  padding-right: 80px;
}

@media (max-width: 767px) {
  .detail-block.detail-block--reverse .detail-block__text {
    padding: 40px 0;
  }
}

.form-group {
  width: 50%;
  text-align: left;
  padding: 0 15px;
  margin-bottom: 30px;
  float: left;
  position: relative;
}

.form-group label {
  display: block;
  font-family: "Gilroy-ExtraBold";
  font-size: 17px;
  color: #EAEAEA;
  margin-bottom: 10px;
}

.form-group input, .form-group select, .form-group textarea {
  border: 2px solid #FADB31;
  width: 100%;
  font-family: "Gilroy-ExtraBold";
  color: #FFFFFF;
  padding: 15px;
}

.form-group input, .form-group select {
  height: 57px;
}

.form-group select {
  background-image: url(/wp-content/themes/lightmedia/assets/images/contact/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: 95% 23px;
  background-size: 12px;
}

.form-group select option {
  color: #000;
}

.form-group textarea {
  height: 137px;
}

.form-group input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.form-group.full-form-group {
  width: 100%;
}

.form-group .wpcf7-not-valid {
  border-color: #b22d2c;
}

.form-group .wpcf7-form-control-wrap {
  position: static;
}

.form-group .wpcf7-not-valid-tip {
  font-family: "Gilroy-ExtraBold";
  font-size: 13px;
  text-transform: uppercase;
  color: #B22D2C;
  position: absolute;
  top: 4px;
  right: 15px;
}

@media (max-width: 767px) {
  .form-group {
    width: 100%;
    float: none;
  }
}

.grid-block {
  background-color: #d8d8d8;
  text-align: center;
  padding: 55px;
}

.grid-block .grid-block__icon {
  height: 150px;
  margin-bottom: 20px;
}

.grid-block .grid-block__title {
  color: #202020;
  font-size: 22px;
  font-family: "Gilroy-ExtraBold";
  text-transform: capitalize;
  margin-bottom: 10px;
}

.grid-block .grid-block__description {
  color: #4F4F4F;
}

#gg-screen, .pong-popup {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
  text-align: center;
  opacity: 0;
  background-color: #000;
}

#gg-screen .content-left, .pong-popup .content-left,
#gg-screen .content-right,
.pong-popup .content-right {
  position: absolute;
  top: 0;
  bottom: 0;
  float: left;
  height: 100%;
  padding-top: 150px;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-left, .pong-popup .content-left,
  #gg-screen .content-right,
  .pong-popup .content-right {
    position: relative;
    height: auto;
    padding-top: 80px;
    padding-bottom: 0;
  }
}

#gg-screen .content-left .content-inner-wrap, .pong-popup .content-left .content-inner-wrap,
#gg-screen .content-right .content-inner-wrap,
.pong-popup .content-right .content-inner-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

#gg-screen .content-left, .pong-popup .content-left {
  left: -65%;
  width: 65%;
  padding-left: 80px;
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-left, .pong-popup .content-left {
    width: 100%;
    padding-left: 0;
  }
}

#gg-screen .content-left .gg-image, .pong-popup .content-left .gg-image {
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-left .gg-image, .pong-popup .content-left .gg-image {
    height: 230px;
  }
}

#gg-screen .content-left .title, .pong-popup .content-left .title {
  font-family: "Gilroy-ExtraBold";
  font-size: 70px;
  position: absolute;
  top: -100px;
  left: 0;
  color: #fff;
}

@media (max-width: 1199px) {
  #gg-screen .content-left .title, .pong-popup .content-left .title {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  #gg-screen .content-left .title, .pong-popup .content-left .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  #gg-screen .content-left .title, .pong-popup .content-left .title {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  #gg-screen .content-left .title, .pong-popup .content-left .title {
    position: relative;
    top: -15px;
  }
}


#gg-screen .content-right,
.pong-popup .content-right {
  right: -35%;
  width: 35%;
  padding-right: 80px;
  -webkit-transition: all .5s ease-in-out 0s;
  -moz-transition: all .5s ease-in-out 0s;
  -ms-transition: all .5s ease-in-out 0s;
  -o-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s;
}

@media screen and (max-width: 767px) {
  
  #gg-screen .content-right,
  .pong-popup .content-right {
    top: 0;
    width: 100%;
    margin-top: -7px;
    padding-top: 0;
    padding-right: 0;
  }
}


#gg-screen .content-right .content-inner-wrap,
.pong-popup .content-right .content-inner-wrap {
  padding: 40px 30px 30px;
  text-align: left;
  color: #202020;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  
  #gg-screen .content-right .content-inner-wrap,
  .pong-popup .content-right .content-inner-wrap {
    padding: 30px 15px 20px;
  }
}

#gg-screen .content-right h3, .pong-popup .content-right h3 {
  font-family: "Gilroy-ExtraBold";
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #202020;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right h3, .pong-popup .content-right h3 {
    font-size: 16px;
  }
}

#gg-screen .content-right .content-inner, .pong-popup .content-right .content-inner {
  display: block;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .content-inner, .pong-popup .content-right .content-inner {
    padding-bottom: 25px;
  }
}

#gg-screen .content-right .icon-logo, .pong-popup .content-right .icon-logo {
  display: block;
  max-width: 160px;
  margin-bottom: 15px;
}

#gg-screen .content-right .icon-logo img, .pong-popup .content-right .icon-logo img {
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .icon-logo img, .pong-popup .content-right .icon-logo img {
    max-height: 70px;
    object-fit: contain;
  }
}

#gg-screen .content-right .button-wrap, .pong-popup .content-right .button-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 25px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .button-wrap, .pong-popup .content-right .button-wrap {
    position: relative;
  }
}

#gg-screen .content-right .button-wrap .gg-bt, .pong-popup .content-right .button-wrap .gg-bt {
  font-family: "Gilroy-ExtraBold";
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  color: #b22d2c;
}

#gg-screen .content-right .button-wrap .gg-nxt, .pong-popup .content-right .button-wrap .gg-nxt {
  float: right;
}

#gg-screen .content-right .button-wrap .gg-prev, .pong-popup .content-right .button-wrap .gg-prev {
  float: left;
}

#gg-screen .content-right .description, .pong-popup .content-right .description {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .description, .pong-popup .content-right .description {
    font-size: 15px;
  }
}

#gg-screen .content-right .description p, .pong-popup .content-right .description p {
  color: initial;
}

#gg-screen .content-right .description-list ul, .pong-popup .content-right .description-list ul {
  margin-left: 15px;
  text-align: left;
}

#gg-screen .content-right .description-list ul li, .pong-popup .content-right .description-list ul li {
  position: relative;
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .description-list ul li, .pong-popup .content-right .description-list ul li {
    font-size: 15px;
    padding-left: 20px;
  }
}

#gg-screen .content-right .description-list ul li:not(:last-child), .pong-popup .content-right .description-list ul li:not(:last-child) {
  margin-bottom: 10px;
}

#gg-screen .content-right .description-list ul li:before, .pong-popup .content-right .description-list ul li:before {
  position: absolute;
  top: 0;
  left: -15px;
  width: 28px;
  height: 28px;
  content: '';
  border-radius: 50%;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 80%);
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .description-list ul li:before, .pong-popup .content-right .description-list ul li:before {
    width: 23px;
    height: 23px;
  }
}

#gg-screen .content-right .description-list ul li:after, .pong-popup .content-right .description-list ul li:after {
  position: absolute;
  top: 6px;
  left: -5px;
  width: 8px;
  height: 13px;
  content: '';
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

@media screen and (max-width: 767px) {
  #gg-screen .content-right .description-list ul li:after, .pong-popup .content-right .description-list ul li:after {
    top: 4px;
    left: -7px;
    width: 6px;
    height: 12px;
  }
}

#gg-screen.in, .in.pong-popup {
  z-index: 999999;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #gg-screen.in, .in.pong-popup {
    overflow-y: scroll;
  }
}

#gg-screen.in .content-left, .in.pong-popup .content-left {
  left: 0;
}

#gg-screen.in .content-right, .in.pong-popup .content-right {
  right: 0;
}

#gg-screen .gg-image, .pong-popup .gg-image {
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

#gg-screen .gg-image img, .pong-popup .gg-image img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.gg-close, .pong-popup-inner .pong-popup-close {
  position: absolute;
  top: -120px;
  right: 0;
  width: 51px;
  height: 51px;
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
  opacity: .7;
  background-image: url(/wp-content/themes/lightmedia/assets/images/our-works/close.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .gg-close, .pong-popup-inner .pong-popup-close {
    top: -350px;
    right: 12px;
    width: 35px;
    height: 35px;
  }
}

.gg-close:hover, .pong-popup-inner .pong-popup-close:hover {
  opacity: 1;
}

.pong-popup {
  left: 100%;
}

.pong-popup.in {
  left: 0;
}

.pong-popup-inner {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  max-width: 700px;
  margin: 0 auto;
  padding: 50px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: #191919;
}

.pong-popup-inner .pong-popup-close {
  top: -15%;
}

.pong-popup-inner .form-group {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.pong-popup-inner form {
  overflow: hidden;
}

.pong-popup-inner .btn-wrap {
  margin-bottom: 0;
}

.pong-popup-inner .btn-wrap button {
  display: block;
  width: 100%;
  padding: 20px;
}

.pong-popup-header {
  display: flex;
  margin-bottom: 20px;
  padding: 0 0 15px;
  border-bottom: 1px solid #242429;
}

.pong-popup-header h3 {
  font-family: "Gilroy-ExtraBold";
  text-align: left;
}

.pong-popup-header .pong-popup-header-title-left {
  margin-right: auto;
}

.pong-popup-header .pong-popup-header-title-left span {
  font-family: "Gilroy-Light";
}

.split-image .cell-grid .cell {
  transition: all 1s ease-in-out;
  opacity: 0;
}

.split-image .cell-grid .cell:nth-child(1) {
  transform: translate(-17px, -87px) rotate(5deg);
}

.split-image .cell-grid .cell:nth-child(2) {
  transform: translate(-305px, -171px) rotate(2deg);
}

.split-image .cell-grid .cell:nth-child(3) {
  transform: translate(-182px, -7px) rotate(12deg);
}

.split-image .cell-grid .cell:nth-child(4) {
  transform: translate(-343px, -199px) rotate(32deg);
}

.split-image .cell-grid .cell:nth-child(5) {
  transform: translate(-159px, -113px) rotate(10deg);
}

.split-image .cell-grid .cell:nth-child(6) {
  transform: translate(-202px, -166px) rotate(48deg);
}

.split-image .cell-grid .cell:nth-child(7) {
  transform: translate(-194px, -196px) rotate(7deg);
}

.split-image .cell-grid .cell:nth-child(8) {
  transform: translate(-100px, -63px) rotate(40deg);
}

.split-image .cell-grid .cell:nth-child(9) {
  transform: translate(-223px, -156px) rotate(9deg);
}

.split-image .cell-grid .cell:nth-child(10) {
  transform: translate(-71px, -55px) rotate(50deg);
}

.split-image .cell-grid .cell:nth-child(11) {
  transform: translate(-360px, -200px) rotate(55deg);
}

.split-image .cell-grid .cell:nth-child(12) {
  transform: translate(-54px, -28px) rotate(60deg);
}

.split-image .cell-grid .cell:nth-child(13) {
  transform: translate(-54px, -28px) rotate(60deg);
}

.split-image .cell-grid .cell:nth-child(14) {
  transform: translate(-344px, -70px) rotate(39deg);
}

.split-image .cell-grid .cell:nth-child(15) {
  transform: translate(-221px, -200px) rotate(120deg);
}

.split-image .cell-grid .cell:nth-child(16) {
  transform: translate(-336px, -192px) rotate(32deg);
}

.split-image .cell-grid .cell:nth-child(17) {
  transform: translate(-358px, -115px) rotate(17deg);
}

.split-image .cell-grid .cell:nth-child(18) {
  transform: translate(-276px, -41px) rotate(144deg);
}

.split-image .cell-grid .cell:nth-child(19) {
  transform: translate(-27px, -33px) rotate(95deg);
}

.split-image .cell-grid .cell:nth-child(20) {
  transform: translate(-102px, -192px) rotate(140deg);
}

.split-image .cell-grid .cell:nth-child(21) {
  transform: translate(-55px, -59px) rotate(21deg);
}

.split-image .cell-grid .cell:nth-child(22) {
  transform: translate(-159px, -166px) rotate(110deg);
}

.split-image .cell-grid .cell:nth-child(23) {
  transform: translate(-302px, -14px) rotate(184deg);
}

.split-image .cell-grid .cell:nth-child(24) {
  transform: translate(-162px, -70px) rotate(192deg);
}

.split-image.is-active .cell {
  transform: none !important;
  opacity: 1;
}

.loader {
  position: relative;
  z-index: 90;
  width: 100%;
  height: 100vh;
  background-color: #000;
}

.loader__image {
  position: fixed;
  top: 30%;
  left: 35%;
  width: 500px;
  height: 214px;
  z-index: 9999;
}

.loader__image svg {
  position: absolute;
  transform: translate(0, -50%);
}

.loader__image svg stop {
  -webkit-transition: .4s ease;
  transition: .3s ease;
}

.loader__image svg:first-child {
  top: 49.5%;
  left: 0;
}

.loader__image svg:first-child.is-active path:nth-child(1) {
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  fill: transparent;
  stroke: url(#paint0_linear);
}

.loader__image svg:last-child {
  top: 52%;
  left: 2.2rem;
}

.loader__image svg:last-child.is-active path:nth-child(1) {
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  fill: transparent;
  stroke: #fff;
}

.is-loaded {
  background-color: transparent;
  height: auto;
  width: auto;
  position: static;
}

.is-loaded .loader__image {
  display: block;
  top: -2.6rem;
  left: -.8rem;
  transition: all 1s;
  transform: scale(0.38);
}

.is-locked .loader__image {
  left: -3.5rem;
  transform: scale(0.4) !important;
}

@keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes box-shadow {
  to {
    fill: #fff;
  }
}

@keyframes gradient-shadow {
  to {
    fill: url(#paint0_linear);
  }
}

.l-index__play {
  padding: 14vh 0;
}

.l-index__play h2 svg {
  height: 18vh;
}

.pong__curtain {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #97080c 0%, #fbb431 100%);
}

.pong__top {
  text-align: left;
}

.pong__top h2 {
  font-size: 2.1vw;
}

.pong__top-outline {
  position: absolute;
  top: -143px;
  left: 600px;
}

.pong__content {
  margin-top: 2rem;
  display: flex;
}

.post__content-left {
  width: 50%;
}

.post__content-right {
  width: 50%;
  padding-left: 3.5rem;
}

.pong__scores {
  font-size: 14px;
  margin-top: 6vh;
  text-align: center;
  letter-spacing: .1em;
}

.pong__scores h3 {
  margin-bottom: 18px;
  font-size: 22px;
}

.pong__scores table td {
  width: 150px;
}

.pong__scores table thead {
  opacity: .6;
  font-family: "Gilroy-ExtraBold";
}

.pong__scores table tbody td {
  padding: 5px 0;
}

.pong__records {
  text-transform: uppercase;
  border: 2px dashed rgba(255, 255, 255, 0.15);
  padding: 18px;
  margin-top: 1rem;
  line-height: 1.1;
}

.pong__tools {
  font-family: "Gilroy-Regular", sans-serif;
}

.pong__restart,
.pong__stop {
  font-size: 14px;
  display: none;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 17px;
}

.pong__instructions {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  opacity: .8;
  text-align: center;
}

.pong__credit {
  margin-top: 10px;
  margin-bottom: -5px;
}

.pong__arrow {
  position: absolute;
  bottom: 0;
  left: 25px;
}

.pong__arrow a {
  display: block;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}

.pong__close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  width: 50px;
  height: 50px;
  margin: auto;
  padding: 22px 12px;
  border-width: 2px;
  border-color: #fff;
  border-radius: 50%;
}

.pong__close:before, .pong__close:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 20px;
  height: 3px;
  content: '';
  background-color: #fff;
}

.pong__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.pong__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.pong__game-canvas {
  position: relative;
  display: block;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

@media (max-width: 1300px) {
  .pong__game-canvas canvas#js-pong-container {
    width: 100% !important;
    height: auto !important;
  }
}

.pong__game-buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pong__tools {
  display: flex;
}

@media (max-width: 1300px) {
  .pong__instructions {
    font-size: 15px;
  }
  .l-index__play {
    padding: 12vh 0;
  }
}

.selected-works {
  width: 250%;
}

.selected-works__item {
  width: 15%;
}

.selected-works__item img {
  width: 100%;
  -webkit-filter: saturate(0);
  -webkit-transition: -webkit-filter 0.35s ease-in-out;
}

.selected-works__item:hover img {
  -webkit-filter: saturate(1);
}

.section-iframe-tours {
  position: relative;
  z-index: 2;
  margin-bottom: -130px;
}

.section-iframe-tours .laptop-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  width: 740px;
  max-width: 95%;
  margin: auto;
}

.section-iframe-tours .laptop-wrap .iframe-responsive {
  width: 100%;
  height: 100%;
  border: none;
}

.section-iframe-tours .laptop-wrap .iframe-responsive iframe {
  position: absolute;
  width: 82.3%;
  height: 82%;
  top: 7%;
  left: 9%;
}

.section-iframe-tours .laptop-wrap img {
  width: 100%;
}

.section-gallery-grid .gallery-wrap {
  display: flex;
  flex-wrap: wrap;
}

.section-gallery-grid .gallery-wrap .image-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.section-gallery-grid .gallery-wrap .image-item img {
  height: 260px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .section-gallery-grid .gallery-wrap .image-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.section-title {
  font-family: "Gilroy-ExtraBold";
  font-size: 45px;
}

.section-title.lg {
  font-size: 50px;
}

.section-title p {
  color: #D4D4D4;
  font-family: "Gilroy-Regular";
  font-size: 18px;
}

.section-title-actions {
  display: flex;
  margin-bottom: 40px;
}

.section-title-actions .section-title {
  width: 66%;
  max-width: 100%;
}

.section-title-actions .button {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 15px;
}

.section-title-actions.bb {
  border-bottom: 1px solid #333333;
  padding-bottom: 40px;
}

.section-service-features {
  padding: 90px 0;
}

@media (max-width: 640px) {
  .section-service-features .section-title {
    font-size: 40px;
  }
}

.section-service-features .service-feature-list {
  overflow: hidden;
  margin-top: 4em;
}

.section-service-features .service-feature-list .feature-item {
  width: 25%;
  float: left;
  position: relative;
}

.section-service-features .service-feature-list .feature-item .number {
  position: absolute;
  top: 0;
  left: 0;
  color: #101010;
  font-size: 80px;
  line-height: .9;
  z-index: 1;
  font-family: "Gilroy-ExtraBold";
}

.section-service-features .service-feature-list .feature-item h3 {
  position: relative;
  z-index: 2;
  padding: 47px 0 0 41px;
}

@media (max-width: 1024px) {
  .section-service-features .service-feature-list .feature-item h3 {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .section-service-features .service-feature-list .feature-item {
    width: 50%;
    margin-bottom: 2em;
  }
}

@media (max-width: 640px) {
  .section-service-features .service-feature-list .feature-item {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .section-service-features {
    padding: 4rem 0;
  }
}

@media (max-width: 767px) {
  .section-service-features .section-title-actions {
    display: block;
    text-align: center;
  }
  .section-service-features .section-title-actions .section-title {
    margin-bottom: 15px;
  }
  .section-service-features .section-title-actions .section-title, .section-service-features .section-title-actions .button {
    width: 100%;
  }
}

.service-feature-list.list-view {
  display: flex;
  flex-wrap: wrap;
}

.service-feature-list.list-view .feature-item {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
}

.service-feature-list.list-view .feature-item.full-width {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 30px;
}

.service-feature-list.list-view .feature-item.full-width .content h2 {
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .service-feature-list.list-view .feature-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.service-feature-list.list-view .feature-item .img-wrap {
  position: relative;
  display: inline-block;
  margin-bottom: 35px;
  background-color: #1f1f21;
  margin-right: 30px;
  padding: 35px 25px;
  display: flex;
  text-align: center;
}

.service-feature-list.list-view .feature-item .img-wrap img {
  margin: auto;
  text-align: center;
  width: 46px;
  max-width: 46px;
}

.service-feature-list.list-view .feature-item .content h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 22px;
  margin-bottom: 4px;
  line-height: 1;
}

.service-feature-list.list-view .feature-item .content p {
  font-size: 16px;
}

.section-vr .detail-block__image {
  display: flex;
  flex-direction: column;
}

.section-vr .detail-block__image img {
  display: block;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  object-fit: cover;
}

.section-vr .detail-block__image img.full {
  height: 100%;
  width: 100%;
}

.section-vr .detail-block__text p {
  font-family: Gilroy-Regular;
  line-height: 30px;
}

@media (max-width: 1280px) {
  .section-vr {
    padding: 4rem 0 !important;
  }
}

@media (max-width: 767px) {
  .section-vr .detail-block__text {
    text-align: center;
  }
  .section-vr .detail-block__image {
    min-height: 0;
  }
  .section-vr .detail-block__image img {
    width: 100%;
    height: auto;
  }
}

.detail-block.detail-block-alt .detail-block__image {
  flex: 0 0 30%;
  max-width: 30%;
  margin-top: auto;
  margin-bottom: auto;
}

.detail-block.detail-block-alt .detail-block__image img {
  margin: auto;
}

@media (max-width: 767px) {
  .detail-block.detail-block-alt .detail-block__image {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.detail-block.detail-block-alt .detail-block__text {
  flex: 0 0 70%;
  max-width: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 767px) {
  .detail-block.detail-block-alt .detail-block__text {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.detail-block.detail-block-40-50 .detail-block__image {
  flex: 0 0 45%;
  max-width: 45%;
  margin-top: auto;
  margin-bottom: auto;
}

.detail-block.detail-block-40-50 .detail-block__image img {
  margin: auto;
}

@media (max-width: 767px) {
  .detail-block.detail-block-40-50 .detail-block__image {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.detail-block.detail-block-40-50 .detail-block__text {
  flex: 0 0 55%;
  max-width: 55%;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 767px) {
  .detail-block.detail-block-40-50 .detail-block__text {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.section-cta-offset {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-cta-offset:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.section-cta-offset .section-cta-img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.section-cta-offset .content {
  position: relative;
  width: 915px;
  padding: 50px 55px 50px;
  background-color: #232323;
  margin: auto;
  text-align: center;
  max-width: 95%;
  z-index: 2;
}

.section-cta-offset .content h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 50px;
  margin-bottom: 15px;
}

@media (max-width: 640px) {
  .section-cta-offset .content h2 {
    font-size: 40px;
  }
}

.section-cta-offset .content p {
  margin-bottom: 40px;
}

.section-features-col-2 {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;
  overflow: hidden;
}

.section-features-col-2 .col-content {
  width: 40%;
  background-color: transparent;
  text-align: left;
  padding: 0 0 0px;
}

@media (max-width: 991px) {
  .section-features-col-2 .col-content {
    width: 100%;
  }
}

.section-features-col-2 .section-features-img {
  width: 45%;
  height: auto;
  position: absolute;
  top: 0;
  right: 3%;
  z-index: 1;
  height: 800px;
  object-fit: cover;
}

@media (max-width: 991px) {
  .section-features-col-2 .section-features-img {
    width: 100%;
    height: auto;
    position: relative;
    top: auto;
    right: auto;
    margin-top: 4em;
  }
}

.section-features-col-2 .section-heading {
  margin-bottom: 45px;
}

.section-features-col-2 .service-feature-list {
  margin-bottom: 45px;
}

@media (max-width: 1280px) {
  .section-features-col-2 .service-feature-list {
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .section-features-col-2 .service-feature-list {
    margin-bottom: 0;
  }
}

@media (max-width: 1280px) {
  .section-features-col-2 {
    margin: 4rem 0;
  }
}

@media (max-width: 991px) {
  .section-features-col-2 {
    margin-bottom: 0;
  }
}

.section-page-intro .intro-content {
  width: 990px;
  max-width: 100%;
  padding: 65px 0;
  text-align: center;
  margin: auto;
}

.section-page-intro .intro-content h1 {
  margin-bottom: 15px;
}

.section-page-intro .intro-content .description {
  margin-bottom: 0;
}

.section-how-it-works {
  position: relative;
  background-color: #1D1D1D !important;
  padding-top: 195px !important;
  z-index: 1;
}

.section-how-it-works .l-service__heading, .section-how-it-works .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .section-how-it-works .l-service-v3__heading {
  font-size: 45px;
}

.section-how-it-works .process-block__icon, .section-how-it-works .l-service-v3__intro-block__icon,
.section-how-it-works .l-service-v3__intro-block__icon {
  background-color: #2D2D2D;
}

.section-how-it-works .l-service-v3__intro-listing li {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section-how-it-works .l-service-v3__intro-listing li {
    margin-bottom: 30px;
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .section-how-it-works {
    padding-bottom: 4rem !important;
  }
}

.section-top-video-wrap {
  position: relative;
  margin-top: -5.3rem;
  padding-top: 5.3rem;
}

.section-top-video-wrap .video-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.section-top-video-wrap .video-bg > div {
  min-height: 100%;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.section-top-video-wrap .video-bg > div .plyr--video, .section-top-video-wrap .video-bg > div .plyr__video-wrapper {
  min-height: 100%;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.section-top-video-wrap .video-bg > div .plyr--video video, .section-top-video-wrap .video-bg > div .plyr__video-wrapper video {
  object-fit: cover;
}

.section-top-video-wrap .video-bg .plyr__control--overlaid,
.section-top-video-wrap .video-bg .plyr--video .plyr__controls {
  display: none !important;
}

.section-top-video-wrap .video-bg:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 3;
}

.section-top-video-wrap .l-service-v2__eyecatch-right {
  right: auto;
  left: -30px;
}

.section-top-video-wrap .l-section-left {
  padding: 2rem 0;
}

.section-top-video-wrap .l-container {
  position: relative;
  z-index: 4;
  padding: 3rem 0;
}

.section-top-video-wrap .l-service__intro, .section-top-video-wrap .l-service-v3__text-column {
  text-align: center;
}

.section-top-video-wrap .description {
  color: #fff;
}

.section-top-video-wrap .description p {
  color: #fff;
}

.section-top-video-wrap .l-service-v2__circle {
  transform: scale(1.45);
  z-index: 1;
}

.section-top-video-wrap.faded .video-bg > div {
  position: relative;
}

.section-top-video-wrap.faded .video-bg > div:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40%;
  width: 100%;
  z-index: 2;
  background: black;
  background: -moz-linear-gradient(0deg, black 0%, black 23%, rgba(0, 0, 0, 0.718925) 42%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(0deg, black 0%, black 23%, rgba(0, 0, 0, 0.718925) 42%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(0deg, black 0%, black 23%, rgba(0, 0, 0, 0.718925) 42%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.section-feature-list.column-4 .l-service-v3__intro-listing.u-flex {
  align-items: baseline;
}

.section-feature-list.column-4 .l-service-v3__intro-listing.u-flex li {
  width: 20%;
  margin-bottom: 50px;
}

.section-video-list .plyr--video {
  pointer-events: none !important;
}

.section-video-list .plyr--video video {
  pointer-events: none !important;
}

.section-video-list .plyr--video .plyr__captions, .section-video-list .plyr--video .plyr__control, .section-video-list .plyr--video .plyr__controls {
  display: none !important;
  opacity: 0;
  visibility: 0;
}

.tabbedPanels {
  width: 600px;
  margin-top: 25px;
}

.panelContainer {
  clear: left;
  margin-bottom: 25px;
  border: 2px solid green;
  background-color: #fff;
  padding: 10px;
  /* add height if you want equal panels */
}

/* reset ul defaults  */
.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* set li properties  */
.tabs li {
  float: left;
  width: 75px;
  padding: 0;
  margin: 0;
  text-align: center;
}

/* all formatting goes inside 'a' */
.tabs a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  padding: 8px;
  margin-right: 4px;
  /* spaces out the tabs  */
  border: 2px solid green;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: green;
  margin-bottom: -2px;
  /* negative margin will move it down
	                         to blend in with outer border  */
}

.tabs a.active {
  border-bottom: 2px solid white;
  background-color: #fff;
  color: #000;
  font-weight: bold;
}

.panel img {
  margin-top: 10px;
}

.panel p {
  margin-bottom: 0px;
}

.l-container {
  position: relative;
  width: 85%;
  margin: 0 auto;
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .l-container {
    width: 100%;
    padding: 0 22px;
  }
}

body.page .l-container {
  max-width: 1200px;
}

body.page .l-container.lg {
  max-width: 1450px;
}

.l-index {
  visibility: hidden;
}

.l-index.is-shown {
  visibility: visible;
}

.section-home-1 {
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 780px;
  position: relative;
}

@media (max-width: 767px) {
  .section-home-1 {
    min-height: 0;
  }
}

.section-home-1 .container {
  position: relative;
  z-index: 1;
}

.section-home-1 #js-blob-spider {
  position: absolute;
  top: 10%;
  bottom: 0;
}

@media (max-width: 767px) {
  .section-home-1 #js-blob-spider {
    top: auto;
  }
}

.section-home-1 .section-copy {
  position: relative;
  z-index: 2;
}

.section-home-1 .section-copy h1 {
  font-family: "Gilroy-ExtraBold";
  font-size: 6vw;
  line-height: 1.2;
  margin-bottom: 40px;
}

.section-home-1 .section-copy h1 span {
  position: relative;
  display: block;
  transition: all 0.35s ease-in-out;
}

.section-home-1 .section-copy h1 span:nth-child(2) {
  transition-delay: .25s;
}

.section-home-1 .section-copy h1 span:nth-child(3) {
  transition-delay: .5s;
}

@media (max-width: 767px) {
  .section-home-1 .section-copy h1 {
    font-size: 2.7rem;
  }
}

.section-home-1 .section-copy .link {
  font-family: "Gilroy-ExtraBold";
  display: inline-block;
  transition: all 0.35s ease-in-out;
  color: #fff;
}

@media (max-width: 767px) {
  .section-home-1 .section-copy {
    z-index: 1;
  }
}

.section-home-1 .hero__email {
  font-size: 20px;
  position: absolute;
  z-index: 21;
  right: -50px;
  bottom: 150px;
  display: block;
  transition: opacity 0.35s ease-in-out;
  transform: rotate(-90deg);
  opacity: .75;
  color: #fff;
}

@media (max-width: 767px) {
  .section-home-1 .hero__email {
    display: none;
  }
}

.section-home-1 .section-actions-float {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 20px;
}

@media (max-width: 1024px) {
  .section-home-1 .section-actions-float {
    transform: inherit;
    position: static;
    margin-top: 0;
    width: 130px;
  }
  .section-home-1 .section-actions-float .button {
    position: relative;
    display: block;
    padding: 15px 0 13px;
    margin-right: 1rem;
  }
}

.section-home-1 .section-actions-float .button {
  display: block;
  background-color: #292929;
  margin: 12px 0;
}

.section-home-1 .section-actions-float .button:before {
  position: absolute;
  top: 50%;
  right: -10px;
  width: 24px;
  height: 2px;
  content: '';
  transition: right 0.35s ease-in-out;
  transform: translateY(-50%);
  background-color: #d5d5d5;
}

.section-home-1 .section-actions-float .button:after {
  position: absolute;
  top: 50%;
  right: -10px;
  display: block;
  width: 8px;
  height: 8px;
  content: '';
  transition: right 0.35s ease-in-out;
  transform: translateY(-50%) rotate(45deg);
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
}

.l-index__mask {
  z-index: 21;
  width: 100vw;
  height: 100vh;
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/mask-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.l-index__services {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.l-index__services .l-index__heading-top h2 {
  display: block;
  text-align: right;
}

.l-index__services-bg {
  width: 100%;
  padding: 12vh 0;
}

.l-index__services-bg .services-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/services-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: .3s ease;
  transform: scale(1);
}

.l-index__services-bg .services-bg:after {
  content: '';
  height: 400px;
  width: 100%;
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/services-bg-top-shadow.png);
  background-repeat: repeat-x;
  background-position: center top;
  display: block;
  background-size: auto 100%;
}

.l-index__heading-top svg {
  position: relative;
  height: 20vh;
}

.l-index__heading-top svg path {
  fill: #fff;
}

.l-index__play {
  position: relative;
  text-align: center;
}

.l-index__works {
  position: relative;
  overflow: hidden;
  padding: 150px 0 130px;
}

.l-index__works:before {
  position: absolute;
  top: 195px;
  left: 0;
  display: block;
  width: 82%;
  height: 920px;
  content: '';
  background-color: #18181a;
  box-shadow: inset 0 -250px 90px -90px #18181a;
}

.l-index__sponsors {
  padding: 5rem 0px 6rem;
}

.l-index__sponsors h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 4.8vw;
  line-height: 1.2;
  margin: auto;
  text-align: center;
  margin-bottom: 4.5rem;
}

.l-index__sponsors-left {
  position: relative;
  width: 45%;
  padding: 36px 45px;
  background-color: #fff;
  text-align: center;
}

.l-index__sponsors-left span {
  font-family: "Gilroy-ExtraBold";
  font-size: 30px;
  display: block;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.l-index__sponsors-left h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 10px;
  padding-right: 30px;
  color: #000;
}

.l-index__sponsors-left p {
  line-height: 1.7;
  margin-bottom: 20px;
  color: #4f4f4f;
}

.l-index__sponsors-left a {
  font-family: "Gilroy-ExtraBold";
  font-size: 17px;
  color: #000;
}

.l-index__sponsors-right {
  width: 50%;
}

.l-index__sponsors-list li {
  position: relative;
  width: 30%;
  margin-bottom: 2.5rem;
  text-align: center;
}

.l-index__sponsors-list li:last-child, .l-index__sponsors-list li:nth-last-child(2), .l-index__sponsors-list li:nth-last-child(3) {
  margin-bottom: 0;
}

.l-index__sponsors-list li img {
  width: 120px;
  max-width: 100%;
  filter: brightness(0) invert(1);
}

.l-index__sponsors-arrow {
  position: absolute;
  bottom: 25px;
  left: 25px;
}

.l-index__sponsors-arrow a {
  display: block;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}

.section-services {
  padding: 4rem 0;
}

.section-services-homepage {
  margin-top: -30vh;
  margin-bottom: 3vh;
}

.services-item {
  display: block;
  padding: .6rem 0;
}

.services-item > a {
  transition: all 0.35s;
  display: inline-block;
  position: relative;
  left: 0;
}

.services-item > a .icon-hover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 65px;
  content: '';
  background-position: 99% 0;
  background-size: 200% 100%;
  transition: background .3s;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-right: 0;
}

.services-item > a .icon-hover:before, .services-item > a .icon-hover:after {
  content: '';
  position: absolute;
  right: 0;
  width: 2px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
}

.services-item > a .icon-hover:before {
  top: 0;
}

.services-item > a .icon-hover:after {
  bottom: 0;
}

.services-item > a span {
  color: #d5d5d5;
  font-family: "Gilroy-ExtraBold";
  line-height: 1.4;
  padding: 12px 28px;
  min-height: 72px;
  position: relative;
  z-index: 1;
  text-shadow: #000 0 1px 28px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.services-item > a:hover {
  color: #fff;
  left: -22px;
}

.services-item > a:hover span {
  text-shadow: none;
}

.services-item > a:hover .icon-hover {
  width: 100%;
  background-image: linear-gradient(to left, transparent, transparent 50%, #97080C 50%, #FB8631);
  background-position: 0 0;
  background-size: 200% 100%;
  border-color: transparent;
}

.services-item > a:hover .icon-hover:before, .services-item > a:hover .icon-hover:after {
  background-color: transparent;
}

@media (max-height: 700px) {
  .services-item {
    padding: 5px 0;
  }
  .services-item > a span {
    min-height: 55px;
    font-size: 15px;
  }
}

section#section-contact {
  padding: 4rem 0;
}

@media (max-width: 1024px) {
  .services-item {
    padding: .2rem 0;
  }
  .services-item > a span {
    font-size: 16px;
    padding: 12px 28px;
  }
  .row .col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .section-services-homepage {
    margin-top: 0;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .row .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-height: 700px) {
  .section-services {
    padding: 1rem 0;
  }
}

.l-works, .l-contact {
  padding: 100px 0 50px;
}

.l-works__top {
  text-align: center;
}

.l-works__top h1 {
  font-family: "Gilroy-ExtraBold";
  font-size: 70px;
}

.l-works__list {
  width: 1024px;
  margin: 70px auto 90px;
  align-items: initial;
  transform-style: preserve-3d;
  will-change: transform;
}

.l-works__list li {
  width: 44%;
  margin-bottom: 20px;
}

.l-works__list li:nth-of-type(even) {
  margin-top: 65px;
}

.l-works__loading {
  display: block;
  width: 222px;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
  color: #cdcdcd;
  background-color: #1f1f20;
}

@media screen and (max-width: 767px) {
  .l-works__loading {
    font-size: 16px;
    width: 185px;
  }
}

.l-service, .l-service-v2, .l-service-v3 {
  position: relative;
  padding: 5.3rem 0 50px;
}

.l-service .description, .l-service-v2 .description, .l-service-v3 .description {
  margin-bottom: 30px;
}

.l-service .description p, .l-service-v2 .description p, .l-service-v3 .description p {
  margin-bottom: 15px;
}

.l-service .description p:last-child, .l-service-v2 .description p:last-child, .l-service-v3 .description p:last-child {
  margin-bottom: 0;
}

.l-service img, .l-service-v2 img, .l-service-v3 img {
  max-width: 100%;
}

@media screen and (max-width: 1024px) {
  .l-service, .l-service-v2, .l-service-v3 {
    padding-bottom: 0;
  }
}

.l-service__heading, .l-service-v3__call-to-action .l-service-v3__heading {
  font-family: "Gilroy-ExtraBold";
  font-size: 38px;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .l-service__heading, .l-service-v3__call-to-action .l-service-v3__heading {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .l-service__heading, .l-service-v3__call-to-action .l-service-v3__heading {
    font-size: 30px;
  }
}

.l-service__content p {
  margin-bottom: 25px;
}

.l-service__content p:last-child {
  margin-bottom: 0;
}

.l-service__eyecatch {
  position: relative;
  min-height: 630px;
  display: flex;
  align-items: center;
}

.l-service__eyecatch.l-service__eyecatch-middle {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

@media screen and (max-width: 767px) {
  .l-service__eyecatch {
    min-height: 0;
    padding-bottom: 50px;
  }
}

.l-service__eyecatch-left, .l-service-v3__eyecatch-content-upper {
  width: 60%;
}

@media screen and (max-width: 1399px) {
  .l-service__eyecatch-left, .l-service-v3__eyecatch-content-upper {
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .l-service__eyecatch-left, .l-service-v3__eyecatch-content-upper {
    width: 100%;
    padding: 0;
    text-align: center;
  }
}

.l-service__eyecatch-left p, .l-service-v3__eyecatch-content-upper p {
  width: 80%;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .l-service__eyecatch-left p, .l-service-v3__eyecatch-content-upper p {
    width: 100%;
  }
}

.l-service__eyecatch-left ul, .l-service-v3__eyecatch-content-upper ul {
  margin-bottom: 60px;
}

.l-service__eyecatch-description {
  margin-bottom: 1.6rem;
}

.l-service__eyecatch-item {
  width: 45%;
  margin-bottom: 40px;
}

.l-service__eyecatch-item:last-child, .l-service__eyecatch-item:nth-last-child(2) {
  margin-bottom: 0;
}

.l-service__eyecatch-item:nth-child(3) img {
  padding: 28px 30px;
}

.l-service__eyecatch-item img {
  width: 35%;
  height: 110px;
  padding: 30px;
  background-color: #242429;
  object-position: bottom;
  object-fit: cover;
}

.l-service__eyecatch-item span {
  font-size: 20px;
  font-weight: bold;
  width: 60%;
}

.l-service__eyecatch-right, .l-service-v2__eyecatch-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  height: 630px;
  background-size: cover;
}

.l-service__eyecatch-right:before, .l-service-v2__eyecatch-right:before {
  position: absolute;
  top: 0;
  left: -150px;
  display: block;
  width: 86px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .l-service__eyecatch-right, .l-service-v2__eyecatch-right {
    display: none;
  }
}

.l-service__intro, .l-service-v3__text-column {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .l-service__intro, .l-service-v3__text-column {
    padding: 50px 0;
  }
  .l-service__intro .l-service__heading, .l-service-v3__text-column .l-service__heading, .l-service__intro .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service__intro .l-service-v3__heading, .l-service-v3__text-column .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-v3__text-column .l-service-v3__heading {
    text-align: center;
  }
}

.l-service__intro-content {
  align-items: initial;
}

.l-service__intro-content p {
  margin-bottom: 15px;
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .l-service__intro-content p {
    width: 100%;
  }
}

.l-service__intro-content.l-service__intro-content-full p {
  width: 100%;
}

.l-service__intro-content.l-service__intro-content-two-column .column-left, .l-service__intro-content.l-service__intro-content-two-column .column-right {
  width: 50%;
}

.l-service__intro-content.l-service__intro-content-two-column .column-left p, .l-service__intro-content.l-service__intro-content-two-column .column-right p {
  width: 100%;
  margin-bottom: 20px;
}

.l-service__intro-content.l-service__intro-content-two-column .column-left {
  padding-right: 30px;
}

.l-service__intro-content.l-service__intro-content-two-column .column-right {
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .l-service__intro-content {
    text-align: center;
  }
}

.l-service__process, .l-service-v3__intro {
  position: relative;
  padding: 4rem 0 4.2rem;
  background-color: #1a1a1a;
}

.l-service__process ul, .l-service-v3__intro ul {
  margin-top: 3rem;
  counter-reset: process;
}

.l-service__process li, .l-service-v3__intro li {
  width: 20%;
  counter-increment: process;
}

.l-service__process .column-2, .l-service-v3__intro .column-2 {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  counter-reset: process;
}

.l-service__process .column-2 li, .l-service-v3__intro .column-2 li {
  width: 45%;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .l-service__process .column-2 li, .l-service-v3__intro .column-2 li {
    width: 100%;
  }
}

.l-service__process .column-3, .l-service-v3__intro .column-3 {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  counter-reset: process;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.l-service__process .column-3 li, .l-service-v3__intro .column-3 li {
  width: 33.333%;
  margin-bottom: 2.3rem;
  padding: 0 1.5rem;
}

@media screen and (max-width: 767px) {
  .l-service__process .column-3 li, .l-service-v3__intro .column-3 li {
    width: 100%;
  }
}

.l-service__process .btn-wrap, .l-service-v3__intro .btn-wrap {
  padding-top: 0;
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  .l-service__process, .l-service-v3__intro {
    padding: 50px 0;
    text-align: center;
  }
}

.l-service__process-button {
  position: absolute;
  top: -30px;
  right: 31%;
}

.l-service__details {
  padding: 3rem 0;
}

.l-service__details h2 {
  text-align: center;
}

.l-service__details ul {
  counter-reset: details;
}

.l-service__details ul li {
  counter-increment: details;
  margin-bottom: 2rem;
}

.l-service__details ul li:last-child {
  margin-bottom: 0;
}

.l-service__details ul li ul {
  margin: 0 0 0 18px;
}

@media screen and (max-width: 767px) {
  .l-service__details ul li {
    margin-bottom: 0;
  }
}

@media (max-width: 1366px) {
  .l-service__details ul {
    margin: 0;
  }
}

.l-service__details .l-service__content {
  text-align: center;
  margin-bottom: 3rem;
}

.l-service__details .detail-block__text h2 {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .l-service__details .detail-block__text h2 {
    text-align: center;
  }
}

.l-service__details .btn-wrap {
  text-align: center;
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .l-service__details .btn-wrap {
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-service__details {
    padding: 50px 0 0;
  }
}

.l-service__info {
  position: relative;
  padding: 3rem 0 01rem;
}

.l-service__info.l-service__info-no-line:before {
  display: none;
}

.l-service__info.l-service__info-l2 {
  border-top: 1px solid #1d1d1d;
  padding-top: 3.5rem;
  padding-bottom: 4rem;
  margin-top: 2rem;
}

@media (max-width: 1024px) {
  .l-service__info.l-service__info-l2 {
    margin-top: 0;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .l-service__info {
    text-align: center;
  }
}

.l-service__info-left {
  width: 50%;
}

.l-service__info-left p {
  color: #d5d5d5;
}

.l-service__info-left p:not(:last-child) {
  margin-bottom: 14px;
}

.l-service__info-left.l-service__info-full {
  width: 100%;
}

.l-service__info-left.l-service__info-full ul {
  margin-left: 15px;
  text-align: left;
}

.l-service__info-left.l-service__info-full ul li {
  position: relative;
  padding-left: 25px;
}

.l-service__info-left.l-service__info-full ul li:not(:last-child) {
  margin-bottom: 14px;
}

.l-service__info-left.l-service__info-full ul li:last-child {
  margin-bottom: 35px;
}

.l-service__info-left.l-service__info-full ul li:before {
  position: absolute;
  top: 0;
  left: -15px;
  width: 28px;
  height: 28px;
  content: '';
  border-radius: 50%;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 80%);
}

.l-service__info-left.l-service__info-full ul li:after {
  position: absolute;
  top: 6px;
  left: -5px;
  width: 8px;
  height: 13px;
  content: '';
  transform: rotate(45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

.l-service__info-right, .l-service__solutions-right, .l-service-v3__plans-right, .l-contact-form-contact {
  position: relative;
  width: 42%;
  padding: 60px 50px;
  text-align: center;
  background-color: #191919;
}

.l-service__info-right:before, .l-service__solutions-right:before, .l-service-v3__plans-right:before, .l-contact-form-contact:before {
  position: absolute;
  top: -15px;
  left: -15px;
  display: block;
  width: 37px;
  height: 37px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/service/caret.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.l-service__info-right h3, .l-service__solutions-right h3, .l-service-v3__plans-right h3, .l-contact-form-contact h3, .l-service__info-right h2, .l-service__solutions-right h2, .l-service-v3__plans-right h2, .l-contact-form-contact h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 28px;
  margin-bottom: 25px;
  text-align: left;
}

.l-service__info-right ul, .l-service__solutions-right ul, .l-service-v3__plans-right ul, .l-contact-form-contact ul {
  margin-left: 15px;
  text-align: left;
}

.l-service__info-right ul li, .l-service__solutions-right ul li, .l-service-v3__plans-right ul li, .l-contact-form-contact ul li {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  padding-left: 30px;
}

.l-service__info-right ul li:not(:last-child), .l-service__solutions-right ul li:not(:last-child), .l-service-v3__plans-right ul li:not(:last-child), .l-contact-form-contact ul li:not(:last-child) {
  margin-bottom: 25px;
}

.l-service__info-right ul li:last-child, .l-service__solutions-right ul li:last-child, .l-service-v3__plans-right ul li:last-child, .l-contact-form-contact ul li:last-child {
  margin-bottom: 35px;
}

.l-service__info-right ul li:before, .l-service__solutions-right ul li:before, .l-service-v3__plans-right ul li:before, .l-contact-form-contact ul li:before {
  position: absolute;
  top: 0;
  left: -15px;
  width: 28px;
  height: 28px;
  content: '';
  border-radius: 50%;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 80%);
}

.l-service__info-right ul li:after, .l-service__solutions-right ul li:after, .l-service-v3__plans-right ul li:after, .l-contact-form-contact ul li:after {
  position: absolute;
  top: 6px;
  left: -5px;
  width: 8px;
  height: 13px;
  content: '';
  transform: rotate(45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

.l-service__other {
  padding: 80px 0 0;
  text-align: center;
}

.l-service__other h2 {
  margin-bottom: 40px;
}

.l-service__other .l-index__services-list {
  padding-right: 0;
}

.l-service__other .l-index__services-item {
  text-align: left;
  padding-right: 6rem;
}

@media (max-width: 1024px) {
  .l-service__other .l-index__services-item {
    padding-right: 2rem;
  }
}

@media (max-width: 640px) {
  .l-service__other .l-index__services-item {
    width: 50%;
  }
}

.section-testimonial {
  position: relative;
  padding: 4rem 0 4rem;
  text-align: center;
  background: #252525;
}

.section-testimonial h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 3.2rem;
  margin-bottom: 2.4rem;
}

.testimonials {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
  padding: 0 4rem;
}

.testimonials .slick-slide {
  min-height: 180px;
  display: flex !important;
  align-items: center;
}

.testimonials:after, .testimonials:before {
  content: '';
  width: 60px;
  height: 42px;
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
}

.testimonials:before {
  left: -4rem;
  top: 0rem;
  background-image: url(/wp-content/themes/lightmedia/assets/images/works/quote-2.svg);
}

.testimonials:after {
  right: -4rem;
  bottom: 0;
  background-image: url(/wp-content/themes/lightmedia/assets/images/works/quote.svg);
}

.testimonials li {
  width: 100%;
}

.testimonials li img {
  height: 40px;
  margin: 0 auto 20px;
  transform: rotate(180deg);
}

.testimonials li h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 22px;
  margin-bottom: 12px;
}

.testimonials li p {
  font-size: 19px;
  line-height: 1.7;
  color: #d5d5d5;
}

.testimonials .slick-dots {
  display: flex;
  width: 100px;
  margin: 40px auto 0;
  list-style-type: none;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.testimonials .slick-dots li {
  width: 10px;
  height: 10px;
  cursor: pointer;
  transition: background-color 0.35s ease-in-out, opacity 0.35s ease-in-out;
  border-radius: 100%;
  background-color: #bdbdbd;
}

.testimonials .slick-dots li.slick-active {
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 60%);
}

.l-service__logos-list {
  margin: 80px auto 0;
}

.l-service__logos-list li {
  width: 18%;
}

.l-service__logos-list li img {
  width: 120px;
  max-width: 100%;
  opacity: .5;
  filter: brightness(0) invert(1);
}

.l-services-border-bottom {
  border-bottom: 1px solid #282828;
}

.l-service-web-development .l-service__eyecatch, .l-services .l-service__eyecatch {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.l-service-web-development .l-service__process .l-service__heading, .l-services .l-service__process .l-service__heading, .l-service-web-development .l-service-v3__intro .l-service__heading, .l-services .l-service-v3__intro .l-service__heading, .l-service-web-development .l-service__process .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-web-development .l-service__process .l-service-v3__heading, .l-services .l-service__process .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-services .l-service__process .l-service-v3__heading, .l-service-web-development .l-service-v3__intro .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-web-development .l-service-v3__intro .l-service-v3__heading, .l-services .l-service-v3__intro .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-services .l-service-v3__intro .l-service-v3__heading,
.l-service-web-development .l-service__process .btn-wrap,
.l-services .l-service__process .btn-wrap,
.l-service-web-development .l-service-v3__intro .btn-wrap,
.l-services .l-service-v3__intro .btn-wrap,
.l-service-web-development .l-service__process .l-service__intro-content,
.l-services .l-service__process .l-service__intro-content,
.l-service-web-development .l-service-v3__intro .l-service__intro-content,
.l-services .l-service-v3__intro .l-service__intro-content {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .l-service-web-development .l-service__details, .l-services .l-service__details {
    padding-bottom: 50px;
  }
}

.l-service-iphone-and-android-apps .l-service__details,
.l-service-media-buying .l-service__details {
  padding-bottom: 0;
}

.l-service-iphone-and-android-apps .l-service__details ul,
.l-service-media-buying .l-service__details ul {
  margin: 0;
  border-bottom: 1px solid #282828;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .l-service-iphone-and-android-apps .l-service__details ul,
  .l-service-media-buying .l-service__details ul {
    padding-bottom: 50px;
  }
}

.l-service-iphone-and-android-apps .l-service__details .detail-block__image,
.l-service-iphone-and-android-apps .l-service__details .detail-block__text,
.l-service-media-buying .l-service__details .detail-block__image,
.l-service-media-buying .l-service__details .detail-block__text {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .l-service-iphone-and-android-apps .l-service__details .detail-block__image,
  .l-service-iphone-and-android-apps .l-service__details .detail-block__text,
  .l-service-media-buying .l-service__details .detail-block__image,
  .l-service-media-buying .l-service__details .detail-block__text {
    width: 100%;
  }
}

.l-service-iphone-and-android-apps .l-service__info:before,
.l-service-media-buying .l-service__info:before {
  display: none;
}

.l-service-marketing .l-service__details ul {
  border-bottom: none;
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-service-marketing .l-service__details {
    padding-bottom: 20px;
  }
}

.l-service-marketing .detail-block__text .button {
  margin-top: 0;
}

.l-services .l-service__eyecatch .l-service__eyecatch-right, .l-services .l-service__eyecatch .l-service-v2__eyecatch-right {
  width: 781px;
  height: 565px;
  right: auto;
  left: 27rem;
  top: -10rem;
  position: relative;
  z-index: 1;
}

.l-services .l-service__eyecatch .l-service__eyecatch-right:before, .l-services .l-service__eyecatch .l-service-v2__eyecatch-right:before {
  display: none;
}

.l-services .l-service__eyecatch .l-service__eyecatch-left, .l-services .l-service__eyecatch .l-service-v3__eyecatch-content-upper {
  position: relative;
  width: 50%;
}

.l-services .l-service__eyecatch .l-service__eyecatch-left:before, .l-services .l-service__eyecatch .l-service-v3__eyecatch-content-upper:before {
  position: absolute;
  top: -15px;
  left: -37px;
  display: block;
  width: 86px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.l-service-iphone-and-android-apps .l-section-1 .l-section-left, .l-service-iphone-and-android-apps .l-section-1 .l-section-right {
  width: 50%;
}

@media (max-width: 767px) {
  .l-service-iphone-and-android-apps .l-section-1 .l-section-left, .l-service-iphone-and-android-apps .l-section-1 .l-section-right {
    width: 100%;
  }
}

.l-service-iphone-and-android-apps .l-service__info {
  padding: 4rem 0;
}

@media (max-width: 1280px) {
  .l-service-iphone-and-android-apps .l-service__details {
    padding-bottom: 0 !important;
  }
}

.l-service-web-development .l-service__intro, .l-services .l-service__intro, .l-service-web-development .l-service-v3__text-column, .l-services .l-service-v3__text-column {
  padding-top: 0;
}

@media (max-width: 767px) {
  .l-service-web-development .l-service__intro, .l-services .l-service__intro, .l-service-web-development .l-service-v3__text-column, .l-services .l-service-v3__text-column {
    padding-top: 50px;
  }
}

.l-service-web-development .l-service__process .column-3, .l-services .l-service__process .column-3, .l-service-web-development .l-service-v3__intro .column-3, .l-services .l-service-v3__intro .column-3 {
  margin-top: 0;
  margin-bottom: 30px;
}

.l-service-web-development .l-service__process .l-service__intro-content-full, .l-services .l-service__process .l-service__intro-content-full, .l-service-web-development .l-service-v3__intro .l-service__intro-content-full, .l-services .l-service-v3__intro .l-service__intro-content-full {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5em;
}

.l-service-web-development .l-service__details .l-service__content, .l-services .l-service__details .l-service__content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.l-service .banner-intro .img-right, .l-service-v2 .banner-intro .img-right, .l-service-v3 .banner-intro .img-right {
  margin-top: 50px;
  margin-bottom: 50px;
}

.l-service__details-l2 {
  background-size: cover;
  background-position: center;
  min-height: 450px;
  display: flex;
  align-items: center;
}

.l-service__details-l2 .detail-block {
  max-width: 700px;
  margin: auto;
  background: #000;
  padding: 2.5rem 3rem 3rem;
}

.l-service__details-l2 .detail-block .detail-block__text {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}

@media (max-width: 767px) {
  .l-service__details-l2 * {
    text-align: left;
  }
  .l-service__details-l2 .detail-block {
    padding: 2rem 2rem;
    text-align: left;
  }
}

.detail-block__image {
  position: relative;
}

.detail-block__image figcaption {
  position: absolute;
  bottom: -34px;
  left: 0;
  right: 0;
  font-size: 90%;
  text-align: center;
  opacity: .6;
}

@media (max-width: 767px) {
  .l-service-information-architecture .l-service__details .detail-block {
    display: block;
  }
  .l-service-information-architecture .l-service__details .detail-block .detail-block__image, .l-service-information-architecture .l-service__details .detail-block .detail-block__text {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .l-service-information-architecture .l-service__details .detail-block .detail-block__text {
    padding-left: 0;
    text-align: center;
  }
  .l-service-information-architecture .l-service__details .detail-block .detail-block__image {
    margin-bottom: 2em;
  }
}

@media (max-width: 767px) {
  .l-service-information-architecture .l-service__eyecatch .l-service__eyecatch-left, .l-service-information-architecture .l-service__eyecatch .l-service-v3__eyecatch-content-upper {
    margin: 2em auto 0;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.l-service-v2__eyecatch-right {
  height: 300px;
  width: 730px;
  top: 200px;
  right: 100px;
  z-index: 0;
  opacity: 0.5;
}

.l-service-v2__eyecatch-right:before {
  background-image: url(/wp-content/themes/lightmedia/assets/images/service-v2/dots.png);
  top: -61%;
  right: 0;
  left: auto;
  width: 103px;
  z-index: 2;
}

.l-service-v2__circle {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.l-service-v2__circle .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.l-service-v2__circle .circle1 {
  width: 730px;
  height: 730px;
  background: #151515;
}

.l-service-v2__circle .circle2 {
  width: 548px;
  height: 548px;
  background: #1C1C1C;
}

.l-service-v2__circle .circle3 {
  width: 386px;
  height: 386px;
  background: #282828;
}

.l-service-v2__circle .circle4 {
  width: 220px;
  height: 220px;
  background: #313131;
}

.l-service-v2__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 540px;
  height: 310px;
  z-index: 1;
}

.l-service-v2__icon .img {
  position: absolute;
  z-index: 1;
}

.l-service-v2__icon .img1 {
  left: 11%;
  bottom: 61%;
}

.l-service-v2__icon .img2 {
  left: 23%;
  top: -14%;
}

.l-service-v2__icon .img3 {
  right: 23%;
  top: -14%;
}

.l-service-v2__icon .img4 {
  right: 7%;
  bottom: 64%;
}

.l-service-v2__eyecatch-full {
  position: relative;
  z-index: 1;
}

.l-service-v2__eyecatch-full img {
  width: 100%;
  height: 540px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .l-service-v2__eyecatch-full img {
    height: auto;
  }
}

.l-service-v2__grid {
  margin-bottom: 110px;
}

.l-service-v2__grid ul li {
  width: 33.333333%;
  padding-right: 30px;
}

.l-service-v2__grid ul li:last-child {
  padding-right: 0;
}

.l-service-v2__solutions, .l-service-v3__plans {
  position: relative;
  padding-bottom: 3em;
}

.l-service-v2__solutions .l-container, .l-service-v3__plans .l-container {
  overflow: hidden;
}

.l-service__solutions-right, .l-service-v3__plans-right {
  width: 48%;
  float: right;
  background-color: transparent;
  text-align: left;
}

.l-service__solutions-right:before, .l-service-v3__plans-right:before {
  display: none;
}

.l-service__solutions-right .l-service__heading, .l-service-v3__plans-right .l-service__heading, .l-service__solutions-right .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service__solutions-right .l-service-v3__heading, .l-service-v3__plans-right .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-v3__plans-right .l-service-v3__heading {
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .l-service__solutions-right .l-service__heading, .l-service-v3__plans-right .l-service__heading, .l-service__solutions-right .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service__solutions-right .l-service-v3__heading, .l-service-v3__plans-right .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-v3__plans-right .l-service-v3__heading {
    text-align: center;
  }
}

.l-service__solutions-right p, .l-service-v3__plans-right p {
  color: #D5D5D5;
  margin-bottom: 30px;
}

.l-service__solutions-right ul, .l-service-v3__plans-right ul {
  margin-bottom: 3em;
}

.l-service__solutions-right ul li:before, .l-service-v3__plans-right ul li:before {
  background: #d8d8d8;
}

@media screen and (max-width: 767px) {
  .l-service__solutions-right, .l-service-v3__plans-right {
    text-align: center;
    width: 100%;
  }
}

.l-service-v2__solutions-left, .l-service-v3__plans-left {
  width: 48%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.l-service-v2__solutions-left .l-service-v2__solutions-left-img, .l-service-v3__plans-left .l-service-v2__solutions-left-img {
  width: 100%;
  height: 110%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .l-service-v2__solutions-left .l-service-v2__solutions-left-img, .l-service-v3__plans-left .l-service-v2__solutions-left-img {
    height: 100%;
  }
}

.l-service-v2__solutions-left img, .l-service-v3__plans-left img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .l-service-v2__solutions-left, .l-service-v3__plans-left {
    width: 100%;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
  }
}

.l-service-v2__blurb {
  background: #202020;
  padding: 133px 0 90px;
}

.l-service-v2__blurb.pt-80 {
  padding-top: 80px;
}

@media screen and (max-width: 767px) {
  .l-service-v2__blurb {
    padding: 50px 0;
  }
}

.l-service-v2__blurb-left, .l-service-v2__blurb-right {
  width: 48%;
}

@media screen and (max-width: 767px) {
  .l-service-v2__blurb-left, .l-service-v2__blurb-right {
    width: 100%;
  }
}

.l-service-v2__blurb-left p {
  line-height: 1.9;
  color: #D5D5D5;
}

@media screen and (max-width: 767px) {
  .l-service-v2__blurb-left {
    text-align: center;
    margin-bottom: 2em;
  }
}

.l-service-v2__blurb-right {
  text-align: center;
}

.l-service-v2__blurb-right img {
  margin: 0 auto;
  max-width: 100%;
}

.l-service-v2__testimonies .l-container:before {
  display: none;
}

.l-service-v2__intro .btn-wrap {
  padding-top: 30px;
}

@media screen and (max-width: 767px) {
  .l-service-v2__intro .btn-wrap {
    text-align: center;
  }
}

.l-service-v2-3d-rendering__solutions .l-service-v2__solutions-left, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-left {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .l-service-v2-3d-rendering__solutions .l-service-v2__solutions-left, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-left {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    padding: 0 15px;
  }
  .l-service-v2-3d-rendering__solutions .l-service-v2__solutions-left .l-service-v2__solutions-left-img, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-left .l-service-v2__solutions-left-img {
    height: 500px;
  }
}

.l-service-v2-3d-rendering__solutions .l-service__solutions-right, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-right {
  width: 60%;
}

@media screen and (max-width: 767px) {
  .l-service-v2-3d-rendering__solutions .l-service__solutions-right, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-right {
    width: 100%;
    padding: 45px 0 0;
    text-align: center;
  }
  .l-service-v2-3d-rendering__solutions .l-service__solutions-right ul, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-right ul {
    text-align: center;
  }
  .l-service-v2-3d-rendering__solutions .l-service__solutions-right ul li, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-right ul li {
    padding: 0;
  }
  .l-service-v2-3d-rendering__solutions .l-service__solutions-right ul li:before, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-right ul li:before, .l-service-v2-3d-rendering__solutions .l-service__solutions-right ul li:after, .l-service-v2-3d-rendering__solutions .l-service-v3__plans-right ul li:after {
    display: none;
  }
}

.l-service-v2__content .btn-wrap {
  padding-top: 20px;
}

.l-service-v2-illustration__content {
  max-width: 760px;
  margin: 0 auto 80px;
}

.l-service-v2 .l-service__eyecatch .l-container {
  z-index: 1;
}

.l-service-v2 .l-service__eyecatch .l-container .l-service__eyecatch-left, .l-service-v2 .l-service__eyecatch .l-container .l-service-v3__eyecatch-content-upper {
  width: 100%;
  padding: 100px 0;
}

@media screen and (max-width: 767px) {
  .l-service-v2 .l-service__eyecatch .l-container .l-service__eyecatch-left, .l-service-v2 .l-service__eyecatch .l-container .l-service-v3__eyecatch-content-upper {
    padding: 50px 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-service-v2 .l-service__eyecatch {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-service-v2-service-photography .l-service__details .l-service__content {
    margin-bottom: 0;
  }
}

.l-service-v2-service-photography .l-service-v2__solutions .l-service__solutions-right, .l-service-v2-service-photography .l-service-v3__plans .l-service__solutions-right, .l-service-v2-service-photography .l-service-v2__solutions .l-service-v3__plans-right, .l-service-v2-service-photography .l-service-v3__plans .l-service-v3__plans-right {
  padding: 50px 0 0;
}

.l-service-3d-rendering .l-service__heading--small, .l-service-360-degree-product .l-service__heading--small {
  text-transform: uppercase;
  font-family: "Gilroy-ExtraBold";
}

.l-service-3d-rendering .l-service-v2__intro, .l-service-360-degree-product .l-service-v2__intro {
  background-color: #1D1D1D;
}

.l-service-3d-rendering .l-service-v2__intro .l-service__intro-content p, .l-service-360-degree-product .l-service-v2__intro .l-service__intro-content p {
  width: 50%;
  padding: 0 42px;
}

.l-service-3d-rendering .l-service-v2__intro .btn-wrap, .l-service-360-degree-product .l-service-v2__intro .btn-wrap {
  text-align: center;
}

@media (max-width: 767px) {
  .l-service-3d-rendering .l-service-v2__intro .l-service__intro-content, .l-service-360-degree-product .l-service-v2__intro .l-service__intro-content {
    display: block;
  }
  .l-service-3d-rendering .l-service-v2__intro .l-service__intro-content p, .l-service-360-degree-product .l-service-v2__intro .l-service__intro-content p {
    width: 100%;
  }
}

.l-service-3d-rendering .l-service__details, .l-service-360-degree-product .l-service__details {
  padding-top: 0;
}

.l-service-3d-rendering .l-service__details .l-service__heading, .l-service-360-degree-product .l-service__details .l-service__heading, .l-service-3d-rendering .l-service__details .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-3d-rendering .l-service__details .l-service-v3__heading, .l-service-360-degree-product .l-service__details .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-360-degree-product .l-service__details .l-service-v3__heading {
  margin-bottom: 0;
}

@media (max-width: 1366px) {
  .l-service-3d-rendering .l-service__details .detail-block .detail-block__image, .l-service-360-degree-product .l-service__details .detail-block .detail-block__image {
    min-height: 400px;
  }
}

@media (max-width: 1280px) {
  .l-service-3d-rendering .l-service__details .detail-block .detail-block__image, .l-service-360-degree-product .l-service__details .detail-block .detail-block__image {
    min-height: 425px;
  }
}

@media (max-width: 1024px) {
  .l-service-3d-rendering .l-service__details .detail-block .detail-block__image, .l-service-360-degree-product .l-service__details .detail-block .detail-block__image {
    min-height: 450px;
  }
}

.l-service-3d-rendering .section-what-we-do-tabs, .l-service-360-degree-product .section-what-we-do-tabs {
  padding: 4rem 0;
}

.l-service-3d-rendering .section-what-we-do-tabs .l-service__content, .l-service-360-degree-product .section-what-we-do-tabs .l-service__content,
.l-service-3d-rendering .section-what-we-do-tabs .l-service__heading--small,
.l-service-360-degree-product .section-what-we-do-tabs .l-service__heading--small {
  text-align: center;
}


.l-service-3d-rendering .section-what-we-do-tabs .l-service__heading--small,
.l-service-360-degree-product .section-what-we-do-tabs .l-service__heading--small {
  margin-bottom: 25px;
}

.l-service-3d-rendering .section-what-we-do-tabs .l-service__content, .l-service-360-degree-product .section-what-we-do-tabs .l-service__content {
  max-width: 778px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .l-service-3d-rendering .section-what-we-do-tabs .image-wrap, .l-service-360-degree-product .section-what-we-do-tabs .image-wrap {
    display: none;
  }
}

.what-we-do-tabs {
  position: relative;
  margin-top: 80px;
}

.what-we-do-tabs .image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 85%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.what-we-do-tabs .tab-listing {
  overflow: hidden;
}

.what-we-do-tabs .tab-listing ul.tabs {
  float: right;
  width: 60%;
  margin: 0px;
  padding: 80px 0 80px 40px;
  list-style: none;
}

@media (max-width: 767px) {
  .what-we-do-tabs .tab-listing ul.tabs {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-top: 0;
  }
}

.what-we-do-tabs .tab-listing ul.tabs li {
  color: #fff;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  font-family: "Gilroy-ExtraBold";
  font-size: 45px;
  position: relative;
  padding-left: 40px;
  margin-left: 0;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.what-we-do-tabs .tab-listing ul.tabs li span {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 22px;
  color: #4D4D4D;
}

@media (max-width: 767px) {
  .what-we-do-tabs .tab-listing ul.tabs li {
    font-size: 40px;
  }
}

.what-we-do-tabs .tab-listing ul.tabs li.current {
  color: #B22D2C;
  margin-left: -42px;
}

.what-we-do-tabs .tab-listing ul.tabs li.current span {
  color: #B22D2C;
}

@media (max-width: 767px) {
  .what-we-do-tabs .tab-listing ul.tabs li.current {
    margin-left: 0;
  }
}

.what-we-do-tabs .tab-listing .tab-content {
  display: none;
  padding: 50px;
  float: left;
  width: 100%;
  background-color: #232323;
}

.what-we-do-tabs .tab-listing .tab-content h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 26px;
}

.what-we-do-tabs .tab-listing .tab-content.current {
  display: inherit;
}

.section-what-we-do {
  background-color: #191919;
  text-align: center;
  padding: 4rem 0;
}

.section-what-we-do .l-service__heading, .section-what-we-do .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .section-what-we-do .l-service-v3__heading {
  font-size: 3rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.section-what-we-do .l-service__content {
  max-width: 895px;
  margin: 0 auto 30px;
}

.section-what-we-do .give-us-call {
  font-family: "Gilroy-ExtraBold";
  padding-top: 12px;
}

.section-what-we-do .give-us-call span {
  color: #717171;
  text-transform: uppercase;
}

.section-what-we-do .give-us-call p {
  text-transform: uppercase;
}

.section-what-we-do .give-us-call p a {
  color: #b12b2a;
}

.l-service-360-degree-product .l-service-v3__intro {
  padding: 80px 0;
}

.l-service-360-degree-product .l-service-v3__intro-listing {
  margin-top: 0;
}

.l-service-360-degree-product .l-service-v3__intro-listing li {
  width: 25%;
  padding: 0 32px;
  margin-bottom: 0;
}

.l-service-360-degree-product .l-service-v2__solutions-left .l-service-v2__solutions-left-img, .l-service-360-degree-product .l-service-v3__plans-left .l-service-v2__solutions-left-img {
  height: 100%;
}

.l-service-360-degree-product .l-service-v2__solutions, .l-service-360-degree-product .l-service-v3__plans {
  padding-bottom: 0;
  margin-bottom: 80px;
}

.l-service-360-degree-product .l-service-v2__intro h3 {
  padding-left: 42px;
  font-size: 26px;
  font-family: "Gilroy-ExtraBold";
  margin-bottom: 8px;
}

.l-service-information-architecture .l-service-v2__solutions, .l-service-information-architecture .l-service-v3__plans {
  margin-bottom: 10em;
  padding-bottom: 0;
}

.l-service-v2-crm .l-service__details .l-service-v2__content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.l-service-v2-crm .l-section-1 .l-section-left h1 {
  font-size: 45px;
}

.l-service-v2-crm .l-section-1 .l-section-right.img-right {
  width: 45%;
}

@media (max-width: 767px) {
  .l-service-v2-crm .l-section-1 .l-section-right.img-right {
    width: 100%;
  }
}

.l-service-v2-crm .l-service__intro.l-services-border-bottom, .l-service-v2-crm .l-services-border-bottom.l-service-v3__text-column {
  background-color: #1a1a1a;
}

@media screen and (max-width: 767px) {
  .l-service-v2-illustration {
    padding-bottom: 50px;
  }
}

.l-service-v2-illustration .section-video-list .detail-block {
  background-color: transparent;
}

.l-service-v2-service-photography .l-service__intro .l-service__intro-content.l-service__intro-content-full, .l-service-v2-service-photography .l-service-v3__text-column .l-service__intro-content.l-service__intro-content-full {
  font-size: 20px;
  text-align: center;
}

.l-service-v2-service-photography .l-service-v2__blurb {
  padding: 60px 0;
}

.l-service-v2.l-service-3d-rendering .banner-services, .l-service-v2.l-service-360-degree-product .banner-services {
  position: relative;
}

.l-service-v2.l-service-3d-rendering .banner-services:after, .l-service-v2.l-service-360-degree-product .banner-services:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.l-service-v2.l-service-3d-rendering .banner-services .l-container, .l-service-v2.l-service-360-degree-product .banner-services .l-container {
  position: relative;
  z-index: 2;
}

.l-service-v2.l-service-3d-rendering .section-what-we-do-tabs .tab-listing ul.tabs li, .l-service-v2.l-service-360-degree-product .section-what-we-do-tabs .tab-listing ul.tabs li {
  float: none;
  width: 100%;
  text-align: left;
}

.l-service-v2.l-service-3d-rendering .l-service__intro-content.u-flex, .l-service-v2.l-service-360-degree-product .l-service__intro-content.u-flex {
  margin-right: -42px;
  margin-left: -42px;
}

.l-service-v2.l-service-3d-rendering.l-service-virtual .section-page-intro h1, .l-service-v2.l-service-virtual.l-service-360-degree-product .section-page-intro h1 {
  font-size: 45px;
}

.l-service-v2.l-service-3d-rendering.l-service-virtual .l-service__details.section-vr, .l-service-v2.l-service-virtual.l-service-360-degree-product .l-service__details.section-vr {
  padding-top: 80px;
  padding-bottom: 80px;
}

.l-service-v2.l-service-3d-rendering.l-service-virtual .l-service__details.section-vr .detail-block, .l-service-v2.l-service-virtual.l-service-360-degree-product .l-service__details.section-vr .detail-block {
  background-color: transparent;
}

.l-service-v2.l-service-3d-rendering.l-service-virtual .l-service__details.section-vr .detail-block__text, .l-service-v2.l-service-virtual.l-service-360-degree-product .l-service__details.section-vr .detail-block__text {
  background-color: transparent;
}

.l-service.l-service-v2.l-service-v2-illustration .l-service-v2-illustration__content, .l-service-v2.l-service-v2-illustration .l-service-v2-illustration__content, .l-service-v2.l-service-v2-illustration.l-service-v3 .l-service-v2-illustration__content {
  font-size: 19px;
  font-family: "Gilroy-Bold", sans-serif;
  padding-top: 40px;
}

.l-service.l-service-v2.l-service-v2-illustration .video-bg, .l-service-v2.l-service-v2-illustration .video-bg, .l-service-v2.l-service-v2-illustration.l-service-v3 .video-bg {
  position: relative;
}

.l-service.l-service-v2.l-service-v2-illustration .l-service-v2-illustration__content, .l-service-v2.l-service-v2-illustration .l-service-v2-illustration__content, .l-service-v2.l-service-v2-illustration.l-service-v3 .l-service-v2-illustration__content {
  margin-top: -60px;
}

.l-service.l-service-v2.l-service-v2-illustration .l-service-v2__circle, .l-service-v2.l-service-v2-illustration .l-service-v2__circle, .l-service-v2.l-service-v2-illustration.l-service-v3 .l-service-v2__circle {
  left: auto;
  right: auto;
  bottom: -300px;
  z-index: 1;
}

.l-service.l-service-v2.l-service-v2-illustration .section-top-video-wrap > .l-container, .l-service-v2.l-service-v2-illustration .section-top-video-wrap > .l-container, .l-service-v2.l-service-v2-illustration.l-service-v3 .section-top-video-wrap > .l-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  max-width: 1200px;
}

.l-service.l-service-v2.l-service-v2-illustration .section-top-video-wrap .l-section-1 .l-section-left, .l-service-v2.l-service-v2-illustration .section-top-video-wrap .l-section-1 .l-section-left, .l-service-v2.l-service-v2-illustration.l-service-v3 .section-top-video-wrap .l-section-1 .l-section-left {
  width: 100%;
  padding-right: 0;
  margin: auto;
  text-align: center;
  padding-top: 0;
}

.l-contact {
  padding-bottom: 100px;
}

.l-contact__top {
  position: relative;
}

.l-contact__top:after {
  position: absolute;
  top: 16%;
  right: 0;
  display: block;
  width: 90px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.l-contact__top .description {
  text-align: center;
}

.l-contact__top .description p {
  font-size: 19px;
  line-height: 32px;
  color: #CFCFCF;
}

.l-contact__top .description p a {
  color: #d8d8d8;
  font-family: "Gilroy-ExtraBold";
}

.l-contact-form-contact {
  width: 100%;
  margin: 0 auto;
  max-width: 803px;
  margin-top: 4em;
  padding-bottom: 4em;
  padding-top: 1em;
}

.l-contact-form-contact:after {
  position: absolute;
  bottom: -15px;
  right: -15px;
  display: block;
  width: 37px;
  height: 37px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/contact/white-caret.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.l-contact-form-contact .btn-wrap {
  display: block;
  text-align: center;
  padding: 0 15px;
  margin-top: 3em;
}

@media (max-width: 767px) {
  .l-contact-form-contact .btn-wrap {
    margin-top: 2em;
  }
  .l-contact-form-contact .btn-wrap input {
    width: 100%;
  }
}

.l-contact-form-contact .button {
  padding: 25px 72px;
}

.l-contact-form-contact .ajax-loader {
  display: block !important;
  text-align: center;
  background-position: center center;
  margin: 0 auto !important;
}

@media (max-width: 767px) {
  .l-contact-form-contact {
    padding: 2em;
  }
}

.l-thank-you {
  padding-top: 10em;
  padding-bottom: 100px;
  position: relative;
}

.l-thank-you__top {
  position: relative;
  background: #1f1f20;
  padding: 3.3rem 2rem 3.5rem;
  text-align: center;
}

.l-thank-you__top h1 {
  margin-bottom: .6rem;
}

.l-thank-you__top svg {
  width: 600px;
  height: auto;
}

.l-thank-you__top .description {
  max-width: 877px;
  margin: auto;
}

.l-thank-you__top .description p {
  font-size: 20px;
  opacity: .8;
}

.l-thank-you__top:before {
  position: absolute;
  top: -20px;
  left: -20px;
  display: block;
  width: 33px;
  height: 33px;
  content: '';
  border-top: 5px solid #c4c4c4;
  border-left: 5px solid #c4c4c4;
}

.l-thank-you__top:after {
  position: absolute;
  right: -20px;
  bottom: -20px;
  display: block;
  width: 33px;
  height: 33px;
  content: '';
  border-right: 5px solid #b12b2a;
  border-bottom: 5px solid #b12b2a;
}

@media (max-width: 1024px) {
  .l-thank-you__top svg {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .l-thank-you__top .description {
    font-size: 17px;
  }
  .l-thank-you__top svg {
    width: 100%;
  }
  .l-thank-you__top .description p {
    font-size: 17px;
  }
  .l-thank-you__top {
    padding: 2rem 2.2rem 2rem;
  }
}

.l-our-works {
  padding: 100px 0 50px;
}

.l-our-works__top, .l-contact__top {
  position: relative;
  margin: 4em 0;
}

@media (max-width: 1280px) {
  .l-our-works__top, .l-contact__top {
    margin-bottom: 3em;
  }
}

@media (max-width: 1024px) {
  .l-our-works__top, .l-contact__top {
    margin-bottom: 2em;
  }
}

.l-our-works__top:after, .l-contact__top:after {
  position: absolute;
  top: -3%;
  right: 0;
  display: block;
  width: 90px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .l-our-works__top:after, .l-contact__top:after {
    right: 30px;
    width: 66px;
    height: 70px;
  }
}

.l-our-works__top h1, .l-contact__top h1 {
  font-family: "Gilroy-ExtraBold";
  font-size: 60px;
  text-align: center;
  color: #fff;
}

@media (max-width: 1280px) {
  .l-our-works__top h1, .l-contact__top h1 {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  .l-our-works__top h1, .l-contact__top h1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .l-our-works__top h1, .l-contact__top h1 {
    font-size: 30px;
  }
}

#cont_posters a.poster-item {
  position: relative;
}

#cont_posters a.poster-item .img-wrap img.img-main {
  opacity: .65;
  z-index: 1;
  position: absolute;
}

#cont_posters a.poster-item .img-wrap img.gradient {
  z-index: 2;
  opacity: 0;
  position: absolute;
  transition: all linear .2s;
}

#cont_posters a.poster-item .portfolio-content {
  z-index: 5;
  transform: translateY(-50%);
  top: 51%;
}

#cont_posters a.poster-item .portfolio-content img {
  display: block;
  max-width: 50%;
  max-height: 30%;
  margin: 15px auto;
  width: auto;
  transform: translateY(0%);
  margin-bottom: 20px;
}

#cont_posters a.poster-item .portfolio-content .offset-top-md {
  transform: translateY(-20%);
}

#cont_posters a.poster-item:hover .img-wrap img.gradient {
  opacity: .75;
  background: linear-gradient(90deg, #fb8631 0.01%, #97080c 100.02%);
}

#cont_posters a.poster-item:hover .portfolio-content {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

#cont_posters a.poster-item:hover .portfolio-content span {
  visibility: visible;
  opacity: 1;
}

#cont_posters a.poster-item.more-link .img-wrap img.img-main {
  opacity: 1;
}

#cont_posters a.poster-item.more-link .portfolio-content img {
  max-width: 160px;
  max-height: max-content;
}

@media (max-width: 767px) {
  #cont_posters a.poster-item .img-wrap img.gradient {
    top: .5rem;
    left: .5rem;
    display: block;
    width: calc( 100% - 1rem);
    height: calc( 100% - 1rem);
  }
}

.portfolio_thumbs {
  overflow: hidden;
  margin: 0 -15px 4em;
}

@media screen and (max-width: 767px) {
  .portfolio_thumbs {
    margin-bottom: 3em;
  }
}

.portfolio_img {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
  height: 284px;
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120%;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

@media (max-width: 1024px) {
  .portfolio_img {
    height: 244px;
  }
}

@media screen and (max-width: 767px) {
  .portfolio_img {
    height: 140px;
  }
}

.portfolio_img:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
  opacity: .25;
  background: #000;
}

.portfolio-content {
  position: absolute;
  z-index: 1;
  top: 55%;
  width: 100%;
  padding: 0 15px;
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
  -webkit-transform: translateY(-45%);
  -moz-transform: translateY(-45%);
  -ms-transform: translateY(-45%);
  -o-transform: translateY(-45%);
  transform: translateY(-45%);
  text-align: center;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .portfolio-content {
    top: 50%;
  }
}

.portfolio-content img {
  display: block;
  max-width: 200px;
  max-height: 100px;
  margin: 15px auto;
}

@media screen and (max-width: 767px) {
  .portfolio-content img {
    max-width: 100px;
    max-height: 60px;
    margin-bottom: 0;
  }
}

.portfolio-content span {
  font-family: "Gilroy-ExtraBold";
  font-size: 12px;
  visibility: hidden;
  width: 160px;
  margin: 0 auto;
  padding: 10px;
  -webkit-transition: all .35s ease-in-out 0s;
  -moz-transition: all .35s ease-in-out 0s;
  -ms-transition: all .35s ease-in-out 0s;
  -o-transition: all .35s ease-in-out 0s;
  transition: all .35s ease-in-out 0s;
  opacity: 0;
  border: 1px solid #fff;
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .portfolio-content span {
    display: none;
  }
}

.portfolio_item {
  position: relative;
  float: left;
  width: 33.333333%;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .portfolio_item {
    width: 50%;
  }
}

.portfolio_item:hover .portfolio_img {
  background-size: 130%;
}

.portfolio_item:hover .portfolio_img:before {
  opacity: .75;
  background: linear-gradient(90deg, #fb8631 0.01%, #97080c 100.02%);
}

.portfolio_item:hover .portfolio-content {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.portfolio_item:hover .portfolio-content span {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1024px) {
  .l-our-works__top, .l-contact__top,
  .l-contact__top {
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
  .l-our-works#page-wrap .l-container {
    width: 100%;
    padding: 0 17px;
  }
  .portfolio_item {
    padding: 3px;
  }
  .portfolio_img {
    height: 255px;
    background-size: cover;
  }
  .l-our-works {
    padding-bottom: 0;
  }
  .portfolio_thumbs {
    margin-bottom: 0;
  }
}

.l-about__heading {
  font-family: "Gilroy-ExtraBold";
  margin-bottom: 15px;
}

.l-about__eyecatch {
  padding: 0 0 250px;
}

@media screen and (max-width: 767px) {
  .l-about__eyecatch {
    padding: 80px 0 40px;
  }
}

.l-about__eyecatch-content {
  position: relative;
}

@media screen and (max-width: 1399px) {
  .l-about__eyecatch-content {
    width: 90%;
    padding-left: 7vw;
  }
}

@media screen and (max-width: 767px) {
  .l-about__eyecatch-content {
    width: 100%;
    padding-left: 0;
  }
}

.l-about__eyecatch-content:before {
  position: absolute;
  top: -60px;
  right: -30px;
  display: block;
  width: 86px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .l-about__eyecatch-content:before {
    right: 30px;
    width: 66px;
    height: 70px;
  }
}

.l-about__eyecatch-title {
  font-family: "Gilroy-ExtraBold";
  font-size: 45px;
  line-height: 1.2;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .l-about__eyecatch-title {
    font-size: 32px;
    width: 100%;
  }
}

.l-about__eyecatch-description p {
  line-height: 1.8;
  width: 46%;
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .l-about__eyecatch-description p {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.l-about__eyecatch-description.l-about__eyecatch-description-full p {
  width: 100%;
}

.l-about__intro {
  padding-bottom: 5rem;
  background-color: #1a1a1a;
}

@media screen and (max-width: 767px) {
  .l-about__intro {
    padding-bottom: 20px;
  }
}

.l-about__intro ul {
  margin-top: 1rem;
  counter-reset: process;
  align-items: initial;
}

@media screen and (max-width: 767px) {
  .l-about__intro ul {
    margin-top: 20px;
  }
}

.l-about__intro li {
  width: 20%;
  counter-increment: process;
}

@media screen and (max-width: 767px) {
  .l-about__intro li {
    width: 100%;
    margin-bottom: 40px;
  }
}

.l-about__intro-banner {
  width: 100%;
  height: 460px;
  margin-top: -200px;
  margin-bottom: 50px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .l-about__intro-banner {
    height: 290px;
    margin-top: 0;
  }
}

.l-about__values {
  position: relative;
  padding: 4.5rem 0;
}

@media screen and (max-width: 767px) {
  .l-about__values {
    padding: 70px 0 40px;
  }
}

.l-about__values ul {
  position: relative;
  z-index: 1;
  margin-top: 70px;
  margin-left: 160px;
  counter-reset: details;
  cursor: draggable;
}

@media screen and (max-width: 767px) {
  .l-about__values ul {
    margin-top: 150px;
    margin-left: 0;
  }
}

.l-about__values ul li {
  padding-right: 35px;
  counter-increment: details;
}

@media screen and (max-width: 767px) {
  .l-about__values ul li {
    padding-right: 15px;
  }
}

.l-about__values-arrows {
  position: absolute;
  top: 3rem;
  right: 190px;
  width: 140px;
}

@media screen and (max-width: 767px) {
  .l-about__values-arrows {
    top: 200px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.l-about__values-arrows-note {
  font-family: "Gilroy-ExtraBold";
  font-size: 14px;
  line-height: 5.5;
  position: relative;
  left: 11px;
  width: 82px;
  height: 82px;
  text-align: center;
  border: 3px dashed rgba(95, 95, 95, 0.5);
  border-radius: 50%;
}

.l-about__values-arrow {
  position: relative;
  cursor: pointer;
}

.l-about__values-arrow:before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 15px;
  content: '';
  transform: translate(-50%, -50%) rotate(-45deg);
  border-top: 2px solid #2e2e30;
  border-left: 2px solid #2e2e30;
}

.l-about__values-arrow:hover {
  opacity: 1;
}

.l-about__values-arrow.l-about__values-arrow--next {
  margin-left: 20px;
}

.l-about__values-arrow.l-about__values-arrow--next:before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.l-about__values-arrow.slick-disabled {
  opacity: .5;
}

.l-about__characteristics {
  padding: 0 0 100px;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics {
    padding: 60px 0;
  }
}

.l-about__characteristics-left {
  width: 45%;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-left {
    width: 100%;
    margin-bottom: 40px;
  }
}

.l-about__characteristics-left p {
  line-height: 1.8;
  margin-bottom: 25px;
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-left p {
    font-size: 15px;
  }
}

.l-about__characteristics-right {
  width: 42%;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-right {
    width: 100%;
  }
}

.l-about__characteristics-right ul li {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-right ul li {
    font-size: 16px;
    padding-left: 35px;
  }
}

.l-about__characteristics-right ul li:not(:last-child) {
  margin-bottom: 14px;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-right ul li:not(:last-child) {
    margin-bottom: 18px;
  }
}

.l-about__characteristics-right ul li:last-child {
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-right ul li:last-child {
    margin-bottom: 0;
  }
}

.l-about__characteristics-right ul li:before {
  position: absolute;
  top: 0;
  left: -15px;
  width: 28px;
  height: 28px;
  content: '';
  border-radius: 50%;
  background: linear-gradient(90deg, #fb8631 0%, #b12b2a 80%);
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-right ul li:before {
    left: 0;
    width: 23px;
    height: 23px;
  }
}

.l-about__characteristics-right ul li:after {
  position: absolute;
  top: 6px;
  left: -5px;
  width: 8px;
  height: 13px;
  content: '';
  transform: rotate(45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

@media screen and (max-width: 767px) {
  .l-about__characteristics-right ul li:after {
    top: 4px;
    left: 7px;
    width: 6px;
    height: 11px;
  }
}

.l-about__cta {
  margin-left: 50px;
  justify-content: initial;
}

@media screen and (max-width: 767px) {
  .l-about__cta {
    margin-left: 0;
  }
}

.l-about__cta-images {
  width: 50%;
  align-items: initial;
}

@media screen and (max-width: 767px) {
  .l-about__cta-images {
    width: 100%;
  }
}

.l-about__cta-images img {
  width: 100%;
  object-fit: cover;
}

.l-about__cta-images img:first-child {
  width: 47%;
  height: 287px;
}

@media screen and (max-width: 767px) {
  .l-about__cta-images img:first-child {
    height: 250px;
  }
}

.l-about__cta-images img:nth-child(2) {
  width: 49%;
  height: 193px;
}

@media screen and (max-width: 767px) {
  .l-about__cta-images img:nth-child(2) {
    height: 165px;
  }
}

.l-about__cta-images img:nth-child(3) {
  width: 47%;
  height: 240px;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .l-about__cta-images img:nth-child(3) {
    height: 210px;
    margin-top: 15px;
  }
}

.l-about__cta-images img:last-child {
  width: 49%;
  height: 329px;
  margin-top: -60px;
}

@media screen and (max-width: 767px) {
  .l-about__cta-images img:last-child {
    height: 293px;
    margin-top: -70px;
  }
}

.l-about__cta-content {
  width: 35%;
  margin-top: -20px;
  margin-left: 100px;
}

@media screen and (max-width: 767px) {
  .l-about__cta-content {
    width: 100%;
    margin-top: 30px;
    margin-left: 0;
    padding: 0 15px;
  }
}

.l-about__cta-content h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 38px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .l-about__cta-content h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

.l-about__cta-content p {
  line-height: 1.8;
  margin-bottom: 35px;
  color: #d5d5d5;
}

@media screen and (max-width: 767px) {
  .l-about__cta-content p {
    font-size: 15px;
    margin-bottom: 25px;
  }
}

.l-about .ms--images {
  position: relative;
  overflow: hidden;
}

.l-about .ms--images.ms-container--horizontal {
  width: 100%;
  height: 315px;
  max-width: 100%;
}

.l-about .ms--images.ms-container--horizontal .ms-track {
  left: calc(50% - 250px);
}

.l-about .ms--images.ms-container--horizontal .ms-slide {
  display: inline-flex;
}

.l-about .ms--images .ms-track {
  display: flex;
  position: absolute;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.l-about .ms--images .ms-slide {
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 315px;
  user-select: none;
}

.l-about .ms--images {
  left: 0;
}

.l-about .ms--images.ms-container--horizontal .ms-track {
  left: -70px;
}

.l-about .ms--images .ms-slide {
  padding-right: 50px;
}

.l-about .ms--images .ms-slide:nth-child(1) .ms-slide__image {
  background-image: url("/wp-content/themes/lightmedia/assets/images/about/about-img1.jpg");
}

.l-about .ms--images .ms-slide:nth-child(2) .ms-slide__image {
  background-image: url("/wp-content/themes/lightmedia/assets/images/about/about-img2.jpg");
}

.l-about .ms--images .ms-slide:nth-child(3) .ms-slide__image {
  background-image: url("/wp-content/themes/lightmedia/assets/images/about/about-img3.jpg");
}

.l-about .ms--images .ms-slide:nth-child(4) .ms-slide__image {
  background-image: url("/wp-content/themes/lightmedia/assets/images/about/about-img1.jpg");
}

.l-about .ms--images .ms-slide__image-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.l-about .ms--images .ms-slide__image {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.l-about .ms--numbers {
  position: relative;
  overflow: hidden;
}

.l-about .ms--numbers.ms-container--horizontal {
  width: 200px;
  height: 100px;
  max-width: 100%;
}

.l-about .ms--numbers.ms-container--horizontal .ms-track {
  left: calc(50% - 100px);
}

.l-about .ms--numbers.ms-container--horizontal .ms-slide {
  display: inline-flex;
}

.l-about .ms--numbers .ms-track {
  display: flex;
  position: absolute;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.l-about .ms--numbers .ms-slide {
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  user-select: none;
}

.l-about .ms--numbers {
  position: absolute;
  left: -3%;
  top: 18%;
  z-index: 1;
  pointer-events: none;
}

.l-about .ms--numbers .ms-slide {
  font-size: 120px;
  font-family: "Gilroy-ExtraBold";
  color: #303030;
}

.l-about .ms--titles {
  position: relative;
  overflow: hidden;
}

.l-about .ms--titles.ms-container--vertical {
  width: 400px;
  height: 170px;
  max-height: 100%;
}

.l-about .ms--titles.ms-container--vertical .ms-track {
  flex-direction: column;
  top: calc(50% - 85px);
}

.l-about .ms--titles.ms-container--vertical.ms-container--reverse .ms-track {
  flex-direction: column-reverse;
  top: auto;
  bottom: calc(50% - 85px);
}

.l-about .ms--titles.ms-container--vertical .ms-slide {
  display: flex;
}

.l-about .ms--titles .ms-track {
  display: flex;
  position: absolute;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.l-about .ms--titles .ms-slide {
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 170px;
  user-select: none;
}

.l-about .ms--description {
  position: relative;
  overflow: hidden;
}

.l-about .ms--description.ms-container--vertical {
  width: 400px;
  height: 170px;
  max-height: 100%;
}

.l-about .ms--description.ms-container--vertical .ms-track {
  flex-direction: column;
  top: calc(50% - 85px);
}

.l-about .ms--description.ms-container--vertical.ms-container--reverse .ms-track {
  flex-direction: column-reverse;
  top: auto;
  bottom: calc(50% - 85px);
}

.l-about .ms--description.ms-container--vertical .ms-slide {
  display: flex;
}

.l-about .ms--description .ms-track {
  display: flex;
  position: absolute;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.l-about .ms--description .ms-slide {
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 170px;
  user-select: none;
}

.l-about .ms--titles, .l-about .ms--description {
  position: absolute;
  left: -3%;
  top: calc(50% - 85px);
  z-index: 2;
  pointer-events: none;
}

.l-about .ms--titles .ms-track, .l-about .ms--description .ms-track {
  white-space: normal;
}

.l-about .ms--titles .ms-slide h3, .l-about .ms--description .ms-slide h3 {
  margin: 0;
  font-size: 40px;
  font-family: "Gilroy-ExtraBold";
  line-height: 1;
}

.l-about .ms--description {
  bottom: 0;
  top: auto;
  left: 0;
}

.l-about .ms--description.ms-container--vertical {
  width: 415px;
  height: 216px;
}

.l-about .ms--description p {
  font-size: 16px;
  color: #D4D4D4;
  font-family: "Gilroy-Regular", sans-serif;
}

.l-about .ms--description .ms-track {
  width: 100%;
}

.l-about .ms--description .ms-track .ms-slide {
  width: 100%;
}

.l-about .ms--links {
  position: relative;
  overflow: hidden;
}

.l-about .ms--links.ms-container--vertical {
  width: 120px;
  height: 60px;
  max-height: 100%;
}

.l-about .ms--links.ms-container--vertical .ms-track {
  flex-direction: column;
  top: calc(50% - 30px);
}

.l-about .ms--links.ms-container--vertical .ms-slide {
  display: flex;
}

.l-about .ms--links .ms-track {
  display: flex;
  position: absolute;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.l-about .ms--links .ms-slide {
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 60px;
  user-select: none;
}

@media screen and (max-width: 860px) {
  .l-about .ms--numbers {
    left: calc(50% - 100px);
  }
  .l-about .ms--titles, .l-about .ms--description {
    left: calc(50% - 200px);
    top: calc(50% - 135px);
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .l-about .ms--images {
    overflow: visible;
  }
}

@media screen and (max-width: 400px) {
  .l-about .ms--titles .ms-slide, .l-about .ms--description .ms-slide {
    transform: scale(0.8);
  }
}

.l-about .container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 650px;
  max-width: 1200px;
  margin-top: -5rem;
}

.l-about .sliders-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 200%;
}

.l-about .sliders-container .pagination {
  display: none;
}

.l-about .about-slider {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .l-about__eyecatch {
    padding: 0;
    margin-bottom: 1rem;
  }
  .l-about__intro-banner {
    margin: 0;
  }
  .l-about__eyecatch-title {
    margin-bottom: 1rem;
  }
  .l-about__intro {
    background: transparent;
    padding-bottom: 2rem;
  }
}

.l-pages-wrapper {
  padding-top: 85px;
}

.l-pages-wrapper-l2 {
  padding-top: 130px;
}

.l-services-hero-l2 {
  text-align: center;
  padding: 3rem 0 2rem;
  color: #D4D4D4;
}

.l-services-hero-l2 h1 {
  color: #fff;
  font-family: "Gilroy-ExtraBold";
  font-size: 3.5rem;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-services-hero-l2 h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .l-services-hero-l2 h1 {
    font-size: 30px;
  }
}

.l-service-v3 {
  padding-top: 100px;
}

.l-service-v3__eyecatch {
  overflow: hidden;
  padding-top: 2rem;
}

.l-service-v3__eyecatch-content-upper {
  width: 100%;
  min-height: 100%;
  text-align: center;
}

.l-service-v3__eyecatch-content-upper p {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.l-service-v3__eyecatch-content-lower {
  width: 725px;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-service-v3__eyecatch-content-lower {
    width: 100%;
  }
}

.l-service-v3__eyecatch-content-lower .l-service-v3__eyecatch-image {
  max-width: 725px;
  margin: 0 auto;
  height: auto;
  width: auto;
}

@media screen and (max-width: 767px) {
  .l-service-v3__eyecatch-content-lower .l-service-v3__eyecatch-image {
    max-width: initial;
    width: 100%;
  }
}

.l-service-v3__eyecatch-list {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.l-service-v3__eyecatch-list li {
  background-color: #d8d8d8;
  border-radius: 13px;
  position: absolute;
  padding: 18px;
}

.l-service-v3__eyecatch-list li span {
  display: block;
}

.l-service-v3__eyecatch-list li .l-service-v3__eyecatch-list-icon {
  width: 56px;
  height: 56px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #0E0E11;
  position: absolute;
  top: 50%;
  left: 18px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-service-v3__eyecatch-list li .l-service-v3__eyecatch-list-icon img {
  max-width: 100%;
  height: auto;
}

.l-service-v3__eyecatch-list li .l-service-v3__eyecatch-list-text {
  font-family: "Gilroy-ExtraBold";
  font-size: 20px;
  color: #0E0E11;
  line-height: 1.3;
  padding-left: 70px;
  text-align: left;
}

.l-service-v3__eyecatch-list li.l-service-v3__eyecatch-list-item1 {
  bottom: 30%;
  left: -9%;
}

.l-service-v3__eyecatch-list li.l-service-v3__eyecatch-list-item2 {
  top: 18%;
  left: -24%;
}

.l-service-v3__eyecatch-list li.l-service-v3__eyecatch-list-item3 {
  top: 12%;
  right: -9%;
}

.l-service-v3__eyecatch-list li.l-service-v3__eyecatch-list-item4 {
  bottom: 38%;
  right: -18%;
}

.l-service-v3__cms {
  background-color: #1a1a1a;
  padding: 3.8rem 0;
}

.l-service-v3__cms .l-service__intro-content {
  text-align: center;
}

.l-service-v3__cms .l-service__intro-content h2 {
  font-size: 44px;
  margin-bottom: 25px;
  font-family: "Gilroy-ExtraBold";
}

@media (max-width: 1280px) {
  .l-service-v3__cms .l-service__intro-content {
    padding: 0 75px;
  }
}

@media screen and (max-width: 767px) {
  .l-service-v3__cms .l-service__intro-content {
    padding: 0;
  }
}

.l-service-v3__cms-list {
  text-align: center;
}

.l-service-v3__cms-list li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
}

.l-service-v3__intro .l-service__intro-content-full {
  text-align: center;
  border-bottom: 1px solid #282828;
  padding: 30px 0 80px;
  margin-bottom: 50px;
}

.l-service-v3__intro.centered {
  text-align: center;
}

.l-service-v3__intro.centered ul {
  margin-right: -25px;
  margin-left: -25px;
}

.l-service-v3__intro.centered ul li {
  padding-right: 25px;
  padding-left: 25px;
}

.l-service-v3__intro.centered ul li .l-service-v3__intro-block {
  margin-left: 0;
}

.l-service-v3__intro.centered .l-service-v3__intro-block {
  margin-left: 0;
  text-align: center;
}

.l-service-v3__intro.centered .l-service-v3__intro-block .l-service-v3__intro-block__icon {
  margin: auto;
  margin-bottom: 15px;
}

.l-service-v3__intro-top {
  border-bottom: 1px solid #282828;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.l-service-v3__intro-top h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 40px;
  width: 34%;
}

.l-service-v3__intro-top p {
  width: 53%;
  margin-right: 0;
}

.l-service-v3__intro-top.l-service-v3__intro-top-full {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}

.l-service-v3__intro-top.l-service-v3__intro-top-full h2, .l-service-v3__intro-top.l-service-v3__intro-top-full p {
  width: 100%;
}

.l-service-v3__intro-top.text-left {
  text-align: left;
}

.l-service-v3__intro-top.text-left p {
  margin-bottom: 15px;
}

.l-service-v3__intro-top.l-service-v3__intro-top-no-width {
  max-width: none;
}

.l-service-v3__intro-top.border-b-none {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.l-service-v3__intro-listing li {
  width: 33.333%;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .l-service-v3__intro-listing li {
    width: 100%;
  }
}

.l-service-v3__intro-block__icon:before, .l-service-v3__intro-block__icon:after {
  display: none;
}

.l-service-v3__intro-block__title {
  font-size: 26px;
}

.l-service-v3__plans {
  margin-bottom: 80px;
}

.l-service-v3__plans.l-service-v3-copywriting__plans .l-service-v2__solutions-left, .l-service-v3__plans.l-service-v3-copywriting__plans .l-service-v3__plans-left {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .l-service-v3__plans.l-service-v3-copywriting__plans .l-service-v2__solutions-left, .l-service-v3__plans.l-service-v3-copywriting__plans .l-service-v3__plans-left {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
}

.l-service-v3__plans.l-service-v3-copywriting__plans .l-service-v3__plans-right {
  width: 60%;
}

@media screen and (max-width: 767px) {
  .l-service-v3__plans.l-service-v3-copywriting__plans .l-service-v3__plans-right {
    width: 100%;
  }
}

.l-service-v3__plans.pb-0 {
  padding-bottom: 0;
}

.l-service-v3__plans.mb-0 {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-service-v3__plans {
    margin-bottom: 0;
  }
}

.l-service-v3__plans-left-img {
  height: 100%;
  width: 100%;
}

.l-service-v3__plans-right {
  padding: 80px 50px;
}

@media screen and (max-width: 767px) {
  .l-service-v3__plans-right {
    padding: 50px 0;
    width: 100%;
  }
}

.l-service-v3__plans-right.l-service-v3__plans-right--video {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .l-service-v3__plans-right.l-service-v3__plans-right--video {
    padding: 50px 0;
  }
}

.l-service-v3__plans-left-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.l-service-v3__plans-left-img-left, .l-service-v3__plans-left-img-right {
  width: 50%;
  height: 100%;
  overflow: hidden;
  float: left;
}

.l-service-v3__plans-left-img-item1, .l-service-v3__plans-left-img-item2 {
  width: 100%;
  padding: 15px;
}

.l-service-v3__plans-left-img-item1 {
  height: 60%;
}

.l-service-v3__plans-left-img-item2 {
  height: 40%;
}

.l-service-v3__plans-left-img-right .l-service-v3__plans-left-img-item1 {
  height: 40%;
}

.l-service-v3__plans-left-img-right .l-service-v3__plans-left-img-item2 {
  height: 60%;
}

.l-service-v3__text-column {
  background-color: #1D1D1D;
  padding: 80px 0 8em;
  margin-bottom: 0;
}

.l-service-v3__call-to-action {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding: 80px 0;
  max-width: 1314px;
  margin: 4em auto -18em;
}

.l-service-v3__call-to-action .description {
  max-width: 888px;
  margin: 0 auto 25px;
}

.l-service-v3__call-to-action .l-service-v3__heading {
  font-size: 50px;
  line-height: 1;
}

.l-service-v3__testimonies {
  padding-top: 14em;
}

.l-service-v3__testimonies .l-container:before {
  display: none;
}

.l-service-v3__testimonies.pt-100 {
  padding-top: 100px;
}

.pb-80 {
  padding-bottom: 80px;
}

.l-service-ecommerce .l-service-v3__plans,
.l-service-v3-social-media .l-service-v3__plans,
.l-service-v3-video .l-service-v3__plans,
.l-service-v3-web-application .l-service-v3__plans {
  margin: 80px 0;
  padding: 0;
}

.l-service-ecommerce .l-service-v3__intro,
.l-service-v3-social-media .l-service-v3__intro,
.l-service-v3-video .l-service-v3__intro,
.l-service-v3-web-application .l-service-v3__intro {
  padding: 0 0 80px;
}

.l-service-ecommerce .l-service-v3__intro .btn-wrap,
.l-service-v3-social-media .l-service-v3__intro .btn-wrap,
.l-service-v3-video .l-service-v3__intro .btn-wrap,
.l-service-v3-web-application .l-service-v3__intro .btn-wrap {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .l-service-v3-social-media .l-service-v3__plans .l-service-v3__plans-right,
  .l-service-v3-web-application .l-service-v3__plans .l-service-v3__plans-right {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-service-v3-social-media .l-service-v3__text-column,
  .l-service-v3-web-application .l-service-v3__text-column {
    text-align: center;
  }
}

.l-service-v3-social-media .l-service-v3__cms .l-service-v3__cms {
  padding: 80px 0;
}

.l-service-v3-social-media .l-service-v3__cms .l-service__intro-content-full .description {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.l-service-ecommerce .l-service-v3__cms {
  padding: 80px 0;
}

.l-service-ecommerce .l-service-v3__cms .l-service__intro-content-full {
  max-width: 945px;
  margin-left: auto;
  margin-right: auto;
}

.l-service-ecommerce .l-services-ecommerce-management-systems .l-service__heading, .l-service-ecommerce .l-services-ecommerce-management-systems .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-ecommerce .l-services-ecommerce-management-systems .l-service-v3__heading {
  margin-bottom: 1em;
}

.l-service-ecommerce .l-services-ecommerce-management-systems .l-service-v3__intro-listing {
  align-items: normal;
  margin-left: -30px;
  margin-right: -30px;
}

.l-service-ecommerce .l-services-ecommerce-management-systems .l-service-v3__intro-listing li {
  padding: 0 30px;
  text-align: left;
}

.l-service-ecommerce .l-services-ecommerce-management-systems .l-service-v3__intro-listing li .l-service-v3__intro-block {
  margin-left: 0;
}

.l-service-ecommerce .l-services-ecommerce-management-systems .l-service-v3__intro-listing li .l-service-v3__intro-block .l-service-v3__intro-block__icon {
  height: 120px;
  width: 120px;
}

.l-service-v3-event .l-service-v3__text-column {
  margin-bottom: 0;
  background-color: #252525;
  padding: 3.4rem 0 3.5rem;
}

.l-service-v3-copywriting .l-service-v3__intro {
  padding-bottom: 0;
}

.l-service-v3-copywriting .l-service-v3__intro .l-service-v3__intro-listing {
  align-items: normal;
}

.l-service-v3-copywriting .l-service-v3__intro .l-service-v3__intro-listing .l-service-v3__intro-block {
  margin-left: 0;
}

.l-service-v3-copywriting .l-service-v3__cms {
  padding-bottom: 0;
}

.l-service-v3-copywriting .l-service-v3__cms .l-service__intro-content-full .description {
  max-width: 1065px;
  margin-left: auto;
  margin-right: auto;
}

.l-service-v3-copywriting .l-service__eyecatch .l-service__eyecatch-left, .l-service-v3-copywriting .l-service__eyecatch .l-service-v3__eyecatch-content-upper {
  position: relative;
  z-index: 1;
}

.l-service-v3-copywriting .l-service__eyecatch .l-service__eyecatch-left .content, .l-service-v3-copywriting .l-service__eyecatch .l-service-v3__eyecatch-content-upper .content {
  position: relative;
  z-index: 1;
}

.l-service-v3-copywriting .l-service__eyecatch .l-service__eyecatch-left .l-service-v2__circle, .l-service-v3-copywriting .l-service__eyecatch .l-service-v3__eyecatch-content-upper .l-service-v2__circle {
  position: absolute;
  top: 50%;
  left: 41%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .l-service-v3-copywriting .l-service__eyecatch .l-service__eyecatch-left, .l-service-v3-copywriting .l-service__eyecatch .l-service-v3__eyecatch-content-upper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .l-service-v3-copywriting .l-service__eyecatch .l-service__eyecatch-left .l-service-v2__circle, .l-service-v3-copywriting .l-service__eyecatch .l-service-v3__eyecatch-content-upper .l-service-v2__circle {
    left: 50%;
  }
}

.l-service-v3.l-service-v3-web-application .l-service-v3__eyecatch .l-service-v3__eyecatch-content-lower {
  width: 300px;
}

.l-service-v3.l-service-v3-web-application .l-service-v3__eyecatch .l-service-v3__eyecatch-content-lower .l-service-v3__eyecatch-image {
  max-width: 300px;
}

.l-service-v3.l-service-v3-web-application .l-service-v3__eyecatch, .l-service-v3.l-service-v3-social-media .l-service-v3__eyecatch, .l-service-v3.l-service-ecommerce .l-service-v3__eyecatch, .l-service-v3.l-service-v3-video .l-service-v3__eyecatch, .l-service-v3.l-service-v3-video .l-service-v3__eyecatch, .l-service-v3.l-service-v3-copywriting .l-service-v3__eyecatch {
  text-align: center;
}

.l-service-v3.l-service-v3-web-application .l-service-v3__eyecatch img, .l-service-v3.l-service-v3-social-media .l-service-v3__eyecatch img, .l-service-v3.l-service-ecommerce .l-service-v3__eyecatch img, .l-service-v3.l-service-v3-video .l-service-v3__eyecatch img, .l-service-v3.l-service-v3-video .l-service-v3__eyecatch img, .l-service-v3.l-service-v3-copywriting .l-service-v3__eyecatch img {
  margin-bottom: 60px;
}

.l-service-v3.l-service-v3-web-application .l-service-v3__eyecatch .button, .l-service-v3.l-service-v3-social-media .l-service-v3__eyecatch .button, .l-service-v3.l-service-ecommerce .l-service-v3__eyecatch .button, .l-service-v3.l-service-v3-video .l-service-v3__eyecatch .button, .l-service-v3.l-service-v3-video .l-service-v3__eyecatch .button, .l-service-v3.l-service-v3-copywriting .l-service-v3__eyecatch .button {
  margin: auto;
  margin-bottom: 60px;
}

.l-service.l-service-v3.l-service-v3-copywriting .section-vr .detail-block__text, .l-service-v3.l-service-v3-copywriting.l-service-v2 .section-vr .detail-block__text, .l-service-v3.l-service-v3-copywriting .section-vr .detail-block__text {
  margin: auto;
}

.l-service-v3.l-service-v3-event .l-service-v3__eyecatch {
  padding-top: 60px;
  padding-bottom: 100px;
}

.l-service-v3.l-service-v3-event .l-service-v3__eyecatch img {
  width: 460px;
  max-width: 95%;
  margin-bottom: 40px;
}

.l-service-v3.l-service-v3-event .l-service-v3__eyecatch .button {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .l-service-v3-video .l-service__details .detail-block .detail-block__image {
    margin-bottom: 2em;
  }
}

@media (max-width: 767px) {
  .l-service-v3-video .l-service__details .detail-block .detail-block__text {
    text-align: left;
  }
  .l-service-v3-video .l-service__details .detail-block .detail-block__text h2 {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .l-service-v3-video .l-service__details {
    padding-bottom: 50px;
  }
}

.l-privacy {
  padding: 100px 0 50px;
}

@media screen and (max-width: 767px) {
  .l-privacy {
    padding: 80px 0 30px;
  }
}

.l-privacy__top {
  position: relative;
  margin-bottom: 4em;
}

@media screen and (max-width: 767px) {
  .l-privacy__top {
    margin-bottom: 3em;
  }
}

.l-privacy__top:after {
  position: absolute;
  top: -3%;
  right: 0;
  display: block;
  width: 90px;
  height: 100px;
  content: '';
  background-image: url(/wp-content/themes/lightmedia/assets/images/index/dots.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .l-privacy__top:after {
    right: 30px;
    width: 66px;
    height: 70px;
  }
}

.l-privacy__top h1 {
  font-family: "Gilroy-ExtraBold";
  font-size: 70px;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .l-privacy__top h1 {
    font-size: 40px;
  }
}

.l-privacy__content {
  width: 1024px;
  margin: 70px auto 90px;
}

@media screen and (max-width: 767px) {
  .l-privacy__content {
    width: 100%;
    margin: 0;
  }
}

.l-privacy__content p {
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .l-privacy__content p {
    font-size: 15px;
  }
}

.l-privacy__content p:not(:last-child) {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .l-privacy__content p:not(:last-child) {
    margin-bottom: 20px;
  }
}

.l-3d-rendering-portfolio .section-what-we-do .l-container {
  margin-top: -60px;
}

.l-3d-rendering-portfolio .l-service__eyecatch {
  min-height: 0;
  padding: 80px 0;
}

.l-3d-rendering-portfolio .l-service__eyecatch .l-service__eyecatch-content {
  text-align: center;
}

.l-3d-rendering-portfolio .l-service__eyecatch .l-service__eyecatch-content h1 {
  font-size: 55px;
  font-family: "Gilroy-ExtraBold";
}

@media (max-width: 991px) {
  .l-service-printing .l-section-1 {
    display: block;
  }
}

.l-service-printing .l-section-1 .l-section-left {
  width: 50%;
  line-height: 1;
}

.l-service-printing .l-section-1 .l-section-left h1 {
  font-size: 48px;
}

.l-service-printing .l-section-1 .l-section-left .description {
  line-height: 1.7;
}

@media (max-width: 991px) {
  .l-service-printing .l-section-1 .l-section-left {
    width: 100%;
  }
}

.l-service-printing .l-section-1 .l-section-right {
  width: 50%;
  position: relative;
}

.l-service-printing .l-section-1 .l-section-right .l-circle {
  position: absolute;
  height: 511px;
  width: 511px;
  -webkit-border-radius: 600px;
  -moz-border-radius: 600px;
  border-radius: 600px;
  border: 2px dashed #353535;
  background-color: transparent;
  z-index: 0;
  top: 50%;
  right: -30%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.l-service-printing .l-section-1 .l-section-right .l-circle .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 353px;
  width: 353px;
  -webkit-border-radius: 600px;
  -moz-border-radius: 600px;
  border-radius: 600px;
  background-color: #252525;
  z-index: 1;
}

@media (max-width: 991px) {
  .l-service-printing .l-section-1 .l-section-right .l-circle {
    display: none;
  }
}

.l-service-printing .l-section-1 .l-section-right .img-wrap {
  position: relative;
  padding-bottom: 70px;
  margin-top: 15px;
  z-index: 1;
  overflow: hidden;
}

.l-service-printing .l-section-1 .l-section-right .img, .l-service-printing .l-section-1 .l-section-right .bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.l-service-printing .l-section-1 .l-section-right .img1 {
  width: 70%;
  height: 400px;
  float: left;
}

.l-service-printing .l-section-1 .l-section-right .img2 {
  width: 30%;
  height: 50%;
  float: left;
  padding: 0 0 15px 15px;
}

.l-service-printing .l-section-1 .l-section-right .img2 .bg {
  width: 100%;
  height: 195px;
}

.l-service-printing .l-section-1 .l-section-right .img3 {
  width: 70%;
  height: 260px;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 10px solid #0E0E11;
}

@media (max-width: 991px) {
  .l-service-printing .l-section-1 .l-section-right {
    width: 100%;
    margin-bottom: 80px;
  }
}

.l-service-printing .section-what-we-offer {
  background-color: #252525;
  padding: 80px 0 0;
  border-bottom: 1px solid #252525;
}

.l-service-printing .section-what-we-offer .small-title,
.l-service-printing .section-what-we-offer .section-title {
  font-family: "Gilroy-ExtraBold";
  text-align: center;
  font-weight: 800;
}

.l-service-printing .section-what-we-offer .small-title {
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 3px;
}

.l-service-printing .section-what-we-offer .section-title {
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  margin-bottom: 40px;
}

.l-service-printing .section-what-we-offer .btn-wrap {
  text-align: center;
  margin-top: 40px;
}

.l-service-printing .tab-wrap .nav-tabs {
  border: none;
  text-align: center;
}

.l-service-printing .tab-wrap .nav-tabs li {
  width: 33%;
  vertical-align: top;
  display: inline-block;
  float: none;
  padding: 15px;
  text-align: center;
  position: relative;
}

.l-service-printing .tab-wrap .nav-tabs li a {
  font-family: "Gilroy-ExtraBold";
  font-size: 22px;
  font-weight: 800;
  background: #171717;
  color: #fff !important;
  border: none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 46px 45px;
  margin: 0;
}

.l-service-printing .tab-wrap .nav-tabs li a .icon {
  width: 80px;
  height: 80px;
  background-image: url("../images/printing/tab-icon1-gray.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto 15px;
}

@media (max-width: 1024px) {
  .l-service-printing .tab-wrap .nav-tabs li a .icon {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 991px) {
  .l-service-printing .tab-wrap .nav-tabs li a .icon {
    width: 60px;
    height: 60px;
  }
}

.l-service-printing .tab-wrap .nav-tabs li a .icon2 {
  background-image: url("../images/printing/tab-icon2-gray.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a .icon3 {
  background-image: url("../images/printing/tab-icon3-gray.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a .icon4 {
  background-image: url("../images/printing/tab-icon4-gray.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a .icon5 {
  background-image: url("../images/printing/tab-icon5-gray.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a:hover {
  color: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);
}

.l-service-printing .tab-wrap .nav-tabs li a:hover .icon {
  background-image: url("../images/printing/tab-icon1.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a:hover .icon2 {
  background-image: url("../images/printing/tab-icon2.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a:hover .icon3 {
  background-image: url("../images/printing/tab-icon3.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a:hover .icon4 {
  background-image: url("../images/printing/tab-icon4.svg");
}

.l-service-printing .tab-wrap .nav-tabs li a:hover .icon5 {
  background-image: url("../images/printing/tab-icon5.svg");
}

@media (max-width: 1366px) {
  .l-service-printing .tab-wrap .nav-tabs li a {
    padding: 46px 33px;
  }
}

@media (max-width: 1280px) {
  .l-service-printing .tab-wrap .nav-tabs li a {
    font-size: 20px;
  }
}

@media (max-width: 1199px) {
  .l-service-printing .tab-wrap .nav-tabs li a {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .l-service-printing .tab-wrap .nav-tabs li a {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .l-service-printing .tab-wrap .nav-tabs li a {
    padding: 30px 15px;
  }
}

.l-service-printing .tab-wrap .nav-tabs li.active a {
  background: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);
}

.l-service-printing .tab-wrap .nav-tabs li.active a .icon {
  background-image: url("../images/printing/tab-icon1.svg");
}

.l-service-printing .tab-wrap .nav-tabs li.active a .icon2 {
  background-image: url("../images/printing/tab-icon2.svg");
}

.l-service-printing .tab-wrap .nav-tabs li.active a .icon3 {
  background-image: url("../images/printing/tab-icon3.svg");
}

@media (max-width: 1024px) {
  .l-service-printing .tab-wrap .nav-tabs li {
    width: 30%;
  }
}

@media (max-width: 991px) {
  .l-service-printing .tab-wrap .nav-tabs li {
    width: 48%;
  }
}

.l-service-printing .tab-wrap .tab-content {
  background-color: #000;
  padding: 80px 0;
  margin-top: 80px;
}

.l-service-printing .tab-wrap .tab-content h3 {
  font-size: 36px;
  margin-bottom: 40px;
}

.l-service-printing .listing {
  overflow: hidden;
}

.l-service-printing .listing-item {
  width: 50%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.l-service-printing .listing-item.listing-item-reverse {
  flex-direction: row-reverse;
}

@media (max-width: 1024px) {
  .l-service-printing .listing-item.listing-item-reverse {
    flex-direction: inherit;
  }
}

@media (max-width: 1024px) {
  .l-service-printing .listing-item {
    width: 100%;
    float: none;
    flex-direction: inherit;
  }
  .l-service-printing .listing-item:nth-child(2n) {
    flex-direction: row-reverse;
  }
}

@media (max-width: 767px) {
  .l-service-printing .listing-item {
    flex-direction: row-reverse;
  }
}

.l-service-printing .listing-item-text, .l-service-printing .listing-item-img {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  height: 335px;
}

@media (max-width: 1366px) {
  .l-service-printing .listing-item-text, .l-service-printing .listing-item-img {
    height: 360px;
  }
}

@media (max-width: 1280px) {
  .l-service-printing .listing-item-text, .l-service-printing .listing-item-img {
    height: 397px;
  }
}

@media (max-width: 640px) {
  .l-service-printing .listing-item-text, .l-service-printing .listing-item-img {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.l-service-printing .listing-item-text {
  background-color: #262628;
  text-align: center;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.l-service-printing .listing-item-text .content {
  padding: 32px;
}

.l-service-printing .listing-item-text .description {
  margin-bottom: 0;
}

.l-service-printing .listing-item-text p {
  font-size: 15px;
}

.l-service-printing .listing-item-text h4, .l-service-printing .listing-item-text p {
  color: #f2f2f2;
}

.l-service-printing .listing-item-text h4 {
  font-family: "Gilroy-ExtraBold";
  font-size: 23px;
  margin-bottom: 5px;
}

@media (max-width: 640px) {
  .l-service-printing .listing-item-text {
    display: block;
    height: auto;
  }
}

.l-service-printing .listing-item-img {
  background-size: cover;
  background-position: center center;
}

@media (max-width: 640px) {
  .l-service-printing .listing-item-img {
    height: 275px;
  }
}

.section-print-materials {
  background-color: #252525;
  padding: 80px 0;
}

.section-print-materials .listing {
  text-align: center;
}

.section-print-materials .listing .listing-item {
  display: inline-block;
  vertical-align: top;
  width: 370px;
  margin: 15px;
  text-align: center;
  position: relative;
  font-family: "Gilroy-ExtraBold";
  font-size: 27px;
  font-weight: 800;
  background: #000000;
  color: #fff !important;
  border: none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 46px;
  float: none;
}

.section-print-materials .listing .listing-item .icon {
  width: 102px;
  height: 102px;
  background-image: url("../images/printing/sales-add-img1-gray.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto 15px;
}

.section-print-materials .listing .listing-item.listing-item2 .icon {
  background-image: url("../images/printing/sales-add-img2-gray.svg");
}

.section-print-materials .listing .listing-item:hover {
  background: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);
}

.section-print-materials .listing .listing-item:hover .icon {
  background-image: url("../images/printing/sales-add-img1.svg");
}

.section-print-materials .listing .listing-item:hover.listing-item2 .icon {
  background-image: url("../images/printing/sales-add-img2.svg");
}

.section-what-are-you-after {
  padding: 80px 0;
}

.section-what-are-you-after .l-heading {
  text-align: center;
}

.nav-printing-sticky {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  background: #252525;
  transition: all .3s;
  pointer-events: none;
  opacity: 0;
}

.nav-printing-sticky .nav {
  display: flex;
}

.nav-printing-sticky .nav > li {
  width: 100%;
  text-align: center;
}

.nav-printing-sticky .nav > li > a {
  color: #fff;
  font-family: "Gilroy-ExtraBold";
}

.nav-printing-sticky .nav > li > a:hover {
  background-color: transparent !important;
}

.nav-printing-sticky .nav > li.active a {
  background: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);
}

.nav-printing-sticky.is-show {
  top: 85px;
  pointer-events: inherit;
  opacity: 1;
}

.section-banner {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .section-banner {
    overflow: hidden;
  }
  .l-service-printing .section-what-we-offer {
    padding: 3rem 0 0;
  }
  .l-service-printing .section-what-we-offer .section-title {
    margin-bottom: 1rem;
  }
  .l-service-printing .tab-wrap .tab-content {
    margin-top: 2rem;
    padding-top: 4rem;
  }
  .l-service-printing .tab-wrap .nav-tabs li {
    padding: 5px;
  }
  .l-service-printing .tab-wrap .nav-tabs li a {
    padding: 1.5rem 1rem;
  }
  .nav-printing-sticky .l-container {
    width: 100%;
    padding: 0;
  }
  .l-service-printing .tab-wrap .tab-content h3 {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 767px) {
  .nav-printing-sticky .nav {
    min-width: 903px;
  }
  .nav-printing-sticky {
    overflow: scroll;
  }
  .nav-printing-sticky .nav {
    min-width: 903px;
  }
  .nav-printing-sticky {
    overflow: scroll;
  }
  .l-service-printing .section-what-we-offer .l-container {
    width: 100%;
    padding: 0 10px;
  }
  .l-service-printing .section-what-we-offer .section-title {
    font-size: 2.2rem;
  }
  .l-service-printing .tab-wrap .tab-content {
    padding: 2rem 1rem;
  }
  .l-service-printing .tab-wrap .tab-content h3 {
    font-size: 2rem;
    text-align: center;
  }
  .l-service-printing .listing-item-text .content {
    padding: 1rem;
    text-align: left;
  }
  .l-service-printing .l-section-1 .l-section-left h1 {
    font-size: 2.2rem;
  }
  .l-service-printing .l-section-1 .l-section-left {
    text-align: left;
  }
  .l-service-printing .l-section-1 .l-section-right .img1 {
    height: 220px;
  }
  .l-service-printing .l-section-1 .l-section-right .img2 {
    display: none;
  }
  .l-service-printing .l-section-1 .l-section-right {
    margin-bottom: 1rem;
  }
}

.l-service-virtual .banner-bg-wrap {
  background-position: center center;
}

.l-service-virtual .banner-bg-wrap.offset-next-elem:before {
  top: 0;
}

@media (max-width: 991px) {
  .l-service-virtual .section-service-features {
    padding-bottom: 2rem;
  }
}

.l-home-section {
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
}

.l-home-section .l-container {
  margin-top: 3rem;
}

.l-home-section-footer .prefooter .u-flex {
  align-items: inherit;
}

.js-letsplay {
  position: fixed;
  z-index: 24;
  right: 0;
  bottom: 0;
  display: block;
  transition: all .3s;
}

#works-section.fp-completely .js-letsplay {
  right: 0;
}

.modal-letsplay {
  position: fixed;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  margin: 0;
  transition: opacity .5s, visibility 0s .5s;
  opacity: 0;
  background: #000;
  align-items: center;
}

.modal-letsplay.active {
  z-index: 101;
  visibility: visible;
  transition: opacity .5s;
  opacity: 1;
}

.background {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
}

.background:after {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.wrapper {
  display: none;
}

/* 1. Intro Keyframe
--------------------------------------- */
#intro {
  /*position: fixed;*/
  /*top: 48%;*/
  /*left: 10%;*/
  /*!* width: 80%; *!*/
  /*color: #fff;*/
  /*text-align: center;*/
  /*text-transform: uppercase;*/
}

.ampersand {
  font-family: 'Adobe Caslon Pro', 'brandon-grotesque';
  font-style: italic;
}

#images {
  position: relative;
}

.explosion-byline,
.images-byline {
  position: fixed;
  top: 63%;
  left: 10%;
  max-width: 22%;
  opacity: 0;
  color: white;
}

.images-byline-2 {
  position: fixed;
  top: 93%;
  left: 25%;
  width: 50%;
  text-align: center;
  opacity: 0;
  color: white;
}

/* 3. Explosion Keyframe
--------------------------------------- */
/* 3. Images Keyframe
--------------------------------------- */
.raw-page {
  position: fixed;
  width: 45%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-clip: padding-box;
  box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
}

#mediumHomepage {
  top: 100%;
  right: 5%;
}

.iphone {
  position: fixed;
  top: 102%;
  right: 40%;
  width: 16%;
}

.iphone-frame {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  background-size: 100%;
}

.iphone-viewport {
  position: absolute;
  top: 14%;
  left: 8%;
  overflow: hidden;
  width: 83.5%;
  height: 69.5%;
  background-color: #000;
}

.iphone-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#medium-profile-iphone {
  z-index: 2;
}

/* 3. Links Keyframe
--------------------------------------- */
#links {
  position: fixed;
  top: 35%;
  left: 10%;
  width: 80%;
  text-align: center;
  opacity: 0;
  color: #fff;
}

.links-byline {
  font-size: 30px;
  line-height: 1.15;
}

.btn {
  font-size: 12px;
  font-weight: 900;
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px;
}

.btn:hover,
.btn:focus {
  color: #fff;
  border-color: #fff;
}

.twitter {
  position: fixed;
  top: 20px;
  right: 20px;
}

/* 4. Handling resize and small devices
--------------------------------------- */
.error {
  font-size: 30px;
  font-weight: 300;
  position: fixed;
  top: 30%;
  left: 10%;
  display: none;
  width: 80%;
  text-align: center;
}

.page-error .error {
  display: block;
}

.page-error .container {
  display: none;
}

@media all and (max-width: 1000px) {
  .error {
    font-size: 22px;
    display: block;
  }
}

.l-index-container {
  max-width: 1400px;
  margin: auto;
}

#links .l-index__heading-top {
  right: 0;
  left: 0;
}

body.is-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

@-webkit-keyframes mouseScroll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(14px);
    transform: translateY(14px);
  }
}

@keyframes mouseScroll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(14px);
    transform: translateY(14px);
  }
}

.mouse-scroll {
  position: fixed;
  z-index: 0;
  bottom: 1.4rem;
  left: 2rem;
  overflow: hidden;
  width: 26px;
  height: 40px;
  transition: all .3s ease;
  border: 2px solid rgba(255, 255, 255, 0.16);
  border-radius: 15px;
  mix-blend-mode: difference;
}

.mouse-scroll-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.mouse-scroll .roll {
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 50%;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  -webkit-animation: mouseScroll 0.8s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  animation: mouseScroll 0.8s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  border-radius: 4px;
  background: #fff;
}

.mouse-scroll .roll-shadow {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 50%;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  -webkit-animation: mouseScroll 0.8s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  animation: mouseScroll 0.8s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  -webkit-animation-delay: 80ms;
  animation-delay: 80ms;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.16);
}

.mouse-scroll.is-hidden {
  opacity: 0;
}

.l-section-1 {
  display: flex;
  align-items: center;
  padding: 3rem 0;
}

.l-section-1 p {
  color: #d4d4d4;
}

.l-section-1 h1 + * {
  margin-top: .5rem;
}

.l-section-1 .l-section-left {
  width: 67%;
  padding-right: 3rem;
}

@media (max-width: 767px) {
  .l-section-1 .l-section-left {
    padding-right: 0;
    margin-bottom: 2em;
  }
}

.l-section-1 .l-section-right {
  width: 33%;
}

.l-section-1 .l-section-right img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .l-section-1 .l-section-left, .l-section-1 .l-section-right {
    width: 100%;
    text-align: center;
  }
  .l-section-1 .l-section-left img, .l-section-1 .l-section-right img {
    width: 100%;
  }
}

.l-section-right {
  margin-left: auto;
}

.l-section-right.offset-b {
  z-index: 1;
  margin-bottom: -4rem;
}

@media screen and (max-width: 767px) {
  .l-section-1 {
    display: block;
  }
}

h1 {
  font-family: "Gilroy-ExtraBold";
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 20px;
}

@media (max-width: 1280px) {
  h1 {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-family: "Gilroy-ExtraBold";
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: .8rem;
}

h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.top-float {
  position: absolute;
  top: 0;
}

#posters {
  padding: 100px 0px;
}

#post {
  overflow: hidden;
  height: 4500px;
  position: relative;
  padding: 100px 0px 100px;
}

#post .l-index__heading-top svg {
  height: 15vh;
}

#post > .post-inner {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh 1vh) * 100);
  position: absolute;
}

#cont_posters #post > .post-inner > .post-inner-holder {
  position: relative;
  left: -60vw;
  width: 600vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: repeat(10, 24vw);
  grid-gap: 4vw;
}

#cont_posters img {
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  position: relative;
}

#cont_posters a {
  display: block;
  transform: scale(1);
  transition: .3s all;
}

#cont_posters a:hover {
  transform: scale(1.09);
}

#double_g {
  height: 200vh;
  background: #000;
}

#double_g #cont_doubleg,
#double_g #cont_doubleg > div {
  width: 100vw;
  height: 100vh;
  position: relative;
}

#double_g #cont_doubleg > div {
  position: absolute;
  background: #E2302E;
  overflow: hidden;
  top: 0px;
}

#double_g #cont_doubleg > div#second_g {
  z-index: 10;
  height: 0px;
  background: #000;
}

#double_g #cont_doubleg > div > div {
  width: 100vw;
  height: 100vh;
  position: relative;
}

#double_g #cont_doubleg > div > div img {
  position: absolute;
  display: block;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#chap_part {
  height: 3000px;
}

@media (max-width: 767px) {
  #chap_part {
    display: none;
  }
}

#chap_part > div {
  width: 100vw;
  overflow: hidden;
  background: #F1F1E8;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#bauhausEdificio {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
}

#chap_part > div > #bautitle {
  display: block;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translate(430vw, -50%) scale(75);
}

.l-index__services-bg {
  min-height: 100vh;
}

.section-services-list {
  min-height: 64vh;
}

.p-l2 {
  text-align: center;
  font-size: 19px;
}

@media screen and (max-width: 767px) {
  .p-l2 {
    font-size: inherit;
  }
}

.banner-l2 {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.banner-l2.banner-bg:after {
  display: none;
}

.banner-l2 .l-service__eyecatch-left, .banner-l2 .l-service-v3__eyecatch-content-upper {
  background: #000;
  max-width: 675px;
  padding: 3rem 4.3rem 3.5rem;
  margin-left: -4.3rem;
}

.banner-l2 .l-service__eyecatch-left h1, .banner-l2 .l-service-v3__eyecatch-content-upper h1 {
  font-size: 46px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.banner-l2 .l-service__eyecatch-left p, .banner-l2 .l-service-v3__eyecatch-content-upper p {
  width: 100%;
}

@media (max-width: 767px) {
  .banner-l2 .l-service__eyecatch-left, .banner-l2 .l-service-v3__eyecatch-content-upper {
    margin: 2em auto 0;
  }
}

.section {
  padding: 3rem 0;
}

@media screen and (max-width: 767px) {
  .section {
    padding: 2rem 0;
  }
}

.text-center {
  text-align: center;
}

@media (max-width: 1170px) {
  .l-index__services .l-index__heading-top h2 svg {
    width: 700px;
  }
  .l-index__services-list {
    margin-top: -12rem;
    max-width: 824px;
  }
  .service-link span {
    font-size: 17px;
    line-height: 1;
    min-height: 60px;
    padding: 8px 28px;
  }
  .l-container {
    width: 90%;
  }
  .l-index__services-item {
    padding-right: 1rem;
    margin-bottom: 10px;
  }
  .l-index__services-item {
    width: 33.333%;
  }
  .l-index__services .l-index__heading-top {
    padding-top: 1.5rem;
  }
}

@media (max-width: 1024px) {
  #chap_part > div > #bautitle {
    transform: translate(482vw, -50%) scale(64);
  }
  #cont_posters #post > .post-inner > .post-inner-holder {
    grid-template-columns: repeat(10, 32vw);
  }
  .pong__game-canvas canvas {
    width: 100% !important;
    height: auto !important;
  }
  .post__content-right {
    padding-left: 2rem;
  }
  .post__content-left {
    width: 61%;
  }
  #double_g #cont_doubleg > div > div h2 svg {
    width: 340px;
  }
  .l-index__services-item {
    width: 100%;
  }
  #posters {
    padding: 2rem 0 0;
  }
  #posters .l-index__heading-top h2 svg {
    width: 620px;
  }
  .l-index__sponsors-left h3 {
    font-size: 1.6rem;
    padding: 0;
  }
  .l-index__sponsors-left span {
    line-height: 1.2;
    font-size: 26px;
    margin-bottom: 7px;
  }
  .l-index__sponsors-left p {
    line-height: 1.5;
    font-size: 16px;
    margin-bottom: 14px;
  }
  .l-index__sponsors-list li {
    padding: 0 10px;
  }
  footer.footer > .u-flex {
    display: block;
    text-align: center;
  }
  ul.footer__sns.u-flex {
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  footer.footer > .u-flex {
    flex-direction: column-reverse;
    display: flex;
    flex-wrap: wrap;
  }
  footer.footer > .u-flex > * {
    width: 100%;
  }
  .footer {
    padding: 2rem 0;
  }
  .footer__middle small {
    padding: 1rem 0;
    display: block;
  }
  img.footer__logo {
    display: none;
  }
  .prefooter > .u-flex {
    display: block;
  }
  .prefooter__left {
    width: 100%;
  }
  .prefooter__right {
    width: 100%;
    padding: 2rem;
  }
  .prefooter__locations ul li {
    display: inline-block;
    margin-right: 2rem;
    text-align: center;
  }
  .prefooter__locations-time {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .prefooter__locations-details {
    margin-top: 1rem;
  }
  .prefooter__locations h2 {
    margin-bottom: 13px;
  }
  #post {
    height: 3050px;
    padding: 13vh 0;
  }
  body {
    font-size: 17px;
    line-height: 1.5;
  }
  .l-service__process, .l-service-v3__intro, .l-service-v3__intro {
    padding: 2.5rem 0;
  }
  .process-block__description, .l-service-v3__intro-block__description, .l-service-v3__intro-block__description {
    font-size: inherit;
  }
  .process-block__icon, .l-service-v3__intro-block__icon, .l-service-v3__intro-block__icon {
    width: 100px;
    padding: 22px;
  }
  .l-service__process .column-3 li, .l-service-v3__intro .column-3 li, .l-service-v3__intro .column-3 li {
    padding: 0 1rem;
  }
  .l-service-web-development .l-service__process .column-3, .l-services .l-service__process .column-3, .l-service-web-development .l-service-v3__intro .column-3, .l-services .l-service-v3__intro .column-3, .l-services .l-service__process .column-3, .l-services .l-service-v3__intro .column-3, .l-service-web-development .l-service-v3__intro .column-3, .l-services .l-service-v3__intro .column-3, .l-services .l-service-v3__intro .column-3 {
    margin-bottom: 1rem;
  }
  .section-what-we-do {
    padding: 3rem 0;
  }
  .section-what-we-do .l-service__heading, .section-what-we-do .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .section-what-we-do .l-service-v3__heading, .section-what-we-do .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .section-what-we-do .l-service-v3__heading {
    font-size: 2.6rem;
  }
  .section-testimonial h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 767px) {
  .l-index__services .l-index__heading-top h2 svg {
    width: 500px;
  }
  .section-services-list {
    min-height: 0;
    margin-top: -28rem;
    padding-bottom: 1rem;
  }
  .service-link span {
    font-size: 17px;
  }
  #cont_posters #post > .post-inner > .post-inner-holder {
    grid-template-columns: repeat(10, 76vw);
  }
  #posters {
    padding: 2rem 0 0;
  }
  #posters .l-index__heading-top h2 svg {
    width: 540px;
  }
  #post {
    height: 1250px;
    padding: 0;
  }
  .l-index__services-item {
    margin-bottom: 10px;
  }
  #double_g #cont_doubleg > div > div h2 svg {
    width: 340px;
  }
  .pong__top h2 {
    font-size: 1.5rem;
    right: 0 !important;
    margin-top: 0;
  }
  .pong__content {
    display: block;
    margin-top: 0;
  }
  .post__content-left {
    width: 100%;
  }
  .post__content-right {
    width: 100%;
    padding-left: 0;
    margin-top: 3rem;
  }
  #double_g #cont_doubleg,
  #double_g #cont_doubleg > div {
    height: 800px;
  }
  .l-index__sponsors h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .l-index__sponsors-left {
    width: 100%;
    padding: 1.5rem 1.5rem 2rem;
  }
  .l-index__sponsors-list li {
    width: 33.333%;
    padding: 1rem;
    margin: 0;
  }
  .l-index__sponsors-right {
    width: 100%;
  }
  ul.l-index__sponsors-list {
    display: flex;
    margin-top: 1rem;
  }
  .l-index__sponsors {
    padding-bottom: 2rem;
  }
  .l-service__testimonies h2, .l-service-v2__testimonies h2, .l-service-v3__testimonies h2, .l-service-v2__testimonies h2, .l-service-v3__testimonies h2 {
    font-size: 28px;
  }
  .l-service__testimonies-list li p {
    font-size: 16px;
    line-height: 1.5;
  }
  .l-service__testimonies.l2, .l2.l-service-v2__testimonies, .l2.l-service-v3__testimonies, .l2.l-service-v2__testimonies, .l2.l-service-v3__testimonies {
    padding: 2rem 0;
  }
  .prefooter__right h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .prefooter__right {
    margin: 0;
    padding: 1.5rem 2rem;
    margin-top: 4rem;
  }
  .footer__links li {
    width: 100%;
    margin-bottom: 0;
  }
  .footer__links a {
    display: block;
    padding: 5px 0;
  }
  canvas#js-pong-container {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 1rem;
  }
  .pong__game-buttons {
    position: static;
  }
  .pong__game-canvas {
    display: block;
    overflow: visible;
  }
  #double_g #cont_doubleg,
  #double_g #cont_doubleg > div {
    height: 100% !important;
  }
  div#first_g {
    display: none;
  }
  section#double_g {
    height: 830px;
  }
  div#second_g .pong__top {
    display: none;
  }
  .button.button--3d-alt {
    line-height: 1;
    transform-style: inherit;
  }
  .button.button--3d-alt:before, .button.button--3d-alt:after {
    display: none;
  }
  .pong__restart,
  .pong__stop {
    margin: 0 5px;
    min-width: 140px;
  }
  .l-index__play {
    padding: 1rem 0;
  }
  .l-index__sponsors {
    padding: 2.5rem 0 0;
  }
  .l-index__services .l-index__heading-top h2 {
    transform: inherit !important;
  }
  .l-index__services .l-index__heading-top h2 svg {
    width: 100%;
  }
  .l-index__services .l-index__heading-top h2 {
    position: static !important;
    text-align: left;
  }
  .l-index__services-bg {
    min-height: 0;
    padding: 1rem 0;
  }
  .l-index__services {
    overflow: visible;
  }
  .section-services-list {
    margin-top: 0;
  }
  .l-index__services-bg .services-bg {
    position: static;
    background: transparent;
  }
  .l-index__services-bg .services-bg:after {
    display: none;
  }
  .l-index__services-list {
    margin-top: 0;
  }
  .l-index__services-bg {
    transform: none !important;
  }
  #cont_posters #post > .post-inner > .post-inner-holder {
    transform: none !important;
    position: static;
    display: block;
    width: auto;
    height: auto;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-gap: 0;
  }
  #cont_posters #post > .post-inner > .post-inner-holder > a {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #cont_posters img {
    position: static;
    transform: none;
  }
  #post > .post-inner {
    position: static;
    transform: none !important;
    height: auto;
  }
  #cont_posters a:hover {
    transform: none;
  }
  #cont_posters a {
    padding: .5rem;
  }
  .l-index__heading-top.top-float.is-inview {
    position: static;
    transform: none !important;
    margin-bottom: 1rem;
  }
  #posters .l-index__heading-top h2 svg {
    width: 100%;
  }
  #post {
    height: auto;
  }
  #posters {
    opacity: 1 !important;
    pointer-events: inherit;
  }
  div#pong-section h2 {
    transform: none !important;
    position: static !important;
  }
  #double_g #cont_doubleg > div > div h2 svg {
    width: 266px;
    height: auto;
    margin-bottom: 1rem;
  }
  #double_g #cont_doubleg,
  #double_g #cont_doubleg > div {
    height: auto;
    position: static !important;
  }
  section#double_g {
    height: auto;
  }
  .l-index__sponsors h2 > div {
    transform: none !important;
  }
  .prefooter__right {
    margin-top: 0;
  }
  .prefooter__locations ul li {
    margin-right: 0;
    width: 32%;
  }
  .section-testimonial h2 {
    font-size: 2rem;
  }
  .testimonials > li {
    padding: 1rem;
  }
  .testimonials li p {
    font-size: 17px;
    line-height: 1.4;
  }
  .testimonials:before {
    display: none;
  }
  .testimonials li h3 {
    font-size: 20px;
  }
  div#cont_doubleg {
    display: none !important;
  }
  .section-testimonial {
    padding: 2rem 0;
  }
  .l-mega-menu {
    display: none;
  }
  body.page .header__menu {
    width: 58px;
    min-width: 0;
    min-height: 0;
  }
  body.page .header__logo {
    left: 4.8rem;
  }
  .prefooter__left {
    text-align: center;
  }
}

@media (max-width: 425px) {
  .l-service .description, .l-service-v2 .description, .l-service-v3 .description, .l-service-v2 .description, .l-service-v3 .description {
    margin-bottom: 15px;
  }
  h1 {
    margin-bottom: .8rem;
  }
  body {
    font-size: 16px;
  }
  .testimonials {
    padding: 0;
  }
  .section-testimonial h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .prefooter__right h2 {
    text-align: center;
  }
  .prefooter__right p {
    text-align: center;
  }
  .l-service-web-development .l-service__details .detail-block__text, .l-services .l-service__details .detail-block__text {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
  }
  .detail-block {
    margin-bottom: 1rem;
  }
  .section-what-we-do .l-service__heading, .section-what-we-do .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .section-what-we-do .l-service-v3__heading {
    font-size: 30px;
  }
  .l-section-right {
    margin-top: 2rem;
  }
  .l-section-right {
    margin-top: 2rem;
  }
  .detail-block__text h2 {
    font-size: 1.8rem;
  }
  .detail-block__text {
    padding: 1.6rem 0;
  }
  .l-service__details {
    padding: 2.5rem 0 0;
  }
  .l-section-1 {
    padding-top: 2rem;
  }
  .l-service-iphone-and-android-apps .l-service__details ul {
    padding: 0;
  }
  .l-service-iphone-and-android-apps .l-service__info {
    padding: 2rem 0;
  }
  h1 br, h2 br, h3 br, h4 br, h5 br, p br {
    display: none;
  }
  .prefooter__right .wpcf7 {
    margin-top: 1rem;
  }
  .prefooter__right .wpcf7 {
    margin-top: 1rem;
  }
  .footer {
    padding-bottom: 0;
    margin: 0;
  }
  ul.footer__sns.u-flex {
    margin-bottom: 0;
  }
  .section-what-we-do {
    padding: 2rem 0;
  }
  .section-what-we-do .l-service__content {
    margin-bottom: 1rem;
  }
  .section-top-video-wrap .l-container {
    padding: 0;
  }
  .l-section-1 {
    padding-bottom: 1rem;
  }
  .l-service-web-development .l-service__process .l-service__intro-content-full, .l-services .l-service__process .l-service__intro-content-full, .l-service-web-development .l-service-v3__intro .l-service__intro-content-full, .l-services .l-service-v3__intro .l-service__intro-content-full {
    margin-bottom: 2rem;
  }
  .detail-block {
    background: transparent;
  }
  .detail-block-l3 .detail-block__text {
    padding-left: 0;
    text-align: left;
  }
  .l-service__details .detail-block__text h2 {
    text-align: left;
    margin-top: 1rem;
    font-size: 1.6rem;
  }
  .l-service-v2__blurb-left {
    text-align: left;
  }
  .l-service__heading, .l-service-v3__call-to-action .l-service-v3__heading, .l-service-v3__call-to-action .l-service-v3__heading {
    font-size: 1.6rem;
  }
  .l-service-v2-service-photography .l-service-v2__blurb {
    padding: 2rem 0;
  }
  .detail-block__image {
    min-height: 200px;
  }
  .detail-block__text {
    padding-top: 0;
  }
  .l-container {
    padding: 0 12px;
  }
}
