.l-pages-wrapper {
  padding-top: 85px;

  &-l2 {
    padding-top: 130px;
  }
}

.l-services-hero-l2 {
  text-align: center;
  padding: 3rem 0 2rem;
  color: #D4D4D4;

  h1 {
    color: #fff;
    font-family: $font-gilroy-xbold;
    font-size: 3.5rem;
    margin-bottom: 0;

    @include max-screen($breakpoint-mobile){
      font-size: 35px;
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 30px;
    }
  }
}