@font-face {
  font-family: 'icomoon';
  src:  url('#{$path-font}/icons/icomoon.eot?uv3uaf');
  src:  url('#{$path-font}/icons/icomoon.eot?uv3uaf#iefix') format('embedded-opentype'),
    url('#{$path-font}/icons/icomoon.ttf?uv3uaf') format('truetype'),
    url('#{$path-font}/icons/icomoon.woff?uv3uaf') format('woff'),
    url('#{$path-font}/icons/icomoon.svg?uv3uaf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-linkedin:before {
  content: "\eaca";
}
