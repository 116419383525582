.process-block {

    @include max-screen($breakpoint-mobile) {
        text-align: center;
    }
}

.process-block__icon {
    position: relative;

    display: inline-block;
    margin-bottom: 35px;
    padding: 35px 0;
    background-color: #1f1f21;
    width: 130px;
    display: flex;
    text-align: center;

    @include max-screen($breakpoint-mobile) {
        margin-bottom: 25px;
        padding: 25px 30px;
        margin-right: auto;
        margin-left: auto;
    }

    &:before {
        position: absolute;
        top: -10px;
        left: -10px;

        display: block;

        width: 33px;
        height: 33px;

        content: '';

        border-top: 3px solid #c4c4c4;
        border-left: 3px solid #c4c4c4;
    }
    &:after {
        position: absolute;
        right: -10px;
        bottom: -10px;

        display: block;

        width: 33px;
        height: 33px;

        content: '';

        border-right: 3px solid $color-primary;
        border-bottom: 3px solid $color-primary;
    }

    img {
        margin: auto;
        text-align: center;
        width: 46px;
    }
}

.process-block__counter {
    position: absolute;
    top: -10px;
    right: -10px;

    &:before {
        font-family: $font-gilroy-xbold;
        font-size: 15px;

        display: block;

        padding: 6px 11px;

        content: '0' counter(process);

        background-color: $color-primary;
    }
}

.process-block__title {
    font-family: $font-gilroy-xbold;
    font-size: 22px;

    margin-bottom: 10px;
}

.process-block__description {

    color: $color-light-gray;

    @include max-screen($breakpoint-mobile) {
        font-size: 15px;
    }
}
