.form-group {
  width: 50%;
  text-align: left;
  padding: 0 15px;
  margin-bottom: 30px;
  float: left;
  position: relative;

  label {
    display: block;

    font-family: $font-gilroy-xbold;
    font-size: 17px;

    color: #EAEAEA;
    margin-bottom: 10px;
  }

  input, select, textarea {
    border: 2px solid #FADB31;
    width: 100%;
    font-family: $font-gilroy-xbold;

    color: #FFFFFF;
    padding: 15px;
  }

  input, select {
    height: 57px;
  }

  select {
    background-image: url(#{$path-image}/contact/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: 95% 23px;
    background-size: 12px;
    
    option {
      color: $base-background-color;
    }
  }

  textarea {
    height: 137px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &.full-form-group {
    width: 100%;
  }
  
  .wpcf7-not-valid {
    border-color: #b22d2c;
  }

  .wpcf7-form-control-wrap {
    position: static;
  }

  .wpcf7-not-valid-tip {
    font-family: $font-gilroy-xbold;
    font-size: 13px;

    text-transform: uppercase;

    color: #B22D2C;

    position: absolute;
    top: 4px;
    right: 15px;
  }


  @media(max-width: 767px) {
    width: 100%;
    float: none;
  }
}