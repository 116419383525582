.l-3d-rendering-portfolio {


  .section-what-we-do .l-container {
    margin-top: -60px;
  }

  .l-service__eyecatch {
    min-height: 0;
    padding: 80px 0;

    .l-service__eyecatch-content {
      text-align: center;

      h1 {
        font-size: 55px;
        font-family: $font-gilroy-xbold;
      }
    }
  }
}