.section-home-1 {


}

.section-bg-text {
    position: fixed;
    z-index: -1;
    right: 0;
    bottom: 0;
    width: 100%;
    left: 0;

    display: block;

    opacity: .8;

    svg {
        width: 75%;
    }

}


.marquee{
    animation:scroll 15s linear infinite;
}
@keyframes scroll{
    0% {left:1900px;}
    100% {left:-1900px;}
}

.hero__text {
    position: fixed;
    text-align: left;
    left: 15rem;
    top: 35vh;

    h1 {
        font-family: $font-gilroy-xbold;
        font-size: 86px;
        line-height: 1.2;

        width: 550px;
        margin-bottom: 40px;

        span {
            position: relative;

            display: block;

            transition: all $duration $easing;
            transform: translateX(-50px);

            opacity: 0;

            &:nth-child(2) {
                transition-delay: .15s;
            }
            &:nth-child(3) {
                transition-delay: .5s;
            }
        }

        // STATE
        &.is-shown {
            span {
                transform: translateX(0);

                opacity: 1;
            }
        }
    }
    a {
        font-weight: bold;

        display: inline-block;

        transition: all $duration $easing;
        transform: translateX(-50px);

        opacity: 0;
        color: #fff;

        // STATE
        &.is-shown {
            transform: translateX(0);

            opacity: 1;
        }
    }
}

.hero__bottom {
    position: absolute;
    right: 200px;
    bottom: 0;

    &:after {
        position: absolute;
        right: -80px;
        bottom: 0;

        display: block;

        width: 90px;
        height: 90px;

        content: '';

        background-color: $color-primary;
    }

    svg {
        position: relative;

        width: 750px;

        path {
            fill: #464646;
        }
    }
}



//////


.container {
    position: relative;
    width: 100%;
    max-width: 1155px;
    margin-right: auto;
    margin-left: auto;
}



.is-nav-collapsed {
    .section-home-1 {
        &:before {
            width: 100vw;
        }
    }
}
