.prefooter {
    padding: 2rem 0 1rem;
    max-width: 370px;

    .l-container {
        @include max-screen($breakpoint-mobile) {
            padding: 0;
        }
    }

    &.prefooter--home {
        padding: 0;
    }
}

.prefooter__left {
    width: 30%;
    padding: 30px 38px;

    background-color: #1f1f20;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        padding: 25px;
    }
}

.prefooter__details {
    margin-bottom: 20px;

    span {
        font-family: $font-gilroy-xbold;
        font-size: 26px;

        display: block;

        margin-bottom: 0;

        background: linear-gradient(90deg, $color-orange 0%, $color-primary 50%);
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
        @include max-screen($breakpoint-mobile) {
            font-size: 25px;
        }
    }
    a {
        display: block;
        font-family: $font-gilroy-xbold;

        color: #fff;

        @include max-screen($breakpoint-mobile) {
            font-size: 18px;
        }
    }
}

.prefooter__locations {
    h2 {
        font-family: $font-gilroy-xbold;
        font-size: 26px;

        display: inline-block;

        margin-bottom: 10px;

        background: linear-gradient(90deg, $color-orange 0%, $color-primary 50%);
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
        @include max-screen($breakpoint-mobile) {
            font-size: 25px;
        }
    }
    li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: .7rem;

        @include max-screen($breakpoint-mobile) {
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.prefooter__locations-time {
    margin-right: 15px;
}

.prefooter__locations-details {


    h3 {
        font-family: $font-gilroy-xbold;
        font-size: 20px;
        line-height: 1;

        display: inline-block;

        margin-bottom: 0;

        color: #fff;

        @include max-screen($breakpoint-mobile) {
            font-size: 18px;
        }
    }
    a {
        color: #969696;
        font-size: 15px;
        display: block;
        margin-top: 8px;
        line-height: 1;
        margin-bottom: -6px;

        @include max-screen($breakpoint-mobile) {
            font-size: 15px;
        }
    }
}

.prefooter__right {
    width: 60%;

    @include max-screen($breakpoint-mobile) {
        width: 100%;
        margin: 50px 0;
        padding: 0 15px;
    }

    .wpcf7 {
        margin-top: 40px;

        .prefooter__right-flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;
        }
        
        .wpcf7-form-control-wrap {
            width: 47%;

            @include max-screen($breakpoint-mobile) {
                width: 100%;
            }

            input,
            textarea {
                padding: 10px 0;
                font-size: 16px;

                border: none;
                border-bottom: 1px solid #383838;
                outline: none;
                width: 100%;

                @include max-screen($breakpoint-mobile) {
                    font-size: 15px;

                    padding: 15px 0;
                }

                &::placeholder {
                    color: $color-light-gray;
                }
            }
            input {
                margin-bottom: 14px;

                @include max-screen($breakpoint-mobile) {
                    margin-bottom: 20px;
                }
            }
            textarea {
                resize: none;

                @include max-screen($breakpoint-mobile) {
                    width: 100%;
                }
            }
        }

        .link {
            font-weight: bold;

            position: relative;
            top: -16px;

            color: #fff;

            align-self: flex-end;
            @include max-screen($breakpoint-mobile) {
                top: 0;

                margin: 40px auto 0;
            }
        }
    }

    h2 {
        font-family: $font-gilroy-xbold;
        font-size: 2.2vw;
        line-height: 1.3;
        margin-bottom: 20px;
        color: #fff;

        @include max-screen($breakpoint-mobile) {
            font-size: 35px;
        }
    }
    p {
        font-size: 22px;
        font-weight: bold;

        color: $color-light-gray;

        @include max-screen($breakpoint-mobile) {
            font-size: 18px;
        }
    }
}

.prefooter2 {
    background: #1a1a1a;

    .prefooter__left {
        position: relative;
        z-index: 1;

        width: 70%;
        padding: 56px;

        background-color: $base-font-color;

        &:before {
            position: absolute;
            top: -15px;
            left: -15px;

            display: block;

            width: 37px;
            height: 37px;

            content: '';

            background-image: url(#{$path-image}/service/caret.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        h2 {
            font-family: $font-gilroy-xbold;
            font-size: 44px;

            margin-bottom: 20px;

            text-transform: capitalize;

            color: #0e0e11;
        }
    }

    .prefooter__right {
        width: 30%;
        padding: 45px 50px;

        background-color: #1f1f20;

        .prefooter__locations-details {
            h3 {
                line-height: 1.7;
            }
        }

        .prefooter__locations {
            a {
                font-weight: normal;

                color: #787878;
            }
        }
    }
}

.circle {
    position: absolute;
    z-index: -1;

    width: 100%;
    height: 100%;

    &1 {
        position: absolute;
        z-index: -1;
        top: -79%;
        left: -26%;

        display: block;

        width: 450px;
        height: 450px;

        content: '';

        background-image: url(#{$path-image}/contact/subtract.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
}
