.l-contact {
  @extend .l-works;
  padding-bottom: 100px;
}

.l-contact__top {
  @extend .l-our-works__top;
  position: relative;

  &:after {
    position: absolute;
    top: 16%;
    right: 0;
    display: block;
    width: 90px;
    height: 100px;
    content: '';

    background-image: url(#{$path-image}/index/dots.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .description {
    text-align: center;

    p {
      font-size: 19px;
      line-height: 32px;
      color: #CFCFCF;

      a {
        color: $base-font-color;
        font-family: $font-gilroy-xbold;
      }
    }
  }
}

.l-contact-form-contact {
  @extend .l-service__info-right;
  width: 100%;
  margin: 0 auto;
  max-width: 803px;
  margin-top: 4em;
  padding-bottom: 4em;
  padding-top: 1em;

  &:after {
    position: absolute;
    bottom: -15px;
    right: -15px;

    display: block;

    width: 37px;
    height: 37px;

    content: '';

    background-image: url(#{$path-image}/contact/white-caret.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .btn-wrap {
    display: block;
    text-align: center;
    padding: 0 15px;
    margin-top: 3em;

    @media(max-width: 767px) {
      margin-top: 2em;

      input {
        width: 100%;
      }
    }
  }

  .button {
    padding: 25px 72px;
  }

  .ajax-loader {
    display: block !important;
    text-align: center;
    background-position: center center;
    margin: 0 auto !important;
  }

  @media(max-width: 767px) {
    padding: 2em;
  }
}