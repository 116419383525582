@import '../setting';

body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: $font-smoothing;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: $font-smoothing;
  text-rendering: $text-rendering;
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $base-font-color;
  background-color: $base-background-color;
  line-height: 1.7;


  @media screen and (min-width: 1281px) {
    min-width: $site-size;
  }
}

html,
body {
    &,
    * {
        outline: none;    
    }

    // STATE WHEN MENU IS OPEN
    &.is-locked {
      overflow: hidden;
    }

    // hide scrollbar on home (but keep scrolling function)
    &.is-home {
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */

      &::-webkit-scrollbar { /* WebKit */
          width: 0;
          height: 0;
      }
    }
}



///// CUSTOM GRID

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .col {
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-6 {
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 25%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-3 {
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}