.l-service-printing {
  .l-section-1 {
    @media(max-width: 991px) {
      display: block;
    }

    .l-section-left {
      width: 50%;
      line-height: 1;

      h1 {
        font-size: 48px;
      }

      .description {
        line-height: 1.7;
      }

      @media(max-width: 991px) {
        width: 100%;
      }
    }

    .l-section-right {
      width: 50%;
      position: relative;

      .l-circle {
        position: absolute;
        height: 511px;
        width: 511px;
        -webkit-border-radius: 600px;
        -moz-border-radius: 600px;
        border-radius: 600px;
        border: 2px dashed #353535;
        background-color: transparent;
        z-index: 0;

        top: 50%;
        right: -30%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);

        .circle {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          height: 353px;
          width: 353px;
          -webkit-border-radius: 600px;
          -moz-border-radius: 600px;
          border-radius: 600px;
          background-color: #252525;

          z-index: 1;

        }

        @media(max-width: 991px) {
          display: none;
        }
      }

      .img-wrap {
        position: relative;
        padding-bottom: 70px;
        margin-top: 15px;
        z-index: 1;
        overflow: hidden;
      }

      .img, .bg {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .img1 {
        width: 70%;
        height: 400px;
        float: left;
      }

      .img2 {
        width: 30%;
        height: 50%;
        float: left;
        padding: 0 0 15px 15px;

        .bg {
          width: 100%;
          height: 195px;

        }
      }

      .img3 {
        width: 70%;
        height: 260px;
        position: absolute;
        bottom: 0;
        right: 0;
        border: 10px solid #0E0E11;
      }

      @media(max-width: 991px) {
        width: 100%;
        margin-bottom: 80px;
      }
    }
  }

  .section-what-we-offer {
    background-color: #252525;
    padding: 80px 0 0;
    border-bottom: 1px solid #252525;

    .small-title,
    .section-title {
      font-family: $font-gilroy-xbold;
      text-align: center;
      font-weight: 800;
    }

    .small-title {
      font-size: 15px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: 3px;
    }

    .section-title {
      font-weight: 800;
      font-size: 48px;
      text-align: center;
      margin-bottom: 40px;
    }

    .btn-wrap {
      text-align: center;
      margin-top: 40px;
    }
  }

  .tab-wrap {
    .nav-tabs {
      border: none;
      text-align: center;

      li {
        width: 33%;
        vertical-align: top;
        display: inline-block;
        float: none;
        padding: 15px;
        text-align: center;
        position: relative;

        a {
          font-family: $font-gilroy-xbold;
          font-size: 22px;
          font-weight: 800;
          background: #171717;
          color: #fff !important;
          border: none !important;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          padding: 46px 45px;
          margin: 0;

          .icon {
            width: 80px;
            height: 80px;

            background-image: url('../images/printing/tab-icon1-gray.svg');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            display: block;
            margin: 0 auto 15px;

            @media(max-width: 1024px){
              width: 70px;
              height: 70px;
            }

            @media(max-width: 991px){
              width: 60px;
              height: 60px;
            }
          }

          .icon2 {
            background-image: url('../images/printing/tab-icon2-gray.svg');
          }

          .icon3 {
            background-image: url('../images/printing/tab-icon3-gray.svg');
          }

          .icon4 {
            background-image: url('../images/printing/tab-icon4-gray.svg');
          }

          .icon5 {
            background-image: url('../images/printing/tab-icon5-gray.svg');
          }

          &:hover {

            color: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);

            .icon {
              background-image: url('../images/printing/tab-icon1.svg');
            }

            .icon2 {
              background-image: url('../images/printing/tab-icon2.svg');
            }

            .icon3 {
              background-image: url('../images/printing/tab-icon3.svg');
            }

            .icon4 {
              background-image: url('../images/printing/tab-icon4.svg');
            }

            .icon5 {
              background-image: url('../images/printing/tab-icon5.svg');
            }
          }

          @media(max-width: 1366px) {
            padding: 46px 33px;
          }

          @media(max-width: 1280px) {
            font-size: 20px;
          }

          @media(max-width: 1199px) {
            font-size: 18px;
          }

          @media(max-width: 1024px) {
            font-size: 15px;
          }

          @media(max-width: 991px) {
            padding: 30px 15px;
          }
        }

        &.active {
          a {

            background: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);

            .icon {
              background-image: url('../images/printing/tab-icon1.svg');
            }

            .icon2 {
              background-image: url('../images/printing/tab-icon2.svg');
            }

            .icon3 {
              background-image: url('../images/printing/tab-icon3.svg');
            }
          }
        }

        @media(max-width: 1024px) {
          width: 30%;
        }

        @media(max-width: 991px) {
          width: 48%;
        }

      }
    }

    .tab-content {
      background-color: #000;
      padding: 80px 0;
      margin-top: 80px;

      h3 {
        font-size: 36px;
        margin-bottom: 40px;
      }
    }
  }

  .listing {
    overflow: hidden;
  }

  .listing-item {
    width: 50%;
    float: left;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &.listing-item-reverse {

      flex-direction: row-reverse;

      @media(max-width: $md) {
        flex-direction: inherit;
      }
    }

    @media(max-width: $md) {
      width: 100%;
      float: none;


      flex-direction: inherit;

      &:nth-child(2n) {
        flex-direction: row-reverse;
      }
    }

    @media(max-width: $sm) {
      flex-direction: row-reverse;
    }

  }

  .listing-item-text,.listing-item-img {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;

    height: 335px;

    @media(max-width: 1366px) {
      height: 360px;
    }

    @media(max-width: 1280px) {
      height: 397px;
    }


    @media(max-width: 640px) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .listing-item-text {
    background-color: #262628;

    text-align: center;

    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;

    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;

    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;

    .content {
      padding: 32px;
    }

    .description {
      margin-bottom: 0;
    }

    p {
      font-size: 15px;
    }

    h4, p {
      color: #f2f2f2;
    }

    h4 {
      font-family: $font-gilroy-xbold;
      font-size: 23px;
      margin-bottom: 5px;
    }

    @media(max-width: 640px) {
      display: block;
      height: auto;
    }
  }

  .listing-item-img {
    background-size: cover;
    background-position: center center;

    @media(max-width: 640px) {
      height: 275px;
    }
  }
}

.section-print-materials {
  background-color: #252525;
  padding: 80px 0;

  .listing {
    text-align: center;

    .listing-item {
      display: inline-block;
      vertical-align: top;
      width: 370px;
      margin: 15px;
      text-align: center;
      position: relative;

      font-family: $font-gilroy-xbold;
      font-size: 27px;
      font-weight: 800;
      background: #000000;
      color: #fff !important;
      border: none !important;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 46px;
      float: none;

      .icon {
        width: 102px;
        height: 102px;

        background-image: url('../images/printing/sales-add-img1-gray.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: block;
        margin: 0 auto 15px;
      }

      &.listing-item2 {
        .icon {
          background-image: url('../images/printing/sales-add-img2-gray.svg');
        }
      }

      &:hover {
        background: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);

        .icon {
          background-image: url('../images/printing/sales-add-img1.svg');
        }

        &.listing-item2 {
          .icon {
            background-image: url('../images/printing/sales-add-img2.svg');
          }
        }
      }

    }
  }
}

.section-what-are-you-after {
  padding: 80px 0;

  .l-heading {
    text-align: center;
  }
}


.nav-printing-sticky {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  background: #252525;
  transition: all .3s;
  pointer-events: none;
  opacity: 0;


  .nav {
    display: flex;

    > li {
      width: 100%;
      text-align: center;

      > a {
        color: #fff;
        font-family: "Gilroy-ExtraBold";

        &:hover {
          background-color: transparent!important;
        }
      }

      &.active {
        a {
          background: linear-gradient(90deg, #FB8631 0.01%, #97080C 100.02%);
        }
      }
    }
  }


  &.is-show {
    top: 85px;
    pointer-events: inherit;
    opacity: 1;
  }
}


.section-banner {
  overflow: hidden;
}

@media (max-width:$md) {
  .section-banner {
    overflow: hidden;
  }

  .l-service-printing .section-what-we-offer {
    padding: 3rem 0 0;
  }

  .l-service-printing .section-what-we-offer .section-title {
    margin-bottom: 1rem;
  }

  .l-service-printing .tab-wrap .tab-content {
    margin-top: 2rem;
    padding-top: 4rem;
  }

  .l-service-printing .tab-wrap .nav-tabs li {
    padding: 5px;
  }

  .l-service-printing .tab-wrap .nav-tabs li a {
    padding: 1.5rem 1rem;
  }

  .nav-printing-sticky .l-container {
    width: 100%;
    padding: 0;
  }

  .l-service-printing .tab-wrap .tab-content h3 {
    margin-bottom: 1.5rem;
  }

}
@media (max-width:$sm) {

  .nav-printing-sticky .nav {min-width: 903px;}

  .nav-printing-sticky {
    overflow: scroll;
  }

  .nav-printing-sticky .nav {min-width: 903px;}

  .nav-printing-sticky {
    overflow: scroll;
  }

  .l-service-printing .section-what-we-offer .l-container {
    width: 100%;
    padding: 0 10px;
  }

  .l-service-printing .section-what-we-offer .section-title {
    font-size: 2.2rem;
  }

  .l-service-printing .tab-wrap .tab-content {
    padding: 2rem 1rem;
  }

  .l-service-printing .tab-wrap .tab-content h3 {
    font-size: 2rem;
    text-align: center;
  }

  .l-service-printing .listing-item-text .content {
    padding: 1rem;
    text-align: left;
  }

  .l-service-printing .l-section-1 .l-section-left h1 {
    font-size: 2.2rem;
  }

  .l-service-printing .l-section-1 .l-section-left {
    text-align: left;
  }

  .l-service-printing .l-section-1 .l-section-right .img1 {
    height: 220px;
  }

  .l-service-printing .l-section-1 .l-section-right .img2 {
    display: none;
  }

  .l-service-printing .l-section-1 .l-section-right {
    margin-bottom: 1rem;
  }

}