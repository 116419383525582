.l-container {
  position: relative;

  width: 85%;
  margin: 0 auto;
  padding: 0 30px;


  @include max-screen($breakpoint-mobile) {
    width: 100%;
    padding: 0 22px;
  }
}

body.page {
  .l-container {
    max-width: 1200px;


    &.lg {

      max-width: 1450px;
    }
  }
}