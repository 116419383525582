.l-service-v3 {
  @extend .l-service;
  padding-top: 100px;
}

.l-service-v3__eyecatch {
  overflow: hidden;
  padding-top: 2rem;
}

.l-service-v3__eyecatch-content-upper {
  @extend .l-service__eyecatch-left;
  width: 100%;
  min-height: 100%;
  text-align: center;

  p {
    margin-left: auto;
    margin-right:  auto;
    width: 60%;
  }
}

.l-service-v3__eyecatch-content-lower {
  width: 725px;
  margin: auto;
  position: relative;

  @include max-screen($breakpoint-mobile) {
    width: 100%;
  }

  .l-service-v3__eyecatch-image {
    max-width: 725px;
    margin: 0 auto;
    height: auto;
    width: auto;

    @include max-screen($breakpoint-mobile) {
      max-width: initial;
      width: 100%;
    }
  }
}

.l-service-v3__eyecatch-list {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  li {
    background-color: $base-font-color;
    border-radius: 13px;

    position: absolute;
    padding: 18px;

    span {
      display: block;
    }

    .l-service-v3__eyecatch-list-icon {
      width: 56px;
      height: 56px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      background-color: #0E0E11;

      position: absolute;
      top: 50%;
      left: 18px;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .l-service-v3__eyecatch-list-text {
      font-family: $font-gilroy-xbold;
      font-size: 20px;

      color: #0E0E11;
      line-height: 1.3;
      padding-left: 70px;
      text-align: left;
    }

    &.l-service-v3__eyecatch-list-item {
      &1 {
        bottom: 30%;
        left: -9%;
      }

      &2 {
        top: 18%;
        left: -24%;
      }

      &3 {
        top: 12%;
        right: -9%;
      }

      &4 {
        bottom: 38%;
        right: -18%;
      }
    }

  }
}

.l-service-v3__cms {
  background-color: #1a1a1a;
  padding: 3.8rem 0;

  .l-service__intro-content {
    text-align: center;

    h2 {
      font-size: 44px;
      margin-bottom: 25px;
      font-family: $font-gilroy-xbold;
    }

    @media(max-width: 1280px) {
      padding: 0 75px;
    }

    @include max-screen($breakpoint-mobile) {
      padding: 0;
    }
  }
}

.l-service-v3__cms-list {
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
  }
}

.l-service-v3__intro {
  @extend .l-service__process;

  .l-service__intro-content-full {
    text-align: center;

    border-bottom: 1px solid #282828;
    padding: 30px 0 80px;
    margin-bottom: 50px;
  }
  &.centered{
    text-align: center;
    ul{
       margin-right: -25px;
        margin-left: -25px;
      li{
        padding-right: 25px;
        padding-left: 25px;
        .l-service-v3__intro-block{
          margin-left: 0;

        }
      }
    }
    .l-service-v3__intro-block{
      margin-left: 0;
      text-align: center;

      .l-service-v3__intro-block__icon{
        margin: auto;
        margin-bottom: 15px;
      }
    }
  }
}

.l-service-v3__intro-top {
  border-bottom: 1px solid #282828;
  padding-bottom: 50px;
  margin-bottom: 50px;

  h2 {
    font-family: $font-gilroy-xbold;
    font-size: 40px;
    width: 34%;
  }

  p {
    width: 53%;
    margin-right: 0;
  }

  &.l-service-v3__intro-top-full {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;

    h2, p {
      width: 100%;
    }
  }

  &.text-left {
    text-align: left;

    p {
      margin-bottom: 15px;
    }
  }

  &.l-service-v3__intro-top-no-width {
    max-width: none;
  }

  &.border-b-none {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.l-service-v3__intro-listing {

  li {
    width: 33.333%;
    margin-bottom: 50px;

    @include max-screen($breakpoint-mobile) {
      width: 100%;
    }
  }
}

.l-service-v3__intro-block {
  @extend .process-block;
}

.l-service-v3__intro-block__icon {
  @extend .process-block__icon;
  
  &:before, &:after {
    display: none;
  }
}

.l-service-v3__intro-block__icon {
  @extend .process-block__icon;
}

.l-service-v3__intro-block__title {
  @extend .process-block__title;
  font-size: 26px;
}

.l-service-v3__intro-block__description {
  @extend .process-block__description;
}

.l-service-v3__plans {
  @extend .l-service-v2__solutions;
  margin-bottom: 80px;

  &.l-service-v3-copywriting__plans {
    .l-service-v2__solutions-left {
      width: 40%;

      @include max-screen($breakpoint-mobile) {
        width: 100%;
        position: relative;
        height: auto;
        top: auto;
        left: auto;
      }
    }

    .l-service-v3__plans-right {
      width: 60%;

      @include max-screen($breakpoint-mobile) {
        width: 100%;
      }
    }
  }

  &.pb-0 {
    padding-bottom: 0;
  }

  &.mb-0 {
    margin-bottom: 0;
  }


  @include max-screen($breakpoint-mobile) {
    margin-bottom: 0;
  }

}

.l-service-v3__plans-left {
  @extend .l-service-v2__solutions-left;
}

.l-service-v3__plans-left-img {
  height: 100%;
  width: 100%;
}

.l-service-v3__plans-right {
  @extend .l-service__solutions-right;
  padding: 80px 50px;

  @include max-screen($breakpoint-mobile) {
    padding: 50px 0;
    width: 100%;
  }

  &.l-service-v3__plans-right--video {
    padding: 0;

    @include max-screen($breakpoint-mobile) {
      padding: 50px 0;
    }
  }
}

.l-service-v3__plans-left-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.l-service-v3__plans-left-img-left, .l-service-v3__plans-left-img-right {
  width: 50%;
  height: 100%;
  overflow: hidden;
  float: left;
}

.l-service-v3__plans-left-img-item {
  &1, &2{
    width: 100%;
    padding: 15px;
  }

  &1 {
    height: 60%;
  }

  &2 {
    height: 40%;
  }
}

.l-service-v3__plans-left-img-right {
  .l-service-v3__plans-left-img-item {

    &1 {
      height: 40%;
    }

    &2 {
      height: 60%;
    }
  }
}

.l-service-v3__text-column {
  @extend .l-service__intro;
  background-color: #1D1D1D;
  padding: 80px 0 8em;
  margin-bottom: 0;
}

.l-service-v3__call-to-action {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding: 80px 0;
  max-width: 1314px;
  margin: 4em auto -18em;

  .description {
    max-width: 888px;
    margin: 0 auto 25px;
  }

  .l-service-v3__heading {
    @extend .l-service__heading;
    font-size: 50px;
    line-height: 1;
  }
}

.l-service-v3__testimonies {
  @extend .l-service__testimonies;
  padding-top: 14em;

  .l-container {
    &:before {
      display: none;
    }
  }

  &.pt-100 {
    padding-top: 100px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

.l-service-ecommerce,
.l-service-v3-social-media,
.l-service-v3-video,
.l-service-v3-web-application {
  .l-service-v3__plans {
    margin: 80px 0;
    padding: 0;
  }

  .l-service-v3__intro {
    padding: 0 0 80px;

    .btn-wrap {
      text-align: center;
    }
  }
}

.l-service-v3-social-media,
.l-service-v3-web-application {
  .l-service-v3__plans {
    .l-service-v3__plans-right {
      @include max-screen($breakpoint-mobile) {
        padding-bottom: 0;
      }
    }
  }

  .l-service-v3__text-column {
    @include max-screen($breakpoint-mobile) {
      text-align: center;
    }
  }
}

.l-service-v3-social-media {
  .l-service-v3__cms {
    .l-service-v3__cms {
      padding: 80px 0;
    }

    .l-service__intro-content-full {
      .description {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.l-service-ecommerce {
  .l-service-v3__cms {
    padding: 80px 0;

    .l-service__intro-content-full {
      max-width: 945px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .l-services-ecommerce-management-systems {
    .l-service__heading {
      margin-bottom: 1em;
    }

    .l-service-v3__intro-listing {
      align-items: normal;
      margin-left: -30px;
      margin-right: -30px;

      li {
        padding: 0 30px;
        text-align: left;

        .l-service-v3__intro-block {
          margin-left: 0;

          .l-service-v3__intro-block__icon {
            height: 120px;
            width: 120px;
          }
        }
      }
    }
  }
}


.l-service-v3-event {
  .l-service-v3__text-column {
    margin-bottom: 0;
    background-color: #252525;
    padding: 3.4rem 0 3.5rem;
  }
}

.l-service-v3-copywriting {
  .l-service-v3__intro {
    padding-bottom: 0;

    .l-service-v3__intro-listing {
      align-items: normal;

      .l-service-v3__intro-block {
        margin-left: 0;
      }
    }
  }

  .l-service-v3__cms {
    padding-bottom: 0;

    .l-service__intro-content-full {
      .description {
        max-width: 1065px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .l-service__eyecatch {
    .l-service__eyecatch-left {
      position: relative;
      z-index: 1;

      .content {
        position: relative;
        z-index: 1;
      }

      .l-service-v2__circle {
        position: absolute;
        top: 50%;
        left: 41%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 0;
        opacity: 0.5;
      }

      @media(max-width: 1024px) {
        width: 100%;
        text-align: center;
        margin: 0 auto;

        .l-service-v2__circle {
          left: 50%;
        }
      }
    }
  }


}



.l-service-v3{
  &.l-service-v3-web-application{
    .l-service-v3__eyecatch{
     .l-service-v3__eyecatch-content-lower{
      width: 300px;
      .l-service-v3__eyecatch-image{
        max-width: 300px;
      }
    }
    }
  }
  &.l-service-v3-web-application, &.l-service-v3-social-media, &.l-service-ecommerce, &.l-service-v3-video, &.l-service-v3-video, &.l-service-v3-copywriting{
    .l-service-v3__eyecatch{
      text-align: center;
      img{
        margin-bottom: 60px;
      }
      .button{
        margin: auto;
        margin-bottom: 60px;
      }
    }
  }
}

.l-service.l-service-v3.l-service-v3-copywriting{
  .section-vr{
    .detail-block__text {
      margin: auto;
    }
  }
}

.l-service-v3.l-service-v3-event{
  .l-service-v3__eyecatch{
    padding-top: 60px;
    padding-bottom: 100px;

    img{
      width: 460px;
      max-width: 95%;
      margin-bottom: 40px;
    }

    .button{
      margin-top: 30px;
    }
  }
}

.l-service-v3-video {
  .l-service__details {
    .detail-block {
      .detail-block__image {
        @media(max-width: 767px) {
          margin-bottom: 2em;
        }
      }

      .detail-block__text {
        @media(max-width: 767px) {
          text-align: left;

          h2 {
            text-align: left;
          }
        }
      }
    }

    @media(max-width: 767px) {
      padding-bottom: 50px;
    }
  }
}