.loader {
  position: relative;
  z-index: 90;

  width: 100%;
  height: 100vh;

  background-color: #000;
}

.loader__image {
  position: fixed;
  top: 30%;
  left: 35%;
  width: 500px;
  height: 214px;
  z-index: 9999;


  svg {
    position: absolute;
    transform: translate(0, -50%);

    stop {
      -webkit-transition: .4s ease;
      transition: .3s ease;
    }

    &:first-child {
      top: 49.5%;
      left: 0;

      &.is-active {
        path:nth-child(1) {
          // animation: line-animation ease-in-out forwards 3s,
          // gradient-shadow .15s ease-in-out forwards 1.55s;

          stroke-dasharray: 1500;
          stroke-dashoffset: 1500;
          fill: transparent;
          stroke: url(#paint0_linear);
        }
      }
    }
    &:last-child {
      top: 52%;
      left: 2.2rem;

      &.is-active {
        path:nth-child(1) {
          // animation: line-animation ease-in-out forwards 4s,
          // box-shadow .75s ease-in-out forwards 2.2s;

          stroke-dasharray: 800;
          stroke-dashoffset: 800;
          fill: transparent;
          stroke: #fff;
        }
      }
    }
  }
}



.is-loaded {
  background-color: transparent;
  height: auto;
  width: auto;
  position: static;

  .loader__image {
    display: block;
    top: -2.6rem;
    left: -.8rem;
    transition: all 1s;
    transform: scale(.38);
  }
}

.is-locked {
  .loader__image {
    left: -3.5rem;
    transform: scale(0.4)!important;

  }
}

@keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes box-shadow {
  to {
    fill: #fff;
  }
}

@keyframes gradient-shadow {
  to {
    fill: url(#paint0_linear);
  }
}
