.pong {
    // margin-left: -15.2vw;
}

.l-index__play {
    padding: 14vh 0;
    h2 {
        svg {

            height: 18vh;
        }
    }

}

.pong__curtain {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, #97080c 0%, #fbb431 100%);
}

.pong__top {
    text-align: left;

    h2 {
        font-size: 2.1vw;
    }

}

.pong__top-outline {
    position: absolute;
    top: -143px;
    left: 600px;
}

.pong__content {
    margin-top: 2rem;
    display: flex;
}

.post__content-left {

    width: 50%;
}
.post__content-right {
    width: 50%;
    padding-left: 3.5rem;
}

.pong__scores {
    font-size: 14px;
    margin-top: 6vh;
    text-align: center;
    letter-spacing: .1em;

    h3 {
        margin-bottom: 18px;
        font-size: 22px;
    }

    table {
        td {
            width: 150px;
        }

        thead {
            opacity: .6;
            font-family: $font-gilroy-xbold;
        }

        tbody {
            td {
                padding: 5px 0;
            }
        }
    }
}

.pong__records {
    text-transform: uppercase;
    border: 2px dashed rgba(#fff, .15%);
    padding: 18px;
    margin-top: 1rem;
    line-height: 1.1;
}

.pong__tools {
    font-family: $base-font-family;
}

.pong__start {
}

.pong__restart,
.pong__stop {
    font-size: 14px;

    display: none;

    padding-left: 24px;
    padding-right: 24px;
    margin: 0 17px;
}

.pong__instructions {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    opacity: .8;
    text-align: center;
}

.pong__credit {
    margin-top: 10px;
    margin-bottom: -5px;
}

.pong__arrow {
    position: absolute;
    bottom: 0;
    left: 25px;

    a {
        display: block;

        width: 25px;
        height: 25px;

        transform: rotate(-45deg);

        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
    }
}

.pong__close {
    position: absolute;
    top: 15px;
    right: 15px;

    display: block;

    width: 50px;
    height: 50px;
    margin: auto;
    padding: 22px 12px;

    border-width: 2px;
    border-color: #fff;
    border-radius: 50%;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;

        width: 20px;
        height: 3px;

        content: '';

        background-color: #fff;
    }
    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}


.pong__game-canvas {
    position: relative;
    display: block;
    display: flex;
    justify-content: center;
    overflow: hidden;

    canvas#js-pong-container {


        @media (max-width:1300px) {

            width: 100%!important;
            height: auto!important;
        }
    }
}

.pong__game-buttons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pong__tools {
    display: flex;
}

.pong__tools a {
}

@media (max-width:1300px) {
    .pong__instructions {
        font-size: 15px;
    }

    .l-index__play {
        padding: 12vh 0;
    }
}