.circle-dial {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    width: 500px;
    height: 500px;
    margin: auto;

    .slider-circle {
        position: absolute;
        top: -40px;
        left: -40px;

        overflow: visible;

        width: 537px;
        height: 537px;

        cursor: grab;

        fill: transparent;
    }

    .draggable-circle {
        position: relative;

        cursor: grab;
        animation: wiggle 1.5s infinite linear;

        fill: $color-primary;
    }

    .draggable-circle-bg {
        cursor: grab;
        animation: blink 1.5s infinite linear,
        wiggle 1.5s infinite linear;
    }

}

.circle-dial__bg {
    position: absolute;
    top: -40px;
    left: -40px;

    width: 537px;
    height: 537px;

    transition: background-image .5s $easing;

    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    // $companies: 5;

    // @for $i from 1 through $companies {
    //     &.is-rotating#{$i} {
    //         animation: crazy-rotate 1.5s alternate forwards ease-in-out;
    //     }
    // }
}

.circle-dial__circles {
    position: absolute;
    top: 0;

    overflow: visible;

    fill: transparent;
}

.circle-dial__color {
    transform: rotate(-90deg);
    transform-origin: center;
    // stroke-linecap: round;

    stroke-width: 1.5;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
}

$pulse-radius: 2.5;
$pulse-scale: 2.5;

@keyframes blink {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    49.99% {
        opacity: 0;

        r: $pulse-radius * $pulse-scale;;
    }
    50% {
        opacity: 1;

        r: $pulse-radius;
    }
    100% {
        // r: $pulse-radius * $pulse-scale;
        opacity: 1;
    }
}

@keyframes wiggle {
    0% {
        transform: translate(5px, 0);
    }
    50% {
        transform: translate(-5px, 0);
    }
    100% {
        transform: translate(5px, 0);
    }
}

// @keyframes crazy-rotate {
//     0% {
//         transform: rotate(0) scale(1);
//     }
//     50% {
//         transform: translate(-5px, 0);
//     }
//     100% {
//         transform: rotate(1080deg) scale(.1);
//     }
// }
